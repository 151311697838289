import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'

interface Option {
  key: string
  value: React.ReactNode
}

interface DropdownPopupProps extends React.ComponentProps<any> {
  options: Option[]
  selectedOption: string
  handleSelect: (optionKey: string) => void
  checkIconClass?: string
}

const SelectPopup: React.FC<DropdownPopupProps> = ({
  options, selectedOption, handleSelect, checkIconClass = '', className, ...restProps
}) => {
  return (
      <div tabIndex={-1}
        className={`border-gray-300 border p-2 rounded-2xl shadow-2xl bg-white overflow-auto focus:outline-none ${className}`} {...restProps}>
        <ul>
          {options.map((option) => (
            <li
              key={option.key}
              className="pl-1 pr-3 flex cursor-pointer rounded-xl hover:bg-gray-100 hover:underline"
              onClick={() => { handleSelect(option.key) }}>
              <CheckIcon className={`h-4 w-auto my-1 mr-1 cursor-pointer ${selectedOption === option.key ? '' : 'opacity-0'} ${checkIconClass}`} />
              {option.value}
            </li>
          ))}
        </ul>
      </div>
  )
}

export default SelectPopup
