import React, { type KeyboardEventHandler } from 'react'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onEnterDown?: () => void
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

const Input: React.FC<InputProps> = ({ onEnterDown, onKeyDown, ...props }) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && onEnterDown != null) {
      onEnterDown()
    }
    if (onKeyDown != null) {
      onKeyDown(event)
    }
  }

  return (
    <input
      {...props}
      onKeyDown={handleKeyDown}
    />
  )
}

export default Input
