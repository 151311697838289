import React from 'react'
import ProgressBar, { type ProgressBarProps } from './progressBar'

interface ProgressBarsProps extends React.ComponentProps<any> {
  itemProgresses: ProgressBarProps[]
  progressColor?: string
}

const ProgressBars: React.FC<ProgressBarsProps> = ({
  itemProgresses,
  progressColor = 'bg-blue-500',
  className,
  ...restProps
}) => {
  return (
    <>
     {Array.from(itemProgresses).map((itemProgress) =>
       <ProgressBar key={itemProgress.label} className="text-xs" {...restProps} {...itemProgress} progressColor={itemProgress.progressColor ?? progressColor}/>
     )}
  </>)
}

export default ProgressBars
