export const NULL_UUID = '00000000-0000-0000-0000-000000000000'

export const MIN_BLADE_LENGTH_M = 1
export const MAX_BLADE_LENGTH_M = 200

export const MIN_MIN_ROOT_DISTANCE_M = -20
export const MAX_MIN_ROOT_DISTANCE_M = MAX_BLADE_LENGTH_M
export const MIN_CENTER_ROOT_DISTANCE_M = -10
export const MAX_CENTER_ROOT_DISTANCE_M = MAX_BLADE_LENGTH_M + 10
export const MIN_MAX_ROOT_DISTANCE_M = 0
export const MAX_MAX_ROOT_DISTANCE_M = MAX_BLADE_LENGTH_M + 20

export const MIN_BLADE_ROOT_DIAMETER_M = 0.1
export const MAX_BLADE_ROOT_DIAMETER_M = 20

export const MIN_CAPACITY_MW = 0.1
export const MAX_CAPACITY_MW = 50

export const MIN_DISTANCE_TO_BLADE_M = 1
export const MAX_DISTANCE_TO_BLADE_M = 200

export const MIN_CAMERA_ANGLE_DEGREES = 0
export const MAX_CAMERA_ANGLE_DEGREES = 90

export const KNOWN_TURBINE_MAKERS = ['Enercon', 'Envision', 'GE', 'Goldwind', 'MHI Vestas', 'MingYang', 'Nordex', 'Siemens', 'Vestas', 'Senvion', 'Suzlon']
