import * as React from 'react'
import { DateTime } from 'luxon'
import { type i18n } from 'i18next'

interface FormattedDateTimeProps {
  i18n?: i18n
  dateTime?: string
}
const FormattedDateTime: React.FC<FormattedDateTimeProps> = ({ i18n, dateTime }) => {
  const formatDate = (dateTime?: string): string => {
    return dateTime == null
      ? ''
      : DateTime.fromISO(dateTime).toFormat('yyyy/M/d HH:mm')
  }
  const formatDateTime = (dateTime?: string): string => {
    return dateTime == null
      ? ''
      : i18n == null ? dateTime : DateTime.fromISO(dateTime).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
  }
  return <div title={formatDateTime(dateTime)}>{formatDate(dateTime)}</div>
}

export default FormattedDateTime
