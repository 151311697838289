import { useTranslation } from 'react-i18next'

import * as React from 'react'
import { useNavigate, Navigate, useLocation } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { type ChangeEvent, useState } from 'react'
import { useLocalizedToast } from '../hooks/useLocalizedToast'
import useSyncedRef from '../hooks/useSyncedRef'
import ConfirmationDialog from './common/confirmationDialog'
import { createUrl, emailRegex } from '../utils/utils'
import LoadingSpinner from './common/loadingSpinner'
import Input from './common/input'
import { useGlobalState } from '../hooks/useGlobalState'

const USER_LOGIN_MUTATION = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email,
            password: $password,
        ) {
            tenantId
            locale
            authenticatable {
                email
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`

const USER_SEND_PASSWORD_RESET_WITH_TOKEN_MUTATION = gql`
    mutation UserSendPasswordResetWithToken($email: String!, $redirectUrl: String!) {
        userSendPasswordResetWithToken (email: $email,
            redirectUrl: $redirectUrl,
        ) {
            message
        }
    }
`

// const USER_REGISTER_MUTATION = gql`
//     mutation UserRegister($email: String!, $password: String!) {
//         userRegister(email: $email,
//             password: $password,
//             passwordConfirmation: $password,
//         ) {
//             authenticatable {
//                 email
//             }
//             credentials {
//                 accessToken
//                 client
//                 expiry
//                 tokenType
//                 uid
//             }
//         }
//     }
// `;

export default function Login (): React.JSX.Element | null {
  const { t } = useTranslation('login')
  const showToast = useLocalizedToast()

  useDocumentTitle(t('login'))
  const { user, onLogin } = useGlobalState()
  const navigate = useNavigate()
  const location = useLocation()
  const navigateTo: string = location.hash === '' ? '/' : location.hash.substring(1)
  const navigateToRef = useSyncedRef(navigateTo)
  const [userLogin] = useMutation(USER_LOGIN_MUTATION)
  const [userSendPasswordResetWithToken] = useMutation(USER_SEND_PASSWORD_RESET_WITH_TOKEN_MUTATION)
  const [isLoading, setIsLoading] = useState(false)
  const [passwordResetVisible, setPasswordResetVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [resetEmail, setResetEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setTenantId, setLocale } = useGlobalState()

  const emailIsValid = emailRegex.test(email)
  const canSubmitLogin = emailIsValid && password !== ''
  const resetEmailIsValid = emailRegex.test(resetEmail)

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
    setResetEmail(e.target.value)
  }

  if (user != null) { // already logged in
    return <Navigate to={navigateTo}/>
  }
  const handleLogin = async (): Promise<void> => {
    if (isLoading || !canSubmitLogin) {
      return
    }
    setIsLoading(true)

    try {
      const response = await userLogin({
        variables: {
          email,
          password
        }
      })
      const user: string = response.data.login.authenticatable.email
      const tenantId: string | null = response.data.login.tenantId ?? null
      const locale: string | null = response.data.login.locale

      setTenantId(tenantId)

      if (locale != null) {
        setLocale(locale)
      }

      void onLogin(user)
        .then(() => {
          // setAc() needs to do its (asynchronous) job before we can navigate, hence the setTimeout()
          setTimeout((): void => {
            navigate(navigateToRef.current, { replace: true })
          }, 100)
        })
        .catch(err => {
          console.error('error in onLogin', err)
        })
    } catch (error) {
      console.error('error logging in', error)
      showToast('warn', () => t('invalidLoginCredentials'))
      // if (error instanceof ApolloError) {
      //   handleApolloErrors(error)
      // } else {
      //   throw error
      // }
    } finally {
      setIsLoading(false)
    }
  }

  const onResetConfirm = async (): Promise<void> => {
    if (resetEmailIsValid && !isLoading) {
      setIsLoading(true)
      userSendPasswordResetWithToken({
        variables: {
          email: resetEmail,
          redirectUrl: createUrl('/update-password')
        }
      }).then(response => {
        console.log('message', response.data.userSendPasswordResetWithToken.message)
        showToast('info', () => t('passwordUpdateInstructionsHaveBeenEmailed', { emailAddress: resetEmail }))
        setPasswordResetVisible(false)
      }).catch(err => {
        if (err?.graphQLErrors?.[0]?.extensions?.code === 'USER_ERROR') {
          showToast('warn', () => t('userWasNotFound'))
        } else {
          console.log('error in onResetConfirm', err, JSON.stringify(err))
        }
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  return (
    <div className="p-2 col-span-2 bg-gray-200 flex justify-center items-center text-center">
      <div>
      <h1 className="p-1 text-lg font-bold">{t('userLogin')}</h1>
      <div className="p-1">
        <Input
          type="email"
          placeholder={t('emailAddress')}
          value={email}
          onChange={handleEmailChange}
          onEnterDown={() => { void handleLogin() }}
          autoFocus={true}
        />
      </div>
      <div className="p-1">
        <Input
          type="password"
          placeholder={t('password')}
          value={password}
          onChange={e => { setPassword(e.target.value) }}
          onEnterDown={() => { void handleLogin() }}
          autoComplete="off"
        />
      </div>
      <div className="p-1 flex justify-center items-center text-center">
        <button className="btn m-2 py-1 px-4"
                onClick={() => { void handleLogin() }}
                type="submit">
          {t('login')}
        </button>
        {isLoading && <LoadingSpinner/>}
      </div>
      <div className="text-xs hover:underline" onClick={() => {
        if (!isLoading) {
          setPasswordResetVisible(true)
        }
      }}>{t('forgotYourPassword')}</div>
      </div>
      {passwordResetVisible &&
        <ConfirmationDialog
          onCancel={() => { setPasswordResetVisible(false) }}
          onConfirm={() => { void onResetConfirm() }}
          confirmBtnClass={resetEmailIsValid || isLoading ? 'btn' : 'btn-disabled'}
          confirmText={t('reset')}
          title={t('resetPassword')}>
          <div className="p-2 flex">
            <Input type="email"
                   className="mr-2"
                   value={resetEmail}
                   placeholder={t('emailAddress')}
                   onEnterDown={() => { void onResetConfirm() }}
                   onChange={e => { setResetEmail(e.target.value) }}/>
            {isLoading && <LoadingSpinner/>}
          </div>
        </ConfirmationDialog>
      }
    </div>
  )
}
