import * as React from 'react'
import { createContext, useContext, useEffect, useId } from 'react'
import { ObservableStateMap } from '../state/observableStateMap'
import { getMeta } from '../utils/utils'
import { useStorage } from './useStorage'
import useStateMap from './useStateMap'

interface GlobalStateContextType {
  setComponentsWithUnsavedChanges: (componentId: string, hasChanges: boolean) => void
  hasUnsavedChanges: boolean

  tenantId: string | null
  setTenantId: (tid: string | null) => void

  locale: string | null
  setLocale: (tid: string | null) => void

  authData: AuthData | null
  setAuthData: (authData: AuthData | null) => void
  user: string | null

  onLogin: (user: string) => Promise<void>
  onLogout: () => Promise<void>

  observableStateMap: ObservableStateMap<any>
}

export const observableStateMap = new ObservableStateMap<any>()

export interface AuthData {
  'access-token': string
  client: string
  uid: string
}

export function toQueryString (authData: AuthData): string {
  return Object.entries(authData)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(String(v))}`)
    .join('&')
}

const defaultGlobalState: GlobalStateContextType = {
  hasUnsavedChanges: false,
  setComponentsWithUnsavedChanges: () => [],
  tenantId: null,
  setTenantId: _ => {},
  locale: null,
  setLocale: _ => {},
  authData: null,
  setAuthData: _ => {},
  user: null,
  onLogin: async _ => {},
  onLogout: async () => {},
  observableStateMap
}

const GlobalStateContext = createContext<GlobalStateContextType>(defaultGlobalState)

interface GlobalStateProviderProps {
  children?: React.ReactNode
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const { map: componentsWithUnsavedChanges, setOrDelete: setOrDeleteComponentsWithUnsavedChanges } = useStateMap<string, boolean>()
  const hasUnsavedChanges = componentsWithUnsavedChanges.size > 0
  const setComponentsWithUnsavedChanges = (componentId: string, hasChanges: boolean): void => {
    setOrDeleteComponentsWithUnsavedChanges(componentId, hasChanges || null)
  }

  // localStorage fields
  const [authData, setAuthData] = useStorage<AuthData>(localStorage, 'authData', null)
  const [user, setUser] = useStorage<string>(localStorage, 'user', null)

  // sessionStorage fields
  const [tenantId, setTenantId] = useStorage<string | null>(sessionStorage, 'tid', getMeta('lastTid'))
  const [locale, setLocale] = useStorage<string | null>(sessionStorage, 'locale', getMeta('locale'))

  const onLogin = async (user: string): Promise<void> => {
    setUser(user)
  }

  const onLogout = async (): Promise<void> => {
    setAuthData(null)
    setUser(null)
  }

  return (
    <GlobalStateContext.Provider value={{
      setComponentsWithUnsavedChanges,
      hasUnsavedChanges,

      tenantId,
      setTenantId,

      locale,
      setLocale,

      authData,
      setAuthData,
      user,

      onLogin,
      onLogout,

      observableStateMap
    }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

type UseGlobalStateReturnType = Omit<GlobalStateContextType, 'setHasUnsavedChanges' | 'setComponentsWithUnsavedChanges'> & {
  setHasUnsavedChanges: (hasChanges: boolean) => void
}

export function useGlobalState (): UseGlobalStateReturnType {
  // Even though this is called componentId, a single component may use the useGlobalState hook multiple times and will
  // get assigned a different "componentId" each time, so they maintain multiple "hasChanges" contexts
  const componentId = useId()
  const context = useContext(GlobalStateContext)

  const setHasUnsavedChanges = (hasChanges: boolean): void => {
    context.setComponentsWithUnsavedChanges(componentId, hasChanges)
  }

  useEffect(() => {
    return () => {
      if (componentId != null) {
        setHasUnsavedChanges(false)
      }
    }
  }, [])
  return { ...context, setHasUnsavedChanges }
}
