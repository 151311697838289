import * as React from 'react'
import { type ChangeEvent, type ReactElement, useEffect, useState } from 'react'
import { SeverityCellRenderer } from '../common/severityCellRenderer'
import { type Column, type Row, type Table } from '@tanstack/react-table'
import { type ColumnMeta, type Option, type RecordWithId, type TableMeta } from './table'
import FormattedDate from '../common/formattedDate'
import FormattedDateTime from '../common/formattedDateTime'
import { MapPinIcon } from '@heroicons/react/24/solid'
import CustomSelect from '../common/customSelect'
import { asIterable, mapIt } from '../../utils/utils'

interface TableCellProps<RowData extends RecordWithId> {
  getValue: () => any
  row: Row<RowData>
  column: Column<RowData>
  table: Table<RowData>
  autoFocus?: boolean
}

const INVALID_BG_COLOR = 'bg-red-100'

const TableCell = <RowData extends RecordWithId>({ getValue, row, column, table, autoFocus }: TableCellProps<RowData>): ReactElement => {
  const initialValue = getValue()
  const columnMeta = column.columnDef.meta as ColumnMeta<RowData>
  const tableMeta = table.options.meta as TableMeta<any>
  const [value, setValue] = useState(initialValue)
  const t = tableMeta?.t ?? ((v) => v)
  const colT = columnMeta?.t ?? t

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const convertValue = (strVal: string): any => {
    if (strVal == null) {
      return null
    }
    switch (columnMeta?.type) {
      case 'number':
      case 'latitude':
      case 'longitude':
        return strVal === ''
          ? null
          : Number.isNaN(strVal)
            ? strVal
            : parseFloat(strVal)
      case 'boolean':
        return strVal === ''
          ? null
          : strVal === 'true'
            ? true
            : strVal === 'false'
              ? false
              : strVal
      default:
        return strVal
    }
  }

  const handleChange = (value: string): void => {
    const v = convertValue(value)
    setValue(v)
    tableMeta?.updateData?.(row.index, column.id, v)
  }
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => { handleChange(e.target.value) }
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputElem = e.target
    const INVALID_BG_COLOR_ADDED_BY_US = '__bg-color-due-to-invalid-value-input' // so we only remove the bg color if we were the ones who added it
    const cls = inputElem.classList
    if (inputElem.validity.badInput) { // in this case, e.target.value will be the empty string, rather than the string in the input field
      // the problem is this: we want to set the background color to red for invalid input, but we don't want to actually
      // set the invalid value, which would cause other errors
      setTimeout(() => {
        // badInput turns from true to false after setTimeout(0) if the value displayed in the input field was valid (e.g. "1" for a number),
        // but subsequently the user entered an invalid character (e.g. "a"), which however will not be shown in the input field (Firefox/Mac)
        // in this case, we do not want to mark the input field background red
        if (inputElem.validity.badInput) { // still bad
          if (!cls.contains(INVALID_BG_COLOR) && !cls.contains(INVALID_BG_COLOR_ADDED_BY_US)) {
            cls.add(INVALID_BG_COLOR, INVALID_BG_COLOR_ADDED_BY_US)
          }
        }
      }, 0)
    } else {
      if (cls.contains(INVALID_BG_COLOR_ADDED_BY_US)) {
        cls.remove(INVALID_BG_COLOR, INVALID_BG_COLOR_ADDED_BY_US)
      }
      handleChange(inputElem.value)
    }
  }

  const truncate = (s: any): React.JSX.Element => <span className="truncate">{s}</span>

  const getIsReadOnly = (): boolean => {
    const ro = columnMeta?.readonly
    return typeof ro === 'function'
      ? ro(row, column.id)
      : typeof ro === 'boolean'
        ? columnMeta.readonly as boolean
        : false
  }

  if (tableMeta?.editedRow?.id !== row.id || getIsReadOnly()) {
    if (columnMeta?.render != null) {
      return truncate(columnMeta.render(value))
    }
    switch (columnMeta?.type) {
      case 'severity':
        return SeverityCellRenderer(value as number)
      case 'date':
        return truncate(<FormattedDate date={value} i18n={tableMeta?.i18n}/>)
      case 'datetime':
        return truncate(<FormattedDateTime dateTime={value} i18n={tableMeta?.i18n}/>)
      case 'latitude':
        return typeof value !== 'number'
          ? value
          : typeof row.original.longitude === 'number'
            ? <div className="flex"><a href={`https://www.google.com/maps?q=${value},${row.original.longitude}&t=k`}
                                       title={String(t('viewOnMap'))} target="_blank" className="flex items-center"
                                       rel="noreferrer">
                  <MapPinIcon className="h-4 w-auto"/>
                </a>{(value ?? 0).toFixed(3)}
              </div>
            : value.toFixed(3)
      case 'longitude':
        return typeof value === 'number' ? value.toFixed(3) : value
      default:
        return truncate(columnMeta?.translate === true && value != null ? colT(String(value)) : value)
    }
  }

  const required = columnMeta?.required == null
    ? 'optional'
    : typeof columnMeta.required === 'string'
      ? columnMeta.required
      : columnMeta.required(row, column.id) ?? 'optional'
  let bg = ''
  if (columnMeta?.validate != null) {
    const valid = columnMeta.validate(row, column.id)
    if (valid === true) {
      bg = 'bg-green-100'
    } else if (valid === false || (valid === undefined && required === 'required')) {
      bg = INVALID_BG_COLOR
    }
  } else if (value == null || value === '') {
    bg = required === 'required'
      ? INVALID_BG_COLOR
      : required === 'recommended'
        ? 'bg-amber-50'
        : bg
  } else {
    bg = 'bg-green-100'
  }

  const title = String(t(`${required}Field`))

  const inputType = columnMeta?.options != null ? 'select' : columnMeta?.type
  switch (inputType) {
    case 'boolean':
    case 'select': {
      const options = typeof columnMeta?.options === 'function'
        ? columnMeta?.options(row, column.id)
        : Array.isArray(columnMeta?.options)
          ? columnMeta?.options
          : undefined
      if (value == null || value === '') {
        const defaultOption = options?.find(o => o.default === true)
        if (defaultOption != null) {
          handleChange(String(defaultOption.value))
        }
      }
      return <CustomSelect autoFocus={autoFocus} title={title} style={{ minWidth: '85px' }} className={`w-full ${bg}`}
                           placeholder={String(t('required'))}
                           onChange={handleSelectChange} value={value ?? ''}>
        <option value="" label={String(t('selectOption'))} disabled={required === 'required'}/>
        <option label="---" disabled/>
        {options?.map((option: Option) => (
            <option key={option.value} value={option.value}>{colT(option.label ?? String(option.value))}</option>
        ))}
      </CustomSelect>
    }
    default: {
      const inputType = ['date', 'datetime', 'number'].includes(columnMeta?.type ?? '') ? columnMeta?.type : 'text'
      const autoCompleteList = columnMeta?.autoCompleteList === undefined
        ? undefined
        : asIterable<string>(columnMeta.autoCompleteList) ??
          (typeof columnMeta.autoCompleteList === 'function' // always true - this is necessary to make TS happy
            ? columnMeta.autoCompleteList(table, column.id, row)
            : undefined)

      return (
        <>
          <input autoFocus={autoFocus} title={title} className={`w-full ${bg}`}
                 value={value ?? ''}
                 min={columnMeta?.min}
                 max={columnMeta?.max}
                 placeholder={String(t(required))}
                 // we use onInput rather than onChange to also get events on invalid input, so we can color the bg red
                 onInput={handleInputChange}
                 list={`datalist-${column.id}`}
                 type={inputType}
          />
          <datalist id={`datalist-${column.id}`}>
            {autoCompleteList != null && mapIt(autoCompleteList, s => <option key={s} value={s}/>)}
          </datalist>
        </>
      )
    }
  }
}

export default TableCell
