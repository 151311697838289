import {
  createRow,
  type FilterMeta,
  flexRender, type Header,
  type Row,
  type RowSelectionState,
  type SortDirection, type Table as TanTable,
  type TableOptions,
  useReactTable
} from '@tanstack/react-table'
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { TrashIcon, FolderArrowDownIcon } from '@heroicons/react/24/solid'
import * as React from 'react'
import { type ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import TableRow from './tableRow'
import { callPeriodically, formatApolloErrors, isApolloAuthError, shortId } from '../../utils/utils'
import { isEqual, omit } from 'lodash'
import ConfirmationDialog from '../common/confirmationDialog'
import { useBeforeUnload, useLocation } from 'react-router-dom'
import { useLocalizedToast } from '../../hooks/useLocalizedToast'
import { type useTranslation } from 'react-i18next'
import { useGlobalState } from '../../hooks/useGlobalState'
import useSyncedRef from '../../hooks/useSyncedRef'
import { ApolloError } from '@apollo/client'
import { type IdResult } from '../../graphql/graphql'
import { MutationError } from '../../hooks/useEntityMutation'
import { valueExists } from './tableUtils'
import useForceReLogin from '../../hooks/useForceReLogin'

export interface Option {
  label?: string
  value: any
  default?: true
}

export interface ColumnMeta<RowData extends RecordWithId> {
  type?: 'severity' | 'date' | 'datetime' | 'number' | 'latitude' | 'longitude' | 'boolean'
  min?: number
  max?: number
  editColSpan?: number
  uniqueConstraint?: true | ((table: TanTable<RowData>, columnId: string, row: Row<RowData>) => any)
  autoCompleteList?: Iterable<string> | ((table: TanTable<RowData>, columnId: string, row: Row<RowData>) => Iterable<string>)
  filterValues?: (val: any) => string | string[]
  render?: (val: any) => ReactElement | string
  // translation for the column header caption
  captionT?: ReturnType<typeof useTranslation>['t'] | ((key: string) => string)
  t?: ReturnType<typeof useTranslation>['t'] | ((key: string) => string)
  options?: Option[] | ((row: Row<RowData>, columnId: string) => Option[])
  readonly?: boolean | ((row: Row<RowData>, columnId: string) => boolean)
  required?: 'required' | 'recommended' | ((row: Row<RowData>, columnId: string) => 'required' | 'recommended' | undefined)
  requiredText?: string
  validate?: (row: Row<RowData>, columnId: string) => boolean | undefined
  translate?: boolean
}

/**
 * Items that need to be accessible by TableCell cannot be put into TableProps - which TableCell cannot access - but
 * have to go into TableMeta instead
 */
export interface TableMeta<RowData extends RecordWithId> {
  i18n?: ReturnType<typeof useTranslation>['i18n']
  t?: ReturnType<typeof useTranslation>['t']
  rowClickHandlerFactory?: (row: RowData) => (() => void) | undefined
  editedRow?: RowData | null
  canDelete?: (row: RowData) => boolean
  enableMultiDelete?: boolean
  deleteRows?: (ids: string[]) => Promise<IdResult[]>
  downloadRows?: (rows: RowData[]) => Promise<void>
  saveEdit?: (row: RowData) => Promise<string>
  createNewItem?: () => RowData
  updateData?: (rowIdx: number, colId: string, val: any) => void
}

interface InCompBlocker {
  reset: () => void
  proceed: () => void
}

interface TableProps<RowData extends RecordWithId> {
  tableOptions: TableOptions<RowData>
  onEditedRowChange?: (editedRow: RowData | null) => void
  rowEditor?: (curRow: RowData | null, rowIdx: number, colId: string, NewVal: any) => RowData | null
  t: ReturnType<typeof useTranslation>['t']
  initialFilter?: string
  canCreateNewRow?: boolean
  createNewRowTitle?: string
}

export interface RecordWithId extends Record<string, any> {
  id: string
}

const Table = <RowData extends RecordWithId>({
  tableOptions,
  t,
  onEditedRowChange = undefined,
  rowEditor = (curRow: RowData | null, _: number, colId: string, newVal: any): RowData | null => curRow == null ? curRow : { ...curRow, [colId]: newVal },
  initialFilter = undefined,
  canCreateNewRow = true,
  createNewRowTitle = undefined
}: TableProps<RowData>): React.ReactElement => {
  const forceReLogin = useForceReLogin()
  const [persistingRows, setPersistingRows] = useState<Record<string, boolean>>({})
  const [recentlyChangedRows, setRecentlyChangedRows] = useState<Record<string, boolean>>({})

  const [globalFilter, setGlobalFilter] = useState<string>('')

  const [origData, setOrigData] = useState(tableOptions.data)

  const rowEditorRef = useSyncedRef(rowEditor)

  useEffect(() => {
    setOrigData(tableOptions.data)
    if (Object.keys(persistingRows).length > 0) {
      setPersistingRows({})
    }
  }, [tableOptions.data])

  const location = useLocation()
  useEffect(() => {
    // this will reset the filter in case there is a navigation to the current page (e.g. click on nav bar link)
    setGlobalFilter(initialFilter ?? '')
  }, [location])

  const [editedRowOrig, setEditedRowOrig] = useState<RowData | null>(null)
  const editedRowOrigRef = useSyncedRef(editedRowOrig)
  const [editedRow, _setEditedRow] = useState<RowData | null>(null)
  const setEditedRow = (row: RowData | null): void => {
    _setEditedRow(row)
    setEditedRowOrig(row)
  }

  const editedRowRef = useSyncedRef(editedRow)

  useEffect(() => {
    if (editedRow != null) {
      const elem = document.getElementById(editedRow.id)
      if (elem != null) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
    }
  }, [editedRow])

  useEffect(() => {
    if (onEditedRowChange != null) { onEditedRowChange(editedRow) }
  }, [editedRow, onEditedRowChange])

  const isNewRowCreatable = canCreateNewRow && editedRow?.id !== 'new'
  const newButtonTitle = createNewRowTitle ?? t('createNewItem')

  const selectionCheckBoxRef = useRef<HTMLInputElement>(null)
  const [data, setData] = useState(origData)

  useEffect(() => {
    setData(editedRow?.id === 'new'
      ? [editedRow, ...origData]
      : origData.map(o => o.id === editedRow?.id ? editedRow : o))
  }, [origData, editedRow])

  useEffect(() => {
    const editedRow = editedRowRef.current
    if (editedRow != null) {
      // here, we merge data that was (potentially) changed by another session into the current row edit
      // cells that this session changed should stay what this session set it to,
      // cells unchanged by this session to update to the latest value (potentially) updated by the other session
      const updatedRow = origData.find(i => i.id === editedRow.id)
      if (updatedRow != null) {
        const editedRowOrig = editedRowOrigRef.current
        if (editedRowOrig == null) {
          console.error('editedRowOrig unexpectedly null', editedRowOrig)
        } else {
          const mergedRow = { ...updatedRow }
          for (const [key, value] of Object.entries(editedRow)) {
            if (value !== editedRowOrig[key]) {
              mergedRow[key as keyof RowData] = value
            }
          }
          _setEditedRow(mergedRow)
          setEditedRowOrig(updatedRow)
        }
      } else {
        // row get deleted in other session
        setEditedRow(null)
      }
    }
  }, [origData])

  const { hasUnsavedChanges, setHasUnsavedChanges } = useGlobalState()
  useEffect(() => {
    let newHasUnsavedChanges = false
    if (editedRow != null) {
      const origRow = editedRow.id === 'new' ? tableMeta?.createNewItem?.() : origData.find(i => i.id === editedRow.id)
      newHasUnsavedChanges = !isEqual(origRow, editedRow)
    }
    setHasUnsavedChanges(newHasUnsavedChanges)
  }, [editedRow, origData])

  const showToast = useLocalizedToast()

  useBeforeUnload(useCallback(e => {
    if (hasUnsavedChanges) { e.preventDefault() }
  }, [hasUnsavedChanges]))

  const [inCompBlocker, setInCompBlocker] = useState<InCompBlocker | null>(null)
  const [deleteRequested, setDeleteRequested] = useState<RowData[] | null>(null)

  const tableMeta = tableOptions.meta as TableMeta<RowData>
  const isEditable = tableMeta?.saveEdit != null
  const isDeletable = tableMeta?.deleteRows != null
  const enableRowSelection = tableMeta?.enableMultiDelete === true || tableMeta?.downloadRows != null
  const hasEditColumn = isEditable || isDeletable || tableMeta?.createNewItem != null || enableRowSelection

  const abortEdit = useCallback((): void => {
    const editedRow = editedRowRef.current
    if (editedRow === null) { return }
    setEditedRow(null)
  }, [])

  useEffect(() => {
    let ctrlEnterHandledInKeyDown = false

    const handleKeyDown = (event: KeyboardEvent): void => {
      const isCtrlEnter = (event.ctrlKey || event.metaKey) && event.key === 'Enter'
      if (isCtrlEnter) {
        ctrlEnterHandledInKeyDown = true
        if (editedRowRef.current != null) {
          setSaveEditEvent(editedRowRef.current)
        }
      }
    }

    const handleKeyUp = (event: KeyboardEvent): void => {
      try {
        if (event.key === 'Escape') {
          abortEdit()
          return
        }

        const isCtrlEnter = (event.ctrlKey || event.metaKey) && event.key === 'Enter'
        if (isCtrlEnter && !ctrlEnterHandledInKeyDown && editedRowRef.current != null) {
          setSaveEditEvent(editedRowRef.current)
        }
      } finally {
        ctrlEnterHandledInKeyDown = false
      }
    }

    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const updateData = useCallback((rowIdx: number, colId: string, newVal: any) => {
    _setEditedRow((old: RowData | null) => rowEditorRef.current(old, rowIdx, colId, newVal))
  }, [])

  if (isEditable) {
    tableMeta.updateData = updateData
    tableMeta.editedRow = editedRow
  }

  // the validateInput forward declaration is necessary to resolve the circular dependency validateInput -> table -> saveEdit -> validateInput
  // eslint-disable-next-line prefer-const
  let validateInput: (row: RowData) => boolean

  const drawAttentionToRow = (id: string, markRecentlyChanged: boolean): void => {
    callPeriodically(() => {
      const elem = document.getElementById(id)
      if (elem != null) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        if (markRecentlyChanged) {
          setPersistingRows(old => omit(old, id))
          setRecentlyChangedRows(old => ({ ...old, [id]: true }))
          setTimeout(() => {
            setRecentlyChangedRows(old => omit(old, id))
          }, 5000)
        }
      }
      return elem == null
    }, 100, 50)
  }

  const saveEdit = useCallback(async (row: RowData): Promise<void> => {
    if (!isEditable) {
      return
    }
    if (editedRow == null || tableMeta == null) {
      throw new Error('Error ' + (editedRow == null ? 'editedRow' : 'tableMeta'))
    }

    if (!validateInput(row)) {
      return
    }

    const isNew = row.id === 'new'

    // temporarily set our own version of the edited data, until the refetch of allSites overwrites it with data from the server
    setOrigData(editedRow?.id === 'new'
      ? [editedRow, ...origData]
      : origData.map(o => o.id === editedRow?.id ? editedRow : o))
    setEditedRow(null)

    setPersistingRows(old => ({ ...old, [row.id]: true }))

    await tableMeta?.saveEdit?.(editedRow)
      .then((id: string) => { // we need to use id, not row.id, in the case of "new" ids, which will be changed by the server
        drawAttentionToRow(id, true)
      })
      .catch((error: any) => {
        setPersistingRows(old => omit(old, row.id))
        setEditedRow(row)
        drawAttentionToRow(row.id, false)
        if (error instanceof ApolloError) {
          if (isApolloAuthError(error)) {
            forceReLogin()
          } else {
            showToast('error', () => formatApolloErrors(error))
          }
        } else {
          const errs = error instanceof MutationError
            ? error.errors
            : Array.isArray(error)
              ? error
              : [error]
          errs.forEach(e => {
            showToast('error', () => t(isNew ? 'errorCreatingItem' : 'errorUpdatingItem', { reason: e, id: row.id }))
          })
        }
      })
  }, [isEditable, origData, editedRow, showToast, t, tableMeta])

  const [saveEditEvent, setSaveEditEvent] = useState<RowData>()
  useEffect(() => {
    if (saveEditEvent != null) {
      void saveEdit(saveEditEvent)
      setSaveEditEvent(undefined)
    }
  }, [saveEditEvent, saveEdit])

  const onConfirmDeletion = useCallback((): void => {
    if (deleteRequested == null) {
      throw new Error('deleteRequested == null')
    }
    const delReqArr = Array.isArray(deleteRequested) ? deleteRequested : [deleteRequested]
    for (const row of delReqArr) {
      const id = row.id
      if (id === undefined) {
        throw new Error('onConfirmDeletion: row?.id unexpectedly undefined')
      }
      setPersistingRows(old => ({ ...old, [id]: true }))
      setDeleteRequested(null)
    }
    const ids = delReqArr.map(r => r.id)
    tableMeta?.deleteRows?.(ids)
      .then(results => {
        const errors = results.filter(r => r.error != null).map(r => r.error ?? { type: 'MAKE TYPESCRIPT NULL DETECTOR HAPPY' })
        if (errors.length > 0) {
          showToast('error', () => (
            <ul className="list-disc ml-4">
              {errors.map((error, idx) => (
                <li key={idx}>{String(t(error.type, error.i18nOpts as Record<string, string>))}</li>
              ))}
            </ul>
          ))
        }
      })
      .finally(() => {
        for (const id of ids) {
          setPersistingRows(old => omit(old, id))
        }
      })
      .catch((error: any) => {
        if (error instanceof ApolloError) {
          if (isApolloAuthError(error)) {
            forceReLogin()
          } else {
            showToast('error', () => formatApolloErrors(error))
          }
        } else {
          const errs = Array.isArray(error) ? error : [error]
          errs.forEach(e => {
            showToast('error', () => t('errorDeletingItem', { reason: e/*, id: r.id */ }))
          })
        }
      })
  }, [deleteRequested, tableMeta, showToast])

  const doAfterCheckingUnsavedChanges = useCallback((fn: () => void): void => {
    if (hasUnsavedChanges) {
      setInCompBlocker({
        reset: () => { setInCompBlocker(null) },
        proceed: () => {
          abortEdit()
          setInCompBlocker(null)
          fn()
        }
      })
    } else {
      setEditedRow(null)
      fn()
    }
  }, [hasUnsavedChanges, abortEdit])

  const startEdit = useCallback((row: RowData) => {
    if (isEditable) {
      doAfterCheckingUnsavedChanges(() => { setEditedRow(row) })
    }
  }, [isEditable, doAfterCheckingUnsavedChanges])

  const deleteRow = useCallback((row: RowData) => {
    if (isDeletable) {
      doAfterCheckingUnsavedChanges(() => { setDeleteRequested([row]) })
    }
  }, [isDeletable, doAfterCheckingUnsavedChanges])

  const globalFilterFn = (row: Row<RowData>,
    columnId: string,
    filterValue: any,
    addMeta: (meta: FilterMeta) => void
  ): boolean => {
    if (editedRow?.id === row.id) {
      return true
    }
    const colDef = table.getColumn(columnId)?.columnDef
    const colFilterFn = colDef?.filterFn
    if (typeof colFilterFn === 'function') {
      return colFilterFn(row, columnId, filterValue, addMeta)
    }
    const colMeta: ColumnMeta<RowData> | undefined = colDef?.meta
    if (colMeta?.filterValues != null) {
      const rowFilterValue = colMeta.filterValues(row.getValue(columnId))
      if (typeof rowFilterValue === 'string') {
        return rowFilterValue.includes(String(filterValue))
      } else {
        const filterValueStr = String(filterValue)
        return rowFilterValue.some(rfv => rfv.includes(filterValueStr))
      }
    }
    if (colMeta?.render != null) {
      const rendered = colMeta.render(row.getValue(columnId))
      if (typeof rendered === 'string') {
        return rendered.includes(String(filterValue))
      }
    }
    const filterFn = table.getGlobalAutoFilterFn()
    return filterFn == null ? true : filterFn(row, columnId, filterValue, addMeta)
  }

  const table = useReactTable({
    ...tableOptions,
    globalFilterFn,
    enableRowSelection: row => tableMeta?.canDelete == null || tableMeta?.canDelete(row.original),
    onGlobalFilterChange: setGlobalFilter,
    state: { ...tableOptions.state, globalFilter },
    data
  })

  validateInput = useCallback((row: RowData): boolean => {
    const colDefs = table._getColumnDefs()
    const invalidFields: string[] = []
    const missingRecommendedFields: string[] = []
    const missingRequiredFields: string[] = []
    let colIdViolatedUniqueConstraint: string | null = null
    for (const colDef of colDefs) {
      const colMeta = colDef.meta as ColumnMeta<RowData>
      const accessorKey: string = (colDef as any).accessorKey
      const accessorFn: (row: RowData) => any = (colDef as any).accessorFn
      const colId = colDef.id ?? accessorKey
      if (accessorKey != null || accessorFn != null) {
        const val = accessorKey != null
          ? (row as any)[accessorKey]
          : accessorFn(row)
        const tanRow = createRow(table, row.id, row, -1, -1)
        if (colMeta?.uniqueConstraint != null && valueExists(table, colId, tanRow, colMeta?.uniqueConstraint)) {
          colIdViolatedUniqueConstraint = colId
          break
        }
        const required = colMeta?.required == null
          ? 'optional'
          : typeof colMeta.required === 'string'
            ? colMeta.required
            : colMeta.required(tanRow, colDef.id ?? '') ?? 'optional'
        const missingArray = required === 'required'
          ? missingRequiredFields
          : required === 'recommended'
            ? missingRecommendedFields
            : null
        if (colMeta?.validate != null) {
          if (colId != null) {
            const valid = colMeta?.validate(tanRow, colId)
            if (valid === false) {
              invalidFields.push(colId)
            } else if (valid === undefined && missingArray != null) {
              missingArray.push(colId)
            }
          }
        } else if ((val == null || val === '') && missingArray != null) {
          missingArray.push(colId)
        }
      }
    }
    const canSave = colIdViolatedUniqueConstraint == null && missingRequiredFields.length === 0 && invalidFields.length === 0
    if (!canSave) {
      if (colIdViolatedUniqueConstraint != null) {
        const colId = colIdViolatedUniqueConstraint
        showToast('warn', () => t('valuesOfColMustBeUnique', { column: t(colId) }))
      } else {
        showToast('warn', () => {
          return (
            <div>
              <h2 className="text-lg font-bold">{t('saveFailed')}</h2>
              {missingRequiredFields.length > 0 &&
                <div>
                  <h3 className="font-bold">{t('requiredFields')}</h3>
                  <ul>{missingRequiredFields.map(id => <li key={id} className="list-disc ml-4">{t(id)}</li>)}</ul>
                </div>
              }
              {invalidFields.length > 0 &&
                <div>
                  <h3 className="font-bold">{t('invalidFields')}</h3>
                  <ul>{invalidFields.map(id => <li key={id} className="list-disc ml-4">{t(id)}</li>)}</ul>
                </div>
              }
            </div>
          )
        })
      }
    }
    if (missingRecommendedFields.length > 0) {
      showToast('info', () => {
        return (
          <div>
            <h3 className="font-bold">{t('considerAddingRecommendedFields')}</h3>
            <ul>{missingRecommendedFields.map(id => <li key={id} className="list-disc ml-4">{t(id)}</li>)}</ul>
          </div>
        )
      })
    }
    return canSave
  }, [table, showToast])

  const newRow = table.getCoreRowModel().rowsById.new
  const renderRow = (row: Row<RowData>, rowIndex: number, predicate: ((row: Row<RowData>) => boolean) | null = null): false | React.JSX.Element =>
    (predicate === null || predicate(row)) &&
        <TableRow<RowData>
          key={row.id}
          row={row}
          rowIndex={rowIndex}
          rowClickHandler={tableMeta?.rowClickHandlerFactory != null ? tableMeta.rowClickHandlerFactory(row.original) : undefined}
          hasEditColumn={hasEditColumn}
          isEditable={isEditable}
          areRowsDeletable={isDeletable}
          enableRowSelection={enableRowSelection}
          isSelected={row.getIsSelected()}
          isEditing={editedRow?.id === row.id}
          isPersisting={persistingRows[row.id] != null}
          isRecentlyChanged={recentlyChangedRows[row.id] != null}
          t={t}
          abortEdit={abortEdit}
          startEdit={startEdit}
          canDelete={tableMeta?.canDelete}
          deleteRow={deleteRow}
          saveEdit={saveEdit}
        />

  const filteredRowModel = table.getFilteredRowModel()
  const selectedRowModel = table.getSelectedRowModel()

  useEffect(() => {
    if (selectionCheckBoxRef.current != null) {
      const selectedRowCount = selectedRowModel.rows.length
      selectionCheckBoxRef.current.indeterminate = selectedRowCount > 0 && selectedRowCount < table.getCoreRowModel().rows.length
    }

    // unselect rows that got filtered out after being selected
    // the following commented out lines throw "Error: getRow expected an ID, but got ba09500f-761b-4931-a75c-58c7264d07aa"
    // for (const row of selectedRowModel.rows) {
    //   if (filteredRowModel.rowsById[row.id] == null) {
    //     row.toggleSelected(false)
    //   }
    // }
    table.setRowSelection(selection => {
      let newSelection: RowSelectionState | null = null
      for (const selectedId in selection) {
        if (filteredRowModel.rowsById[selectedId] == null) {
          if (newSelection == null) {
            newSelection = { ...selection }
          }
          const { [selectedId]: _, ...rest } = newSelection
          newSelection = rest
        }
      }
      return newSelection ?? selection
    })
  }, [selectedRowModel, filteredRowModel])

  const toggleSelect = (): void => {
    const selectableRowCount = filteredRowModel.rows
      .reduce((agg, row) => row.getCanSelect() ? agg + 1 : agg, 0)
    const selectedRowCount = selectedRowModel.rows.length
    if (selectedRowCount === 0 || selectedRowCount < selectableRowCount) {
      for (const row of filteredRowModel.rows) {
        if (!row.getIsSelected()) {
          row.toggleSelected(true)
        }
      }
    } else {
      table.resetRowSelection()
    }
  }

  const deleteSelectedRows = (): void => {
    const selectedRows = table.getSelectedRowModel().rows.map(row => row.original)
    if (selectedRows.length > 0) {
      setDeleteRequested(selectedRows)
    }
  }

  const downloadSelectedRows = async (): Promise<void> => {
    const selectedRows = table.getSelectedRowModel().rows.map(row => row.original)
    if (selectedRows.length > 0) {
      await tableMeta?.downloadRows?.(selectedRows)
    }
  }

  const editCol = hasEditColumn &&
    <th key="edit" scope="col" className="sticky left-0 bg-white">
      <div className="flex items-center">
        {enableRowSelection &&
          <input ref={selectionCheckBoxRef} type="checkbox" title={t('selectAll')}
                 onClick={() => { toggleSelect() }} className="mr-1 cursor-pointer"
                 checked={selectedRowModel.rows.length > 0} readOnly={true}/>
        }
        {table.getSelectedRowModel().rows.length > 0
          ? <div className="flex items-center">
            {tableMeta?.enableMultiDelete === true &&
              <button title={t('deleteSelectedItems', { count: selectedRowModel.rows.length })}
                      name="delete" onClick={deleteSelectedRows}>
                <TrashIcon className="w-6 h-6 text-red-500"/>
              </button>}
            {tableMeta?.downloadRows != null &&
              <button title={t('downloadSelectedItems', { count: selectedRowModel.rows.length })}
                      name="download" onClick={() => { void downloadSelectedRows() }}>
                <FolderArrowDownIcon className="w-6 h-6 text-black"/>
              </button>}
          </div>
          : tableMeta?.createNewItem != null &&
          <button title={newButtonTitle}
                  disabled={!isNewRowCreatable}
                  onClick={() => {
                    doAfterCheckingUnsavedChanges(() => {
                      if (tableMeta?.createNewItem == null) {
                        throw new Error('tableMeta?.createNewItem == null')
                      }
                      setEditedRow(tableMeta.createNewItem())
                    })
                  }}
                  className={`px-2 mr-1 whitespace-nowrap ${isNewRowCreatable ? 'btn' : 'btn-disabled'}`}>{t('createNew')}</button>
        }
      </div>
    </th>

  function renderColumnCaption (header: Header<RowData, unknown>): React.ReactNode | JSX.Element {
    if (header.isPlaceholder) {
      return null
    }
    let headerCaption = header.column.columnDef.header
    if (typeof headerCaption === 'string') {
      const colMeta: ColumnMeta<RowData> | undefined = header.column.columnDef?.meta
      const localT = colMeta?.captionT ?? t

      const titleKey = headerCaption + 'Title'
      const titleValue = localT(titleKey)

      headerCaption = localT(headerCaption)
      if (titleKey !== titleValue) { // there exists a i18n entry for the title (but it may be from a fallback language)
        return <span title={titleValue}>{headerCaption}</span>
      }
    }
    return flexRender(headerCaption, header.getContext())
  }

  return (
    <div className="relative flex flex-col h-full">
      <div className="relative w-full flex items-center p-2">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="w-6 h-6 text-gray-400"/>
        </div>
        <input type="text"
               placeholder={t('searchItems', { itemCount: data.length })}
               value={globalFilter}
               onChange={e => { setGlobalFilter(e.target.value) }}
               className={`p-2 pl-10 pr-10 ${globalFilter !== '' ? 'bg-lime-50' : ''} rounded border border-gray-300 focus:outline-none focus:border-blue-500`}/>
        {(globalFilter !== '') && (
          <XCircleIcon className="w-6 h-6 -ml-8 text-gray-400" onClick={_ => {
            setGlobalFilter('')
          }}/>
        )}
        <div className="grow"/>
      </div>
      {inCompBlocker != null &&
        <ConfirmationDialog
          title={t('discardChangesQ')}
          textHtml={t('discardChangesText_HTML')}
          onCancel={inCompBlocker.reset}
          confirmBtnClass="btn-destructive"
          onConfirm={inCompBlocker.proceed}
          confirmText={t('discardChangesBtn')}
          cancelText={t('continueEditingBtn')}
        />}
      {deleteRequested != null && (deleteRequested.length > 1
        ? <ConfirmationDialog
          title={t('deleteItemsQ', { count: deleteRequested.length })}
          textHtml={t('sureToDeleteItems_HTML', { count: deleteRequested.length })}
          onCancel={() => { setDeleteRequested(null) }}
          confirmBtnClass="btn-destructive"
          onConfirm={() => { onConfirmDeletion() }}
          confirmText={t('delete')}
        />
        : <ConfirmationDialog
          title={t('deleteItemQ')}
          textHtml={t('sureToDeleteItem_HTML', { ...(deleteRequested[0] as RecordWithId), id: shortId(deleteRequested[0].id ?? '') })}
          onCancel={() => { setDeleteRequested(null) }}
          onConfirm={() => { onConfirmDeletion() }}
          confirmBtnClass="btn-destructive"
          confirmText={t('delete')}
        />)}

      <div tabIndex={-1} className="overflow-auto mt-1 pl-2">
        <table tabIndex={-1} className="min-w-full focus:outline-none mb-2">
          <thead className="sticky top-0 bg-white text-gray-500 z-20">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {editCol}
              {headerGroup.headers.map(header => (
                <th key={header.id} scope="col" className="select-none px-1">
                  <div className={header.column.getCanSort() ? 'flex cursor-pointer' : 'flex'}
                       onClick={header.column.getToggleSortingHandler()}>
                    {header.column.getCanSort()
                      ? <span
                        className={`flex truncate px-2 ${header.column.getIsSorted() !== false ? 'bg-gray-100 rounded' : null}`}>
                          {renderColumnCaption(header)}
                        {{
                          asc: <ChevronUpIcon className="w-auto h-5 pl-[0.2rem] pt-[0.3rem]"/>,
                          desc: <ChevronDownIcon className="w-auto h-5 pl-[0.2rem] pt-[0.3rem]"/>,
                          none: <ChevronDownIcon className="opacity-0 w-auto h-5 pl-[0.2rem] pt-[0.3rem]"/>
                        }[header.column.getIsSorted() === false ? 'none' : (header.column.getIsSorted() as SortDirection)]}
                        </span>
                      : renderColumnCaption(header)}
                    <span className="grow"/>
                  </div>
                </th>
              ))}
            </tr>
          ))}
          {newRow != null && renderRow(newRow, -1)}
          </thead>
          <tbody>
          {/* using predicate instead of Array.filter, which is not lazy */}
          {table.getRowModel().rows.map((row, i) => renderRow(row, i + 1, r => r.id !== 'new'))}
          </tbody>
        </table>
        {table.getCoreRowModel().rows.length === 0
          ? <div className="flex justify-center items-center">
            <div className="text-center text-gray-300 text-xl font-bold">{t('noItemsYet')}</div>
          </div>
          : table.getFilteredRowModel().rows.length === 0 &&
          <div className="flex justify-center items-center">
            <div className="text-center text-gray-300 text-xl font-bold">{t('changeFilterToShowItems')}</div>
          </div>
        }
      </div>
    </div>
  )
}
export default Table
