import * as React from 'react'
import { type Site, type Turbine } from '../../graphql/graphql'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Map, type MapRef, Marker, type ViewState } from 'react-map-gl/maplibre'
import { LngLat, LngLatBounds } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { setLanguage } from '../../utils/mapUtils'
import '../../types/images.d'
import satelliteImgUrl from '../../../assets/images/satellite.jpg'
import streetsImgUrl from '../../../assets/images/streets.jpg'
import TurbineBarGraph from '../common/turbineBarGraph'
import { groupBy, isEqual } from 'lodash'
import SiteTable, { type SiteTableProps } from './siteTable'
import useEntityQuery from '../../hooks/useEntityQuery'

const padding = { left: 170, top: 30, bottom: 30, right: 20 }
const defaultViewState = {
  zoom: 4.3,
  latitude: 38.053,
  longitude: 139.952,
  bearing: -0,
  pitch: 0,
  padding
}

const mapLanguage = 'ja'

function viewStateFromBounds (mapRef: MapRef | null, bounds: LngLatBounds | null): ViewState {
  if (mapRef == null || bounds?._sw == null) { return defaultViewState }
  const cameraBounds = mapRef.getMap().cameraForBounds(bounds, { padding })
  const cameraBoundsCenter = cameraBounds.center != null
    ? LngLat.convert(cameraBounds.center)
    : new LngLat(defaultViewState.longitude, defaultViewState.latitude)
  return {
    zoom: cameraBounds.zoom ?? defaultViewState.zoom,
    latitude: cameraBoundsCenter.lat,
    longitude: cameraBoundsCenter.lng,
    bearing: -0,
    pitch: 0,
    padding
  }
}

const SiteMapAndTable: React.FC = () => {
  const sites = useEntityQuery<Site>('Site', { suspense: true })
  const turbines = useEntityQuery<Turbine>('Turbine', { suspense: true })
  const { id } = useParams()
  const site = id == null
    ? null
    : sites.find(s => s.id === id) ?? null
  const navigate = useNavigate()
  const mapRef = useRef<MapRef | null>(null)
  const [satelliteMode, setSatelliteMode] = useState(false)
  const [editedRow, setEditedRow] = useState<Site | null>(null)

  const siteToTurbines = useMemo(() => groupBy(turbines, t => t.siteId),
    [turbines])

  const siteToBounds: Record<string, LngLatBounds | null> = useMemo(() =>
    Object.fromEntries(sites.map(site => {
      const siteTurbines = siteToTurbines[site.id]
      const siteBounds = new LngLatBounds()
      siteTurbines?.forEach(turbine => {
        if (turbine.latitude != null && turbine.longitude != null) {
          siteBounds.extend({ lat: turbine.latitude, lon: turbine.longitude })
        }
      })
      return [site.id, siteBounds._sw == null ? null : siteBounds]
    })
    ), [sites, turbines])

  const siteBounds = site?.id == null ? null : siteToBounds[site.id]
  const [viewState, setViewState] = useState<ViewState>(viewStateFromBounds(mapRef?.current, siteBounds))

  function fitMapBounds (map: MapRef, bounds: LngLatBounds): void {
    const newViewState = viewStateFromBounds(map, bounds)
    const changed = !isEqual(viewState, newViewState)
    if (changed) { setViewState(newViewState) }
  }

  useEffect(() => {
    const newBounds = new LngLatBounds()
    if (id != null) {
      const siteBounds = siteToBounds[id]
      if (siteBounds != null) {
        newBounds.extend(siteBounds)
      }
    }
    if (newBounds._sw == null) { // either id is null or there is no siteToBounds data for it
      for (const siteBounds of Object.values(siteToBounds)) {
        if (siteBounds != null) {
          newBounds.extend(siteBounds)
        }
      }
    }
    if (mapRef.current != null) { fitMapBounds(mapRef.current, newBounds) }
  }, [siteToBounds, id])

  function mapMounted (node: MapRef): void {
    mapRef.current = node
    if (node != null) {
      // on("load") only fires the very first time, which is why I'm now using "styledata" - but it's firing a bit
      // too often
      node.getMap().on('styledata', () => {
        setLanguage(node.getMap(), mapLanguage)
      })
      // if (site)
      //     fitMapBounds(node, siteBounds(site))
    }
  }

  const rowClickHandlerFactory: SiteTableProps['rowClickHandlerFactory'] =
      (row: Site) => row.id !== 'new' ? () => { navigate('/sites/' + row.id) } : undefined

  return (
      <Map
          reuseMaps={true}
          // ref={mapRef}
          ref={node => {
            if (node != null) mapMounted(node)
          }}
          {...viewState}
          onMove={evt => {
            setViewState(evt.viewState)
          }}
          renderWorldCopies={true}
          dragRotate={false}
          touchPitch={false}
          touchZoomRotate={false}
          mapStyle={satelliteMode
          // ? "https://api.maptiler.com/maps/satellite/style.json?key=UsgEH2ijh9fNYsMWbNLo"
            ? 'https://api.maptiler.com/maps/hybrid/style.json?key=UsgEH2ijh9fNYsMWbNLo'
            : 'https://api.maptiler.com/maps/streets/style.json?key=UsgEH2ijh9fNYsMWbNLo'
          }
      >
        {viewState.zoom < 12
          ? sites.map((site) => {
            const siteLngLat = site.id != null ? siteToBounds[site.id]?.getCenter() : undefined
            return siteLngLat != null && site.name != null &&
                      <Marker key={site.id} latitude={siteLngLat.lat}
                              longitude={siteLngLat.lng}
                              onClick={() => {
                                navigate('/sites/' + site.id)
                              }}>

                        {/* <div className="relative inline-block group"> */}
                        <div className="cursor-pointer">
                          {/* <Tooltip */}
                          {/*    title="Tooltip" */}
                          {/*    html={<div className="bg-white text-black text-lg p-1">{site.name}</div>}> */}
                          <TurbineBarGraph text={site.name}/>
                          {/* </Tooltip> */}
                        </div>
                      </Marker>
          })
          : sites.flatMap((site) => {
            const siteTurbines = siteToTurbines[site.id]
            return (siteTurbines ?? []).map(turbine => {
              return turbine.longitude != null && turbine.latitude != null && turbine.name != null
                ? <Marker key={site.id + '/' + turbine.id} latitude={turbine.latitude}
                                  longitude={turbine.longitude}
                                  onClick={e => {
                                    console.log('marker clicked', e)
                                  }}>
                          <div className="cursor-pointer">
                            {/* <div className="relative inline-block group"> */}
                            {/* <Tooltip */}
                            {/*    title="Tooltip" */}
                            {/*    html={<div className="bg-white text-black text-lg p-1">{turbine.name}</div>}> */}
                            <TurbineBarGraph text={turbine.name}/>
                            {/* </Tooltip> */}
                          </div>
                        </Marker>
                : null
            }
            )
          })}

        <div tabIndex={-1}
             className={`z-50 absolute ${editedRow != null ? 'right-4' : 'w-1/4'} bottom-4 top-4 left-4 bg-white overflow-auto focus:outline-none`}>
          <SiteTable onEditedRowChange={setEditedRow} rowClickHandlerFactory={rowClickHandlerFactory}/>
        </div>

        <div className="z-40 absolute bottom-6 right-4 bg-white overflow-auto border">
          <img width={75} onClick={() => {
            setSatelliteMode(!satelliteMode)
          }} alt=""
               src={satelliteMode ? streetsImgUrl : satelliteImgUrl}/>
        </div>
      </Map>)
}

export default SiteMapAndTable
