import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FocusTrap from 'focus-trap-react'
import { PROD } from '../../utils/utils'
import { ArrowUpCircleIcon as ArrowUpCircleIconOutline, XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowUpCircleIcon as ArrowUpCircleIconSolid } from '@heroicons/react/24/solid'

interface ConfirmationDialogProps extends React.ComponentProps<any> {
  dialogWidthClass?: string
  onConfirm?: () => void
  onCancel: () => void
  title: string
  textHtml?: string
  confirmText?: string
  confirmTitle?: string
  cancelText?: string
  cancelTitle?: string
  confirmBtnClass?: string
  cancelBtnClass?: string
  autoFocusOnConfirm?: boolean
  children?: React.ReactNode
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> =
    ({
      onConfirm = undefined,
      onCancel,
      title,
      textHtml = undefined,
      autoFocusOnConfirm = false,
      confirmText = null,
      confirmTitle = undefined,
      cancelText = null,
      cancelTitle = undefined,
      confirmBtnClass = 'btn',
      cancelBtnClass = 'btn-lowkey',
      children = undefined,
      dialogWidthClass = 'max-w-screen-sm',
      ...restProps
    }) => {
      const { t } = useTranslation()
      const [active, setActive] = useState(PROD)

      // for some reason, attaching 'autoFocus={autoFocusOnConfirm}' did not do the trick, hence this fallback to
      // imperatively changing focus
      const confirmButtonRef = useRef<HTMLButtonElement>(null)
      const xButtonRef = useRef<HTMLButtonElement>(null)

      useEffect(() => {
        if (autoFocusOnConfirm && confirmButtonRef.current != null) {
          confirmButtonRef.current.focus()
        } else if (!PROD && xButtonRef.current != null) { // only in prod the focus trap takes care of focusing inside dialog
          xButtonRef.current.focus()
        } else { // the following attempt to blur does unfortunately not work:
          // if (document.activeElement instanceof HTMLElement) {
          //   document.activeElement.blur()
          //   window.setTimeout(() => {
          //     document.body.focus()
          //   }, 0)
          // }
        }
        const handleKeyUp = (event: { key: string }): void => {
          if (event.key === 'Escape') { onCancel() }
        }
        window.addEventListener('keyup', handleKeyUp)
        return () => { window.removeEventListener('keyup', handleKeyUp) }
      }, [])

      const focusTrapToggleButton = !PROD &&
        <button
          className="absolute top-0 left-0 mt-3 ml-3 text-xl font-semibold rounded"
          title="[DEV] (De)activate FocusTrap"
          onClick={() => {
            setActive(a => !a)
          }}>
          {active
            ? <ArrowUpCircleIconSolid className="h-6"/>
            : <ArrowUpCircleIconOutline className="h-6"/>}
        </button>

      const className = `relative ${dialogWidthClass} max-h-full flex flex-col bg-white pointer-events-auto px-6 py-3 rounded-lg shadow-lg z-50 ${restProps.className}`

      return (
        <FocusTrap active={active}>
          <div
            className={`fixed inset-0 bg-black bg-opacity-50 ${PROD ? '' : 'pointer-events-none'} z-40 flex justify-center items-center`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="dialogTitle"
            aria-describedby="dialogDesc">
            <div {...restProps} className={className}>
              {focusTrapToggleButton}
              <button
                ref={xButtonRef}
                className="absolute top-0 right-0 mt-3 mr-3 text-xl font-semibold rounded"
                onClick={onCancel}>
                <XMarkIcon className="h-6"/>
              </button>
              <h2 className="text-xl font-semibold text-center mb-5 mx-6" id="dialogTitle">{title}</h2>
              <div className="overflow-auto flex-grow">
                <div className="min-h-0">
                  {textHtml != null && <div dangerouslySetInnerHTML={{ __html: textHtml }}/>}
                  {children}
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button onClick={onCancel} className={`mr-1 py-2 px-4 ${cancelBtnClass}`} title={cancelTitle}>
                  {cancelText ?? t('cancel')}
                </button>
                <button ref={confirmButtonRef} onClick={onConfirm} title={confirmTitle}
                        className={`ml-1 py-2 px-4 ${confirmBtnClass}`}>
                  {confirmText ?? t('confirm')}
                </button>
              </div>
            </div>
          </div>
        </FocusTrap>
      )
    }

export default ConfirmationDialog
