import { type DocumentNode } from 'graphql/language'
import { type FetchResult, useMutation } from '@apollo/client'
import { type RecordWithId } from '../components/table/table'
import { type Scalars } from '../graphql/graphql'

export class MutationError extends Error {
  errors: Array<Scalars['String']['output']>

  constructor (message: string, errors: Array<Scalars['String']['output']>) {
    super(message)
    this.name = 'MutationError'
    this.errors = errors
  }
}

export type GetErrorsFunction = (result: FetchResult<any>) => Array<Scalars['String']['output']>
export type GetEntityIdFunction<Entity extends RecordWithId> = (result: FetchResult<any>) => Entity

function useEntityMutation<Entity extends RecordWithId> (mutation: DocumentNode, getErrors: GetErrorsFunction, getEntity: GetEntityIdFunction<Entity>): (entity: Entity) => Promise<string> {
  const [upsert] = useMutation(mutation)
  return async (entity: Entity): Promise<string> => {
    const isNew = entity.id === 'new'
    if (isNew) {
      entity = { ...entity, id: '' }
    }
    return await upsert({
      variables: entity
    }).then(response => {
      const errors = getErrors(response)
      if (errors.length > 0) {
        throw new MutationError('Error saving entity', errors)
      }
      return getEntity(response).id
    })
  }
}

export default useEntityMutation
