import * as React from 'react'

interface CheckBoxProps {
  checked: boolean | 'half'
  className?: string
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, className = null }) => {
  const combinedClassName = `existing-class ${className ?? ''}`.trim()
  return (<div className={combinedClassName}>
        <div className="border border-black m-1">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                strokeDasharray={checked === 'half' ? '2,2' : ''}
                fill="none"
                stroke={checked !== false ? 'currentColor' : 'none'}
                strokeWidth="2"
                aria-hidden="true"
                className="h-4 w-auto">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z">

                </path>
            </svg>
        </div>
    </div>)
}

export default CheckBox
