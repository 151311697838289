import { useQuery } from '@apollo/client'
import { ENTITY_QUERY } from '../graphql/queriesAndMutations'
import {
  isEntitySubscriptionCompleted,
  watchEntitySubscriptionCompletion
} from './useEntitySubscription'

interface UseCacheQueryOptions {
  suspense?: boolean
  id?: string
}

function useEntityQuery<T> (entityType: string, options: UseCacheQueryOptions = {}): T[] {
  const isCompleted = isEntitySubscriptionCompleted(entityType, options.id)
  if (isCompleted !== true && options?.suspense === true) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw watchEntitySubscriptionCompletion(entityType, options.id)
  }
  const { data, error } = useQuery(ENTITY_QUERY, {
    variables: { entityType, id: options.id },
    fetchPolicy: 'cache-only'
  })
  if (error != null) {
    throw error
  }
  return data?.entity as T[]
}

export default useEntityQuery
