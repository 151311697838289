import React from 'react'
import { useTranslation } from 'react-i18next'
import ContentMessageComponent from './contentMessageComponent'

interface NotFoundComponentProps {
  location: string
}

const NotFoundComponent: React.FC<NotFoundComponentProps> = ({ location }) => {
  const { t } = useTranslation()
  return <ContentMessageComponent title={t('notFound')} message={`${location}: ${t('notFoundSentence')}`}/>
}

export default NotFoundComponent
