import * as React from 'react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center">
      <ArrowPathIcon className="animate-spin h-5 w-5 text-blue-500" aria-hidden="true" />
    </div>
  )
}

export default LoadingSpinner
