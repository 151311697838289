import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useEntityQuery from '../hooks/useEntityQuery'
import type { Inspection, Site, Turbine } from '../graphql/graphql'
import useTenant from '../hooks/useTenant'

export function Dashboard (): React.JSX.Element {
  const sites = useEntityQuery<Site>('Site', { suspense: true })
  const turbines = useEntityQuery<Turbine>('Turbine', { suspense: true })
  const inspections = useEntityQuery<Inspection>('Inspection', { suspense: true })
  const tenant = useTenant()
  const { t } = useTranslation('dashboard')

  const siteCount = sites.length ?? 0
  const turbineCount = turbines.length ?? 0
  const inspectionCount = inspections.length ?? 0
  const counts: Array<[string, number]> = [
    ['sites', siteCount],
    ['turbines', turbineCount],
    ['inspections', inspectionCount]
  ]
  return (
        <div className="h-full p-2 col-span-2 bg-gray-200 flex justify-center items-center text-center">
            <div className="flex flex-col justify-center items-center text-center">
                <h1 className="text-xl font-bold">{t('dashboardFor', { tenantName: tenant?.displayName ?? t('loading') })}</h1>
                <table className="min-w-full border-collapse block md:table">
                    <tbody className="block md:table-row-group">
                    {counts.map(([name, count], i) => (
                        <tr key={i} className="border border-gray-300 md:border-none block md:table-row">
                            <th className="bg-gray-100 p-2 md:border md:border-gray-300 text-left block md:table-cell">{t(name)}</th>
                            <td className="bg-white p-2 md:border md:border-gray-300 text-left block md:table-cell">{count}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
  )
}
