import { gql } from '@apollo/client'

const TENANT_FIELDS = gql`
    fragment TenantFields on Tenant {
        id
        asciiName
        displayName
        curUserIsMember
        updatedAt
    }
`

const TENANT_PROPERTIES_FIELDS = gql`
    fragment TenantPropertiesFields on TenantProperties {
        id
        properties
        updatedAt
    }
`

const USER_PROPERTIES_FIELDS = gql`
    fragment UserPropertiesFields on UserProperties {
        id
        locale
        updatedAt
    }
`

const PERMISSION_FIELDS = gql`
    fragment PermissionFields on Permission {
        id
        resourceType
        resourceId
        action
        updatedAt
    }
`

const SITE_FIELDS = gql`
    fragment SiteFields on Site {
        id
        name
        address
        city
        state
        postalCode
        region
        country
        timezone
        updatedAt
    }
`

const TURBINE_FIELDS = gql`
    fragment TurbineFields on Turbine {
        id
        name
        siteId
        serialNo
        model
        bladeLength
        bladeModel
        latitude
        longitude
        codDate
        eowDate
        hubHeight
        capacity
        maker
        statusOnline
        bladeRootDiameter
        updatedAt
    }
`

const INSPECTION_FIELDS = gql`
    fragment InspectionFields on Inspection {
        id
        turbineId
        plannedDate
        actualDate
        inspectionMethod
        inspector
        status
        cameraModel
        inspectionFirm
        reportBlobId
        reportByteSize
        inspectionImageCount
        inspectionImagesByteSize
        updatedAt
    }
`

const INSPECTION_IMAGE_FIELDS = gql`
    fragment InspectionImageFields on InspectionImage {
        id
        filename
        contentType
        byteSize
        metadata
        imageBlobId
        updatedAt
    }
`

export const FRAGMENTS = [
  TENANT_FIELDS,
  TENANT_PROPERTIES_FIELDS,
  USER_PROPERTIES_FIELDS,
  PERMISSION_FIELDS,
  SITE_FIELDS,
  TURBINE_FIELDS,
  INSPECTION_FIELDS,
  INSPECTION_IMAGE_FIELDS
]
