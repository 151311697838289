type ObservableStateMapListener<T> = (newValue: T | undefined, oldValue: T | undefined, key: string) => void
type ObservableStateMapUpdater<T> = (curValue: T | undefined, key: string) => T | undefined

interface ObservableStateData<T> {
  value?: T
  listeners: Set<ObservableStateMapListener<T>>
}

export class ObservableStateMap<T> {
  private readonly _map = new Map<string, ObservableStateData<T>>()

  subscribe (key: string, listener: ObservableStateMapListener<T>, initialValue: T | undefined = undefined): () => void {
    const stateData = this.getOrCreateStateData(key, initialValue)
    stateData.listeners.add(listener)

    return () => {
      stateData.listeners.delete(listener)
    }
  }

  get (key: string, defaultValue?: T | undefined): T | undefined {
    const stateData = this._map.get(key)
    return stateData == null ? defaultValue : stateData.value
  }

  set (key: string, newValue: T | undefined): T | undefined {
    return this.setAndNotifyIfChanged(this.getOrCreateStateData(key), key, newValue)
  }

  update (key: string, updater: ObservableStateMapUpdater<T>): T | undefined {
    const stateData = this.getOrCreateStateData(key)
    const newVal = updater(stateData.value, key)
    return this.setAndNotifyIfChanged(stateData, key, newVal)
  }

  private getOrCreateStateData (key: string, initialValue?: T | undefined): ObservableStateData<T> {
    let stateData = this._map.get(key)
    if (stateData == null) {
      stateData = { value: initialValue, listeners: new Set<ObservableStateMapListener<T>>() }
      this._map.set(key, stateData)
    }
    return stateData
  }

  private setAndNotifyIfChanged (stateData: ObservableStateData<T>, key: string, newValue: T | undefined): T | undefined {
    const oldValue = stateData.value
    if (!Object.is(oldValue, newValue)) {
      stateData.value = newValue
      for (const listener of stateData.listeners) {
        listener(newValue, oldValue, key)
      }
    }
    return oldValue
  }
}
