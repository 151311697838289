import * as ActionCable from '@rails/actioncable'
import { type ActionCableListener } from '../types/customTypes'
import { getMeta } from '../utils/utils'
import { type ConnectionEstablishedEvent } from '../types/cableEvents'
import { type AuthData, toQueryString } from '../hooks/useGlobalState'

interface SubscriptionState {
  subscription: ActionCable.Subscription | null
  listeners: Set<ActionCableListener>
}

class ActionCableManager {
  private consumer: ActionCable.Consumer | null = null
  private url: string | null = null
  private readonly subscriptions: Map<string, SubscriptionState> = new Map<string, SubscriptionState>()
  private lastConnectionEstablishedMessage: ConnectionEstablishedEvent | undefined

  private createUrl (authData: AuthData, tenantId: string): string {
    return `${getMeta('urlBase')}/cable?${toQueryString(authData)}${tenantId == null ? '' : ('&tid=' + tenantId)}`
  }

  private connectUrl (url: string): ActionCable.Consumer {
    this.url = url
    this.consumer = ActionCable.createConsumer(url)
    for (const [ch, subscriptionState] of this.subscriptions) {
      const subscription = this.consumer.subscriptions.create(ch, { received: (data: any) => { this.handleMessageReceived(ch, data) } })
      const listeners = subscriptionState.listeners
      this.subscriptions.set(ch, { subscription, listeners })
    }
    return this.consumer
  }

  disconnect (): void {
    if (this.consumer != null) {
      for (const [ch, subscriptionState] of this.subscriptions) {
        this.subscriptions.set(ch, { subscription: null, listeners: subscriptionState.listeners })
      }
      this.consumer.disconnect()
      this.consumer = null
      this.url = null
    }
  }

  connect (authData: AuthData, tenantId: string): ActionCable.Consumer | null {
    const url = this.createUrl(authData, tenantId)
    if (url !== this.url || this.consumer == null) {
      this.disconnect()
      this.subscriptions.clear()
      return this.connectUrl(url)
    } else {
      this.consumer.ensureActiveConnection()
      return this.consumer
    }
  }

  private handleMessageReceived (channel: string, data: any): void {
    if (data?.event === 'connectionEstablished') {
      this.lastConnectionEstablishedMessage = data as ConnectionEstablishedEvent
    }
    const ss = this.subscriptions.get(channel)
    if (ss?.listeners != null) {
      ss.listeners.forEach(listener => { listener(channel, data) })
    }
  }

  public subscribe (channel: string, listener: ActionCableListener, getLastConnectionEstablishedMessage = false): void {
    if (getLastConnectionEstablishedMessage && this.lastConnectionEstablishedMessage != null) {
      listener(channel, this.lastConnectionEstablishedMessage)
    }
    let subscriptionState = this.subscriptions.get(channel)
    if (subscriptionState == null) {
      const listeners = new Set<ActionCableListener>()
      const subscription = this.consumer == null
        ? null
        : this.consumer.subscriptions.create(channel, {
          received: (data: any) => { this.handleMessageReceived(channel, data) }
        })
      subscriptionState = { subscription, listeners }
      this.subscriptions.set(channel, subscriptionState)
    }
    subscriptionState.listeners.add(listener)
  }

  public unsubscribe (channel: string, listener: ActionCableListener): void {
    const subscriptionState = this.subscriptions.get(channel)
    if (subscriptionState != null) {
      subscriptionState.listeners.delete(listener)
      if (subscriptionState.listeners.size === 0) {
        this.subscriptions.delete(channel)
        if (subscriptionState.subscription != null) {
          subscriptionState.subscription.unsubscribe()
        }
      }
    }
  }
}

export const actionCableManager = new ActionCableManager()
