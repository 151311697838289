import React from 'react'

interface ContentMessageComponentProps {
  title?: string
  message: string
}

const ContentMessageComponent: React.FC<ContentMessageComponentProps> = ({ title = '', message }) => {
  return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-4xl font-semibold text-gray-900 mb-4">{title}</h1>
                <p className="text-lg text-gray-700">{message}</p>
            </div>
        </div>
  )
}

export default ContentMessageComponent
