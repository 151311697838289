import * as React from 'react'
import { Suspense, useCallback, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import NavigationBar from './navigationBar'
import { isApolloAuthError } from '../utils/utils'
import ApplicationCable from './applicationCable'
import { useActionCable } from '../hooks/useActionCable'
import { useEntitySubscription } from '../hooks/useEntitySubscription'
import SuspenseFallback from './common/suspenseFallback'
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary'
import ContentMessageComponent from './common/contentMessageComponent'
import NotFoundComponent from './common/notFoundComponent'
import { NotFoundError } from '../errors/notFoundError'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from '../hooks/useGlobalState'

export default function Content (): React.JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { onLogout } = useGlobalState()
  const { error: tenantsSubError } = useEntitySubscription('Tenant')
  const { error: tenantPropertiesSubError } = useEntitySubscription('TenantProperties')
  const { error: userPropertiesSubError } = useEntitySubscription('UserProperties')
  const { error: permissionsSubError } = useEntitySubscription('Permission')
  const { error: sitesSubError } = useEntitySubscription('Site')
  const { error: turbinesSubError } = useEntitySubscription('Turbine')
  const { error: inspectionsSubError } = useEntitySubscription('Inspection')

  const isAuthError = isApolloAuthError(tenantsSubError) ||
      isApolloAuthError(tenantPropertiesSubError) ||
      isApolloAuthError(userPropertiesSubError) ||
      isApolloAuthError(permissionsSubError) ||
      isApolloAuthError(sitesSubError) ||
      isApolloAuthError(turbinesSubError) ||
      isApolloAuthError(inspectionsSubError)

  useEffect(() => {
    if (isAuthError) {
      console.log('Session expired - redirecting to login page')
      void onLogout()
      navigate('/login')
    }
  }, [isAuthError])

  const onReceived = useCallback<(channel: string, data: any) => void>((_: string, data: any) => {
    switch (data?.event) {
      case 'connectionEstablished': {
        // void allSitesRefetch() // we may have missed messages between disconnect and reconnect
        break
      }
      case 'update': {
        switch (data?.resource) {
          case 'site':
          case 'turbine':
          case 'inspection':
            // void allSitesRefetch()
        }
      }
    }
  }, [])
  const { subscribe, unsubscribe } = useActionCable()
  useEffect(() => {
    subscribe('AppChannel', onReceived)
    return () => {
      unsubscribe('AppChannel', onReceived)
    }
  }, [])

  if (sitesSubError != null) {
    console.log('Content allSitesError', sitesSubError, 'authErr?', isAuthError, Object.keys(sitesSubError ?? {}))
    return <p>Something went wrong. Error: {sitesSubError.message}</p>
  }

  function errorRender ({ error }: FallbackProps): React.JSX.Element {
    if (error instanceof NotFoundError) {
      return <NotFoundComponent location={error.location}/>
    } else {
      return <ContentMessageComponent title={t('error')} message={error.message}/>
    }
  }

  return (
    <>
      <ApplicationCable/>
      <NavigationBar/>
      <div className="overflow-hidden">
        <ErrorBoundary fallbackRender={errorRender} key={location.pathname}>
          <Suspense fallback={<SuspenseFallback/>}>
            <Outlet/>
          </Suspense>
        </ErrorBoundary>
      </div>
    </>)
}
