import { useTranslation } from 'react-i18next'

import * as React from 'react'
import { useNavigate, Navigate, useLocation } from 'react-router-dom'
import { ApolloError, gql, useMutation } from '@apollo/client'
import { useState } from 'react'
import { useLocalizedToast } from '../hooks/useLocalizedToast'
import useSyncedRef from '../hooks/useSyncedRef'
import { handleApolloErrors, stripOffUrlBase } from '../utils/utils'
import LoadingSpinner from './common/loadingSpinner'
import Input from './common/input'
import { useGlobalState } from '../hooks/useGlobalState'

const USER_UPDATE_PASSWORD_WITH_TOKEN_MUTATION = gql`
    mutation UserUpdatePasswordWithTokenAndSignIn($resetPasswordToken: String!, $password: String!, $passwordConfirmation: String!) {
        updatePasswordWithTokenAndSignIn (resetPasswordToken: $resetPasswordToken,
            password: $password,
            passwordConfirmation: $passwordConfirmation,
        ) {
            tenantId
            authenticatable {
                email
            }
            credentials {
                accessToken
                client
                expiry
                tokenType
                uid
            }
        }
    }
`

export default function UpdatePassword (): React.JSX.Element | null {
  const { t } = useTranslation('updatePassword')
  const showToast = useLocalizedToast()

  const { onLogin } = useGlobalState()
  const navigate = useNavigate()
  const location = useLocation()
  const navigateTo: string = location.hash === '' ? '/' : location.hash.substring(1)
  const navigateToRef = useSyncedRef(navigateTo)
  const [userSendPasswordResetWithToken] = useMutation(USER_UPDATE_PASSWORD_WITH_TOKEN_MUTATION)
  const [isLoading, setIsLoading] = useState(false)
  const [newPassword, setNewNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const { setTenantId, setLocale } = useGlobalState()

  const canSubmit = newPassword !== '' && newPasswordConfirmation === newPassword

  const queryArgsToken = new URLSearchParams(location.search).get('reset_password_token')
  if (queryArgsToken != null) {
    return <Navigate to={stripOffUrlBase(location.pathname)} state={ { resetPasswordToken: queryArgsToken } }/>
  }
  const token = location.state?.resetPasswordToken
  if (token == null) {
    return <Navigate to={navigateTo}/>
  }

  const handleSubmit = async (): Promise<void> => {
    if (!canSubmit) {
      return
    }
    setIsLoading(true)
    userSendPasswordResetWithToken({
      variables: {
        resetPasswordToken: token,
        password: newPassword,
        passwordConfirmation: newPasswordConfirmation
      }
    }).then(response => {
      const user: string = response.data.updatePasswordWithTokenAndSignIn.authenticatable.email
      const tenantId: string | null = response.data.updatePasswordWithTokenAndSignIn.tenantId ?? null
      const locale: string | null = response.data.updatePasswordWithTokenAndSignIn.locale

      setTenantId(tenantId)

      if (locale != null) {
        setLocale(locale)
      }

      void onLogin(user)
        .then(() => {
          showToast('success', () => t('passwordUpdatedSuccessfully'))
          // setAc() needs to do its (asynchronous) job before we can navigate, hence the setTimeout()
          setTimeout((): void => {
            navigate(navigateToRef.current, { replace: true })
          }, 100)
        })
        .catch(err => {
          console.error('error in onLogin', err)
        })
    }).catch(error => {
      if (error instanceof ApolloError) {
        handleApolloErrors(error)
      } else {
        showToast('warn', () => String(error))
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className="p-2 col-span-2 bg-gray-200 flex justify-center items-center text-center">
      <div>
        <h1 className="p-1 text-lg font-bold">{t('updatePassword')}</h1>
        <div className="p-1">
          <Input
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder={t('newPassword')}
            value={newPassword}
            onChange={e => { setNewNewPassword(e.target.value) }}
            onEnterDown={() => { void handleSubmit() }}
            autoComplete="off"
          />
        </div>
        <div className="p-1">
          <Input
            type="password"
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder={t('confirmNewPassword')}
            value={newPasswordConfirmation}
            onChange={e => { setNewPasswordConfirmation(e.target.value) }}
            onEnterDown={() => { void handleSubmit() }}
            autoComplete="off"
          />
        </div>
        <div className="p-1 flex justify-center items-center text-center">
          <button className={`${canSubmit ? 'btn' : 'btn-disabled'} m-2 py-1 px-4`}
                  onClick={() => { void handleSubmit() }}
                  type="submit">{t('updatePasswordBtn')}
          </button>
          {isLoading && <LoadingSpinner/>}
        </div>
      </div>
    </div>
  )
}
