import useEntityQuery from './useEntityQuery'
import type { Permission } from '../graphql/graphql'
import { useMemo } from 'react'
import { NULL_UUID } from '../utils/constants'

const resourceTypeToView: Record<string, string> = {
  site: 'sites',
  turbine: 'turbines',
  inspection: 'inspections'
}

type PermissionAction = 'create' | 'read' | 'update' | 'delete' | 'request'

type PermissionActions = Set<string>
type PermissionsByResourceId = Map<string, PermissionActions>

export interface Permissions {
  enabledViews: Set<string>
  canDo: (resourceType: string, action: PermissionAction, resourceId: string) => boolean
}

export default function usePermissions (): Permissions {
  const permissions = useEntityQuery<Permission>('Permission')
  return useMemo(() => {
    const enabledViews = new Set<string>()
    const permissionByResourceType = new Map<string, PermissionsByResourceId>()

    for (const permission of (permissions ?? [])) {
      const view = resourceTypeToView[permission.resourceType]
      if (view != null) {
        enabledViews.add(view)
      }
      let resourceTypePermissions = permissionByResourceType.get(permission.resourceType)
      if (resourceTypePermissions == null) {
        resourceTypePermissions = new Map()
        permissionByResourceType.set(permission.resourceType, resourceTypePermissions)
      }
      let resourceIdActions = resourceTypePermissions.get(permission.resourceId)
      if (resourceIdActions == null) {
        resourceIdActions = new Set()
        resourceTypePermissions.set(permission.resourceId, resourceIdActions)
      }
      resourceIdActions.add(permission.action)
    }

    function canDo (resourceType: string, action: string, resourceId?: string): boolean {
      const p = permissionByResourceType.get(resourceType)
      if (p == null) {
        return false
      }
      // permission for any id
      const allIdPermission = p.get(NULL_UUID)
      if (allIdPermission != null && allIdPermission.has(action)) {
        return true
      }
      // id specific permission
      if (resourceId != null) {
        const idPermission = p.get(resourceId)
        if (idPermission != null && idPermission.has(action)) {
          return true
        }
      }
      return false
    }
    return { enabledViews, canDo }
  }, [permissions])
}
