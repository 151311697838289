import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { snakeToCamelCase } from '../../utils/utils'
import ConfirmationDialog from '../common/confirmationDialog'

export interface Candidate {
  col_name: string
  col_idx: number
  score: number
}

export interface ColumnMatch {
  required: boolean
  candidates: Candidate[]
}

interface MetadataFormatConfirmProps {
  metadataFilename: string
  columnMatches: Record<string, ColumnMatch>
  rows: Array<Array<string | number>>
  onConfirm: () => void
  onCancel: () => void
  setColumnSelections: (selections: Record<string, number>) => void
}

const MetadataFormatConfirm: React.FC<MetadataFormatConfirmProps> = (
  { columnMatches, rows, metadataFilename, onConfirm, onCancel, setColumnSelections }) => {
  const { t } = useTranslation('inspectionDetailsMetadata')
  const colEntries = Object.entries(columnMatches)

  const [selectedOptions, setSelectedOptions] = useState<Array<string | null>>(
    colEntries.map(([, colMatch]) => colMatch.candidates[0] != null ? String(colMatch.candidates[0].col_idx) : null)
  )
  const [duplicatedSelects, setDuplicatedSelects] = useState<Set<number>>(new Set<number>())

  useEffect(() => {
    const map: Array<[string, number]> = selectedOptions
      .map((val, idx): [string, number] | null => val === null ? null : [colEntries[idx][0], Number(val)])
      .filter(val => val !== null) as Array<[string, number]>
    setColumnSelections(Object.fromEntries(map.map(([k, v]) => [String(k), v])))

    // now calculate duplicate selections
    const dupeCols = new Set<number>()
    const seen = new Set<number>()

    selectedOptions.forEach((val, idx) => {
      if (val != null) {
        const sel = Number(val)
        if (seen.has(sel)) {
          dupeCols.add(idx)
        } else {
          seen.add(sel)
        }
      }
    })
    setDuplicatedSelects(dupeCols)
  }, [selectedOptions])

  useEffect(() => {
    const handleKeyUp = (event: { key: string }): void => {
      if (event.key === 'Escape') { onCancel() }
    }
    window.addEventListener('keyup', handleKeyUp)
    return () => { window.removeEventListener('keyup', handleKeyUp) }
  }, [])

  const handleSelectChange = (index: number, value: string): void => {
    const updatedOptions = [...selectedOptions]
    updatedOptions[index] = value
    setSelectedOptions(updatedOptions)
  }

  return (
    <ConfirmationDialog
      dialogWidthClass="max-w-screen-2xl"
      title={t('adjustAndConfirmColumnMapping', { metadataFilename })}
      onCancel={onCancel}
      onConfirm={duplicatedSelects.size > 0 ? () => {} : onConfirm}
      confirmTitle={duplicatedSelects.size > 0 ? t('fieldMappingMustBeUnique') : ''}
      confirmBtnClass={duplicatedSelects.size > 0 ? 'btn-disabled' : 'btn'}
    >
      <table tabIndex={-1}>
        <thead className="sticky top-0 bg-white">
        <tr>
          {colEntries.map(([colName, colMatch], index) => (
            <th key={index}>{t(snakeToCamelCase(colName))}{colMatch.required &&
              <span className="text-red-500" title={t('required')}>*</span>}</th>
          ))}
        </tr>
        <tr>
          {colEntries.map(([, colMatch], index) => (
            colMatch.candidates[0] != null
              ? <th key={index}>
                <select className={`${duplicatedSelects.has(index) ? 'border border-red-600 focus:border-red-600' : ''}`}
                  value={selectedOptions[index] ?? ''}
                  onChange={(e) => { handleSelectChange(index, e.target.value) }}
                >
                  {!colMatch.required && <>
                    <option value="-1">{t('noSelection')}</option>
                    <option disabled>---</option>
                  </>}
                  {colMatch.candidates.map((option, idx) => (
                    <option key={idx} value={option.col_idx}>
                      {option.col_name}
                    </option>
                  ))}
                </select>
              </th>
              : <th key={index}>N/A</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {rows.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {colEntries.map(([, colMatch], colIndex) =>
              <td key={colIndex}>{colMatch.candidates[0] != null && selectedOptions[colIndex] !== '-1' ? row[Number(selectedOptions[colIndex])] : ''}</td>
            )}
          </tr>
        ))}
        </tbody>
      </table>
    </ConfirmationDialog>
  )
}

export default MetadataFormatConfirm
