import { type MutableRefObject, useEffect, useRef } from 'react'

export default function useSyncedRef<T> (state: T): MutableRefObject<T> {
  const ref = useRef<T>(state)

  useEffect(() => {
    ref.current = state
  }, [state])

  return ref
}
