import { getAuthHeaders } from './auth'
import { type AuthData } from '../hooks/useGlobalState'
import { getFilenameFromContentDisposition, getMeta, safeFilename } from './utils'
import { throttle } from 'lodash'
import { type ProgressBarProps } from '../components/common/progressBar'

interface DownloadOptions {
  filename?: string
  showInNewTab?: boolean
  save?: boolean
  setProgress?: (filename: string, v: ProgressBarProps | null) => void
  expectedFileSize?: number
}

export const downloadMultiple = async (blobIds: string[], authData: AuthData, tenantId: string, options?: DownloadOptions): Promise<void> => {
  const url = `${getMeta('urlBase')}/asm/${options?.filename ?? ''}`
  const expectedFilename = options?.filename ?? 'LATODA.zip'
  const responsePromise = fetch(url, {
    method: 'POST',
    headers: getAuthHeaders(authData, { tid: tenantId, 'Content-Type': 'application/json' }),
    body: JSON.stringify(blobIds)
  })
  await handleResponse(responsePromise, options?.save === true, options?.showInNewTab === true, options, expectedFilename, 'application/zip')
}

export const downloadSingle = async (blobId: string, authData: AuthData, tenantId: string, options?: DownloadOptions): Promise<void> => {
  const url = `${getMeta('urlBase')}/as/${blobId}/${options?.filename ?? ''}`
  const expectedFilename = options?.filename ?? 'LATODA_' + blobId

  const responsePromise = fetch(url, {
    method: 'GET',
    headers: getAuthHeaders(authData, { tid: tenantId })
  })
  await handleResponse(responsePromise, options?.save === true, options?.showInNewTab === true, options, expectedFilename, 'application/octet-stream')
}

export const buildReportFilename = (inspectionId: string, updatedAt: string): string => {
  return safeFilename(`LATODA_REN-AI_Report_${inspectionId}_${updatedAt}.pdf`)
}

async function handleResponse (responsePromise: Promise<Response>, save: boolean, showInNewTab: boolean, options: DownloadOptions | undefined, filename: string, expectedContentType: string): Promise<void> {
  const setProgress = options?.setProgress
  try {
    setProgress?.(filename, { label: filename, progress: 0 })
    const response = await responsePromise
    if (response.body == null || !response.ok) {
      console.error('Network response was not ok', response)
      throw new Error('Network response was not ok')
    }

    const contentDisposition = response.headers.get('Content-Disposition')
    const filenameFromContentDisposition = contentDisposition == null ? null : getFilenameFromContentDisposition(contentDisposition)
    if (filenameFromContentDisposition != null && filename !== filenameFromContentDisposition) {
      // remove progress bar for expectedFilename
      setProgress?.(filename, null)
      filename = filenameFromContentDisposition
      // add progress bar for actual filename
      setProgress?.(filename, { label: filename, progress: 0 })
    }
    const contentType = response.headers.get('Content-Type') ?? expectedContentType

    const contentLength = response.headers.get('Content-Length')
    let totalBytes = options?.expectedFileSize ?? 0
    if (contentLength != null) {
      totalBytes = parseInt(contentLength, 10)
    }
    totalBytes = Math.max(0, totalBytes) // fix negative values being passed in as options.expectedFileSize

    const chunks = []
    let receivedBytes = 0
    const reader = response.body.getReader()

    // We need to throttle the progress update to setProgress, otherwise it pegs one cpu core for 2 minutes (in the case of a 250MB file)
    const throttledSetProgress = throttle((progress: number) => {
      setProgress?.(filename, { label: filename, progress, nonPercentageProgress: totalBytes === 0 })
    }, 100)

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      chunks.push(value)
      receivedBytes += value.length
      throttledSetProgress(totalBytes === 0 ? receivedBytes : Math.min(100, Math.max(0, (receivedBytes / totalBytes) * 100)))
    }

    const chunksAll = new Uint8Array(receivedBytes)
    let position = 0
    for (const chunk of chunks) {
      chunksAll.set(chunk, position)
      position += chunk.length
    }

    const blob = new Blob(chunks, { type: contentType })

    const blobUrl = URL.createObjectURL(blob)
    if (save) {
      const downloadAnchor = document.createElement('a')
      downloadAnchor.href = blobUrl
      downloadAnchor.download = filename
      document.body.appendChild(downloadAnchor)
      downloadAnchor.click()
      downloadAnchor.remove()
    }

    if (showInNewTab) {
      const showInNewTabFn = (): void => {
        const openAnchor = document.createElement('a')
        openAnchor.href = blobUrl
        openAnchor.target = '_blank'
        document.body.appendChild(openAnchor)
        openAnchor.click()
        openAnchor.remove()
      }
      if (save) {
      // when download == showInNewTab == true, Safari doesn't download, if showInNewTab is executed synchronously
      // this is perhaps related to it showing that "do you want to allow downloads on 'hostname'?" dialog?
        setTimeout(showInNewTabFn, 0)
      } else {
        showInNewTabFn()
      }
    }

    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl)
    }, showInNewTab ? 120_000 : 0) // to be on the safe side, give the browser enough time to open the new tab
  } finally {
    setTimeout(() => {
      setProgress?.(filename, null)
    }, 500)
  }
}
