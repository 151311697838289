import * as React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  type SortingState, type ColumnFiltersState, getFilteredRowModel, type TableOptions
} from '@tanstack/react-table'
import { type Site, type Turbine } from '../../graphql/graphql'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../types/images.d'
import { getTimeZoneForCountry, isTimeZoneOfCountry, shortId } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { l10nCountry } from '../../i18n/i18n'
import Table, { type ColumnMeta, type TableMeta } from '../table/table'
import TableCell from '../table/tableCell'
import { getAlpha2Codes } from 'i18n-iso-countries'
import { tz } from 'moment-timezone'
import { DELETE_SITES_MUTATION, UPSERT_SITE_MUTATION } from '../../graphql/queriesAndMutations'
import useEntityMutation from '../../hooks/useEntityMutation'
import useEntityQuery from '../../hooks/useEntityQuery'
import useDeleteEntitiesMutation from '../../hooks/useDeleteEntitiesMutation'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'

const columnHelper = createColumnHelper<SitePlus>()

type SitePlus = Site & {
  turbines?: Turbine[]
}

function createNewItem (): SitePlus {
  return ({
    id: 'new',
    name: '',
    address: null,
    city: null,
    state: null,
    postalCode: null,
    region: null,
    country: '',
    timezone: '',
    updatedAt: ''
  })
}

export interface SiteTableProps {
  onEditedRowChange?: (site: Site | null) => void
  rowClickHandlerFactory?: (site: Site) => (() => void) | undefined
}

const SiteTable: React.FC<SiteTableProps> = ({ onEditedRowChange = undefined, rowClickHandlerFactory = undefined }) => {
  const { t, i18n } = useTranslation('siteTable')
  useDocumentTitle(t('sites'))

  const sites = useEntityQuery<Site>('Site', { suspense: true })
  const turbines = useEntityQuery<Turbine>('Turbine', { suspense: true })

  const sitesPlus = useMemo(() => sites.map(s => ({ ...s, turbines: turbines.filter(t => t.siteId === s.id) })), [sites, turbines])

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false }
  ])

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    // {id: "name", value: "h" }
  ])

  const saveEdit = useEntityMutation(UPSERT_SITE_MUTATION, r => r.data.upsertSite.errors, r => r.data.upsertSite.site)
  const deleteRows = useDeleteEntitiesMutation(DELETE_SITES_MUTATION, r => r.data.deleteSites.results)

  const columns = [
    columnHelper.accessor('id', {
      header: 'id',
      cell: TableCell,
      meta: {
        readonly: true,
        render: (id: string) => <span title={id}>{shortId(id)}</span>
      } satisfies ColumnMeta<SitePlus>
    }),
    columnHelper.accessor('name', {
      header: 'name',
      cell: TableCell,
      filterFn: 'includesString',
      meta: {
        required: 'required',
        uniqueConstraint: true
      } satisfies ColumnMeta<SitePlus>
    }),
    columnHelper.accessor(row => row.turbines?.length, {
      id: 'turbines',
      header: 'turbines',
      cell: info => (
        <Link tabIndex={-1} to="/turbines" state={{ filter: info.row.id }}>
          <div className="inline-flex items-center">
            <CursorArrowRaysIcon className="h-6 text-blue-700"/>
            {info.getValue()}
          </div>
        </Link>
      )
    }),
    columnHelper.accessor('country', {
      header: 'country',
      cell: TableCell,
      meta: {
        required: 'required',
        translate: true,
        t: (key: string) => l10nCountry(String(key), i18n),
        options: Object.keys(getAlpha2Codes())
          .sort((c1: string, c2: string) => (l10nCountry(c1, i18n).localeCompare(l10nCountry(c2, i18n))))
          .map(code => ({ value: code }))
      } satisfies ColumnMeta<SitePlus>
    }),
    columnHelper.accessor('timezone', {
      header: 'timezone',
      cell: TableCell,
      meta: {
        required: 'required',
        options: tz.names().map(code => ({ value: code }))
      } satisfies ColumnMeta<SitePlus>
    }),
    columnHelper.accessor('address', { header: 'address', cell: TableCell }),
    columnHelper.accessor('city', { header: 'city', cell: TableCell }),
    columnHelper.accessor('state', { header: 'state', cell: TableCell }),
    columnHelper.accessor('postalCode', { header: 'postalCode', cell: TableCell }),
    // columnHelper.accessor('region', { header: 'region', cell: TableCell })
    columnHelper.accessor('updatedAt', { header: 'updatedAt', cell: TableCell, meta: { type: 'datetime', readonly: true } })
  ]

  const tableMeta: TableMeta<SitePlus> = {
    t,
    i18n,
    rowClickHandlerFactory,
    canDelete: site => (site.turbines ?? []).length === 0,
    enableMultiDelete: true,
    deleteRows,
    saveEdit,
    createNewItem
  }
  const tableOptions: TableOptions<SitePlus> = {
    data: sitesPlus,
    columns,
    getRowId: row => row.id,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter: _ => true,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnFilters
    },
    meta: tableMeta
  }

  const rowEditor = (curRow: Site | null, _: number, colId: string, newVal: any): Site | null => {
    if (curRow == null) {
      return null
    }
    if (colId === 'country' && typeof newVal === 'string' && curRow.country !== newVal &&
        (curRow.timezone == null || !isTimeZoneOfCountry(newVal, curRow.timezone))) {
      const defaultTZ = getTimeZoneForCountry(newVal)
      return defaultTZ != null ? { ...curRow, country: newVal, timezone: defaultTZ } : { ...curRow, country: newVal }
    }
    return { ...curRow, [colId]: newVal }
  }

  return <Table
      tableOptions={tableOptions}
      t={t}
      onEditedRowChange={onEditedRowChange}
      rowEditor={rowEditor}/>
}

export default SiteTable
