import * as React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements, Navigate,
  Route,
  RouterProvider,
  useLocation
} from 'react-router-dom'
import Login from './components/login'
import Content from './components/content'
import Header from './components/header'
import { ProtectedLayout } from './components/auth/protectedLayout'
import useDocumentTitle from './hooks/useDocumentTitle'
import { Dashboard } from './components/dashboard'
import TurbineTable from './components/turbineTable'
import SiteView from './components/sites/siteView'
import InspectionTable from './components/inspections/inspectionTable'
import InspectionDetails from './components/inspections/inspectionDetails'
import RootComponent from './components/rootComponent'
import { getMeta } from './utils/utils'
import { ActionCableProvider } from './hooks/useActionCable'
import ApolloClientManager from './components/apolloClientManager'
import NotFoundComponent from './components/common/notFoundComponent'
import UpdatePassword from './components/updatePassword'

export function NotFound (): React.JSX.Element {
  const { pathname } = useLocation()
  return <NotFoundComponent location={pathname}/>
}

function App (): React.JSX.Element {
  useDocumentTitle('')
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootComponent/>}>
        <Route element={<ActionCableProvider/>}>
          <Route element={<ApolloClientManager/>}>
            <Route element={<Header/>}>
              <Route path="login" element={<Login/>}/>
              <Route path="update-password" element={<UpdatePassword/>}/>
              <Route path="welcome" element={<UpdatePassword/>}/>

              <Route element={<ProtectedLayout/>}>
                <Route element={<Content/>}>
                  <Route index element={<Navigate to="/sites" replace/>}/>
                  <Route path="insights/*" element={<Dashboard/>}/>
                  <Route path="sites/:id?" element={<SiteView/>}/>
                  <Route path="turbines/*" element={<TurbineTable/>}/>
                  <Route path="inspections" element={<InspectionTable/>}/>
                  <Route path="inspections/:id" element={<InspectionDetails/>}/>

                  <Route path="*" element={<NotFound/>}/>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    ), { basename: getMeta<string | undefined>('urlBase', undefined) })

  return (
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
  )
}

export default App
