import * as React from 'react'

export const SeverityCellRenderer = (value: number | null): React.JSX.Element => {
  const getSeverityColorClass = (severity: number | null): string => {
    if (severity === 1) return 'bg-lime-400'
    if (severity === 2) return 'bg-yellow-400'
    if (severity === 3) return 'bg-amber-400'
    if (severity === 4) return 'bg-orange-400'
    if (severity === 5) return 'bg-red-400'
    return 'bg-white' // Default color
  }

  return (
        <div className="flex items-center">
            <div className={'w-4 h-4 mr-1 ' + getSeverityColorClass(value)}/>
            {value}
        </div>
  )
}
