import * as React from 'react'

interface TurbineBarGraphProps {
  ratios?: number[]
  text?: string
}

const TurbineBarGraph: React.FC<TurbineBarGraphProps> = ({ ratios = null, text = null }) => {
  const colors = ['red', 'yellow', 'green']
  const openSpace = ratios == null ? 0 : 1 - ratios.reduce((acc, cur) => acc + cur, 0)
  const towerX = 85.887
  const towerY = 51.287
  const towerW = 21.911
  const towerH = 128.36
  const width = 130.78
  const height = 215.99
  const strokeWidth = 3
  const outlineColor = '#FFF'
  const scale = 0.44
  return (
        <svg width={width * scale} height={height * scale} strokeWidth={strokeWidth} version="1.1"
             viewBox={`-40 -10 240 ${height + 100}`}
             xmlns="http://www.w3.org/2000/svg"
             strokeLinejoin="round">
            <g transform="translate(-17.118 36.25)" fill="#fff" stroke="#000">

                {/* OUTLINE */}
                <g strokeWidth={strokeWidth * 8} stroke={outlineColor} fill="none">
                    <rect x={towerX} y={towerY} width={towerW} height={towerH}/>
                    <path
                        d="m97.825 33.256s32.432-3.7978 37.729 6.2518c5.2966 10.05 5.209 8.1222-1.3784 16.203-6.5874 8.0804-37.729-3.0938-37.729-3.0938z"/>
                    <path d="m93.885 15.711 33.701-51.889-23.55 73.154-13.589-4.3745z"/>
                    <path d="m118.27 57.441 29.519 54.377-53.051-55.603 10.329-9.8546z"/>
                    <path d="m78.005 59.805-60.829 11.317 69.25-33.324 6.1903 12.864z"/>
                </g>

                {ratios != null
                  ? <g strokeWidth="0">
                        {ratios.map((ratio, index) => (
                            <rect
                                key={index}
                                x={towerX + strokeWidth / 2}
                                y={towerY + ((openSpace + (index === 0 ? 0 : ratios.slice(0, index).reduce((acc, val) => acc + val, 0))) * towerH)}
                                width={towerW - strokeWidth}
                                height={`${ratio * towerH}`}
                                fill={colors[index]}
                            />
                        ))}
                    </g>
                  : null
                }
                <rect fill="none" x={towerX} y={towerY} width={towerW} height={towerH}/>
                <path
                    d="m97.825 33.256s32.432-3.7978 37.729 6.2518c5.2966 10.05 5.209 8.1222-1.3784 16.203-6.5874 8.0804-37.729-3.0938-37.729-3.0938z"/>
                <g>
                    <path d="m93.885 15.711 33.701-51.889-23.55 73.154-13.589-4.3745z"/>
                    <path d="m118.27 57.441 29.519 54.377-53.051-55.603 10.329-9.8546z"/>
                    <path d="m78.005 59.805-60.829 11.317 69.25-33.324 6.1903 12.864z"/>
                </g>
                <ellipse cx="95.832" cy="43.182" rx="9.245" ry="12.843"/>
                {/* <rect x="76" y="36" width="40" height="20" fill="rgba(255, 255, 255, 0.7)" rx="5"/> */}
                {text != null && <>
                    <text strokeWidth="20" stroke={outlineColor} x="96.308"
                          y={height + 20} fontWeight="800" textAnchor="middle" dominantBaseline="middle" fontSize="54"
                          fill="none">{text}</text>
                    <text strokeWidth="0" x="96.308"
                          y={height + 20} fontWeight="800" textAnchor="middle" dominantBaseline="middle" fontSize="54"
                          fill="#00f">{text}</text>
                </>}
            </g>
        </svg>
  )
}

export default TurbineBarGraph
