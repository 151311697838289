import type * as ActionCable from '@rails/actioncable'
import { createContext, useContext, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import * as React from 'react'
import { actionCableManager } from '../cable/actionCableManager'
import type { ActionCableListener } from '../types/customTypes'
import { useGlobalState } from './useGlobalState'

interface ActionCableContextType {
  consumer: ActionCable.Consumer | null
  subscribe: (channel: string, listener: ActionCableListener, getLastConnectionEstablishedMessage?: boolean) => void
  unsubscribe: (channel: string, listener: ActionCableListener) => void
}

const subscribe = actionCableManager.subscribe.bind(actionCableManager)
const unsubscribe = actionCableManager.unsubscribe.bind(actionCableManager)

const nullActionCableContext: ActionCableContextType = {
  consumer: null,
  subscribe,
  unsubscribe
}

const ActionCableContext = createContext<ActionCableContextType>(nullActionCableContext)

export const ActionCableProvider: React.FC = () => {
  const { tenantId, authData } = useGlobalState()

  const value = useMemo<ActionCableContextType>(
    () => {
      if (authData == null) {
        actionCableManager.disconnect()
      }
      const consumer = authData == null ? null : actionCableManager.connect(authData, tenantId ?? '')
      return {
        consumer,
        subscribe,
        unsubscribe
      }
    }, [authData, tenantId])
  return <ActionCableContext.Provider value={value}><Outlet/></ActionCableContext.Provider>
}

export function useActionCable (): ActionCableContextType {
  return useContext(ActionCableContext)
}
