import * as React from 'react'
import { DateTime } from 'luxon'
import { type i18n } from 'i18next'

interface FormattedDateProps {
  i18n?: i18n
  date?: string
}

const FormattedDate: React.FC<FormattedDateProps> = ({ i18n, date }) => {
  const formatDate = (date?: string): string => {
    return date == null
      ? ''
      : DateTime.fromISO(date).toFormat('yyyy/M/d')
  }
  const formatDateTime = (date?: string): string => {
    return date == null
      ? ''
      : i18n == null ? date : DateTime.fromISO(date).setLocale(i18n.language).toLocaleString(DateTime.DATE_FULL)
  }
  return <div title={formatDateTime(date)}>{formatDate(date)}</div>
}

export default FormattedDate
