import * as React from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import '../types/images.d'
import TurbineIcon from '../../assets/images/turbine.svg?react'
import LatodaLogo from '../../assets/images/latoda.svg?react'
import DeFlag from '../../../node_modules/flag-icons/flags/4x3/de.svg?react'
import GbFlag from '../../../node_modules/flag-icons/flags/4x3/gb.svg?react'
import JpFlag from '../../../node_modules/flag-icons/flags/4x3/jp.svg?react'
import { useTranslation } from 'react-i18next'
import { GlobeAltIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { supportedLanguages } from '../i18n/i18n'
import useForceReLogin from '../hooks/useForceReLogin'
import { getMeta, PROD, uuidRegex } from '../utils/utils'
import useTenant from '../hooks/useTenant'
import SelectPopup from './selectPopup'
import Tippy from '@tippyjs/react'
import { gql, useMutation } from '@apollo/client'
import useEntityQuery from '../hooks/useEntityQuery'
import type { Tenant, TenantProperties } from '../graphql/graphql'
import { useGlobalState } from '../hooks/useGlobalState'

const SET_LAST_TID_MUTATION = gql`
    mutation SetLastTid($tid: String!) {
        setLastTid (input: {tid: $tid}) {
            errors
        }
    }
`

const SET_LOCALE_MUTATION = gql`
    mutation SetLocale($locale: String!) {
        setLocale (input: {locale: $locale}) {
            errors
        }
    }
`

const Header = (): React.JSX.Element => {
  const { t, i18n } = useTranslation('header')
  const [showLanguageChooser, setShowLanguageChooser] = useState<boolean>(false)
  const tenant = useTenant()
  const tenantProperties = useEntityQuery<TenantProperties>('TenantProperties')?.[0]
  const productLabel: string = tenantProperties?.properties.productLabel ?? 'essentials'
  const latodaGray = '#4D4D4D'
  const lang = i18n.language.split('-')[0]
  const [setLocaleMutation] = useMutation(SET_LOCALE_MUTATION)
  const forceReLogin = useForceReLogin()
  const location = useLocation()
  const navigate = useNavigate()
  const { tenantId, setTenantId, user, locale, setLocale: _setLocale } = useGlobalState()
  const tenants = useEntityQuery<Tenant>('Tenant')

  const userTenants = useMemo(() => {
    return tenants == null
      ? null
      : [...tenants]
          .filter(t => t.curUserIsMember)
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
  }, [tenants])

  const adminVisibleTenants = useMemo(() => {
    return tenants == null
      ? null
      : [...tenants]
          .filter(t => !t.curUserIsMember)
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
  }, [tenants])
  const [showAdminVisibleTenants, setShowAdminVisibleTenants] = useState(false)

  useEffect(() => {
    if (locale != null) {
      void i18n.changeLanguage(locale)
    }
  }, [locale])

  const setLocale = (locale: string): void => {
    _setLocale(locale)
    if (user != null) {
      void setLocaleMutation({ variables: { locale } })
        .catch(e => {
          console.error('Error calling setLocaleMutation', e)
        })
    }
  }

  const [isTenantDropdownVisible, setTenantDropdownVisible] = useState(false)
  const [setLastTidMutation] = useMutation(SET_LAST_TID_MUTATION)

  useEffect(() => {
    if (user != null && tenantId != null) {
      setLastTidMutation({ variables: { tid: tenantId } })
        .catch(_ => {
          // We get in here with "ApolloError: setLastTid field requires authentication" e.g. when the user token
          // was removed on the server side (e.g. in development due to db:drop)
          forceReLogin()
        })
    }
  }, [user, tenantId])

  // deep links come with a ?tid=... query param which we need to set the tenant
  useEffect(() => {
    const url = new URL(window.location.href)
    const queryParams = url.searchParams
    const tid = queryParams.get('tid')

    if (tid != null) {
      queryParams.delete('tid')
      const queryParamsStr = queryParams.toString()
      const newUrl = `${location.pathname}${queryParamsStr == null ? '' : '?' + queryParamsStr}`

      if (uuidRegex.test(tid)) {
        setTenantId(tid)
        window.location.href = url.toString()
      } else {
        navigate(newUrl, { replace: true })
      }
    }
  }, [location])

  const handleTenantSelect = (selectedTenantId: string): void => {
    setTenantDropdownVisible(false)
    if (selectedTenantId !== tenantId) {
      setTenantId(selectedTenantId)
      window.location.href = getMeta('urlBase') + '/'
    }
  }

  return (
        <>
            <header className={`col-span-2 flex items-center text-[${latodaGray}]`}>
              <div className="flex items-baseline">
                <Link className="flex px-2 py-1 focus:outline-1" to="/">
                  <TurbineIcon className="h-6 w-auto my-1 ml-1 align-bottom"/>
                  <LatodaLogo className="h-6 w-auto my-1 ml-2 mr-0"/>
                </Link>
                {productLabel !== ''
                  ? <span
                    className="text-[20px] text-latoda-orange align-text-bottom cursor-default">{productLabel}</span>
                  : null}
              </div>
              <div className="grow"/>
              {userTenants != null && tenant != null && tenantId != null &&
                <div>
                  <Tippy interactive={true}
                         visible={isTenantDropdownVisible}
                         // e.stopPropagation() in onClickOutside does not work as intended: https://github.com/atomiks/tippyjs/issues/1159
                         onClickOutside={(_, e) => { e.stopPropagation(); setTenantDropdownVisible(false) }}
                         content={<div tabIndex={-1}>
                           <SelectPopup
                             checkIconClass="mt-2"
                             options={userTenants.map(tenant => ({
                               key: tenant.id,
                               value: <div className="mt-1">{tenant.displayName}</div>
                             }))}
                             handleSelect={handleTenantSelect}
                             selectedOption={tenantId}/>
                           {adminVisibleTenants != null && adminVisibleTenants.length > 0 && (
                             showAdminVisibleTenants
                               ? <SelectPopup
                               checkIconClass="mt-2"
                               options={adminVisibleTenants.map(tenant => ({
                                 key: tenant.id,
                                 value: <div className="mt-1 flex bg-red-300 hover:bg-red-400 rounded-md pl-1 pr-2"><LockClosedIcon title={t('notMemberOfTenant')} className="mr-1 w-4"/>{tenant.displayName}</div>
                               }))}
                               handleSelect={handleTenantSelect}
                               selectedOption={tenantId}/>
                               : <div
                                 tabIndex={-1}
                                 onClick={e => { e.stopPropagation(); setShowAdminVisibleTenants(true) }}
                                 className="text-center flex justify-center items-center">
                                   <div tabIndex={-1} className="mt-1 flex bg-red-300 hover:underline hover:bg-red-400 p-1 rounded-md">
                                     <LockClosedIcon title={t('notMemberOfTenant')} className="w-4"/>
                                   </div>
                             </div>
                           )}
                         </div>}
                  >
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        if (tenants.length > 1) {
                          setTenantDropdownVisible(p => !p)
                          if (!isTenantDropdownVisible) { // do not do this when closing the dropdown - for some reason this switching off is visible otherwise
                            setShowAdminVisibleTenants(false)
                          }
                        }
                      }}
                      className={`${tenants.length > 1 ? 'cursor-pointer' : 'cursor-default'} ${tenant.curUserIsMember ? '' : 'bg-red-400'} border border-gray-300 rounded-md pl-1 pr-2`}
                    >{tenant.curUserIsMember
                      ? tenant.displayName
                      : <div className="flex"><LockClosedIcon title={t('notMemberOfTenant')} className="mr-1 w-4"/>{tenant.displayName}</div>}
                    </div>
                  </Tippy>
                </div>
              }
              {user != null &&
                    <a className="cursor-pointer px-2 py-1 rounded-lg hover:bg-gray-100 hover:underline" onClick={() => { forceReLogin() } }>{t('logout')}</a>}
              {!PROD &&
                <>
                  <DeFlag className={`h-6 w-auto border border-black cursor-pointer my-1 mx-1 ${lang === 'de' ? '' : 'opacity-30'}`} onClick={() => { setLocale('de') }} />
                  <GbFlag className={`h-6 w-auto border border-black cursor-pointer my-1 mx-1 ${lang === 'en' ? '' : 'opacity-30'}`} onClick={() => { setLocale('en') }} />
                  <JpFlag className={`h-6 w-auto border border-black cursor-pointer my-1 mx-1 ${lang === 'ja' ? '' : 'opacity-30'}`} onClick={() => { setLocale('ja') }} />
                </>}
              <div className="my-1 mr-1 cursor-pointer rounded-xl hover:bg-gray-300"
                   onClick={() => { setShowLanguageChooser(!showLanguageChooser) }}>
                <Tippy interactive={true}
                       visible={showLanguageChooser}
                       // e.stopPropagation() in onClickOutside does not work as intended: https://github.com/atomiks/tippyjs/issues/1159
                       onClickOutside={(_, e) => { e.stopPropagation(); setShowLanguageChooser(false) }}
                       content={<SelectPopup
                         options={Object.entries(supportedLanguages).map(([l2Ch, lName]) => ({ key: l2Ch, value: lName }))}
                         handleSelect={setLocale}
                         selectedOption={lang}/>}
                >
                  <GlobeAltIcon className="h-6 w-auto" title={t('changeLanguage')}/>
                </Tippy>
              </div>
            </header>
          <Outlet/>
        </>
  )
}

export default Header
