import * as React from 'react'
import { type MouseEvent } from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { type Row } from '@tanstack/react-table'
import { type useTranslation } from 'react-i18next'
import { type RecordWithId } from './table'
import { stopPropagation } from '../../utils/utils'

interface EditCellProps<RowData> {
  row: Row<RowData>
  isEditable: boolean
  isDeletable: boolean
  isEditing: boolean
  enableRowSelection: boolean
  isSelected: boolean
  t: ReturnType<typeof useTranslation>['t']
  abortEdit?: (row: RowData) => void
  startEdit?: (row: RowData) => void
  canDelete?: (row: RowData) => boolean
  deleteRow?: (row: RowData) => void
  saveEdit?: (row: RowData) => void
}

const EditCell = <RowData extends RecordWithId>({
  row, isEditable, isDeletable, isEditing, enableRowSelection, isSelected, t,
  abortEdit = undefined, startEdit = undefined, canDelete = undefined, deleteRow = undefined, saveEdit = undefined
}: EditCellProps<RowData>): React.ReactElement => {
  const changeEditState = (e: MouseEvent<HTMLButtonElement>): void => {
    const elName = e.currentTarget.name
    switch (elName) {
      case 'cancel':
        abortEdit?.(row.original)
        e.stopPropagation()
        break
      case 'edit':
        startEdit?.(row.original)
        e.stopPropagation()
        break
      case 'save':
        // noinspection JSIgnoredPromiseFromCall
        saveEdit?.(row.original)
        e.stopPropagation()
        break
      case 'delete':
        // noinspection JSIgnoredPromiseFromCall
        deleteRow?.(row.original)
        e.stopPropagation()
        break
      default:
        console.error('unknown operation ' + elName)
    }
  }

  const rowIsDeletable = isDeletable && (canDelete == null || canDelete(row.original))

  return (
        <div className="flex cursor-default" onClick={stopPropagation}>
            {row.id === 'new'
              ? (
                <>
                    <button title={t('save')} onClick={changeEditState} name="save">
                        <CheckCircleIcon className="w-6 h-6"/>
                    </button>
                    <button title={t('discard')} onClick={changeEditState} name="cancel">
                        <XCircleIcon className="w-6 h-6"/>
                    </button>
                </>
                )
              : isEditing
                ? (
                <>
                    <button title={t('save')} onClick={changeEditState} name="save">
                        <CheckCircleIcon className="w-6 h-6"/>
                    </button>
                    <button title={t('discardChanges')} onClick={changeEditState} name="cancel">
                        <XCircleIcon className="w-6 h-6"/>
                    </button>
                </>
                  )
                : (
                <>
                  {enableRowSelection &&
                    <input type="checkbox" readOnly={true} checked={isSelected} onClick={row.getToggleSelectedHandler()}
                           title={t('select')}
                           className="mt-1 mr-1 cursor-pointer"/>
                  }
                  {isEditable &&
                  <button title={t('edit')} onClick={changeEditState} name="edit">
                      <PencilSquareIcon className="w-6 h-6"/>
                  </button>
                  }
                  {rowIsDeletable
                    ? <button title={t('delete')} onClick={changeEditState} name="delete">
                        <TrashIcon className="w-6 h-6 text-red-500"/>
                      </button>
                    : <TrashIcon title={t('cannotDeleteItemWithDependents')} className="w-6 h-6 text-gray-400"/>
                  }
                </>
                  )}
        </div>
  )
}

export default EditCell
