import { gql, type SubscriptionResult, useSubscription } from '@apollo/client'
import { type Entity, type Maybe, type Update, UpdateTypeEnum } from '../graphql/graphql'
import { ENTITY_QUERY } from '../graphql/queriesAndMutations'
import type { RecordWithId } from '../components/table/table'
import { useEffect } from 'react'
import { observableStateMap } from './useGlobalState'

const ENTITY_SUBSCRIPTION = gql`
    subscription entity($entityType: String!, $id: ID) {
        entity(entityType: $entityType, id: $id) {
            fullSnapshot {
                __typename
                ... on Tenant {
                    ...TenantFields
                }
                ... on TenantProperties {
                    ...TenantPropertiesFields
                }
                ... on UserProperties {
                    ...UserPropertiesFields
                }
                ... on Permission {
                    ...PermissionFields
                }
                ... on Site {
                    ...SiteFields
                }
                ... on Turbine {
                    ...TurbineFields
                }
                ... on Inspection {
                    ...InspectionFields
                }
                ... on InspectionImage {
                    ...InspectionImageFields
                }
            }
            updates {
                type
                id
                entity {
                    __typename
                    ... on Tenant {
                        ...TenantFields
                    }
                    ... on TenantProperties {
                        ...TenantPropertiesFields
                    }
                    ... on UserProperties {
                        ...UserPropertiesFields
                    }
                    ... on Permission {
                        ...PermissionFields
                    }
                    ... on Site {
                        ...SiteFields
                    }
                    ... on Turbine {
                        ...TurbineFields
                    }
                    ... on Inspection {
                        ...InspectionFields
                    }
                    ... on InspectionImage {
                        ...InspectionImageFields
                    }
                }
            }
        }
    }
`

function entityArrayToIdMap (updates: Update[]): Map<string, Entity | null> {
  const idMap = new Map<string, Entity | null>()
  for (const { id, type, entity } of updates) {
    if (entity == null) {
      if (type !== UpdateTypeEnum.Remove) {
        console.error(`Type ${type} with null entity!`)
        continue
      }
    } else {
      if (type === UpdateTypeEnum.Remove) {
        console.error(`Type ${type} with non-null entity!`, entity)
        continue
      }
    }
    idMap.set(id, entity ?? null)
  }
  return idMap
}

interface EntitySubscriptionEntity {
  // __typename?: 'SitesSubscriptionPayload'
  /** Full update of all sites; supersedes all earlier snapshots and updates */
  fullSnapshot?: Maybe<Entity[]>
  /** Updates that need to be applied to the current state */
  updates?: Maybe<Update[]>
}

interface EntitySubscriptionPayload {
  entity: EntitySubscriptionEntity
}

export async function watchEntitySubscriptionCompletion (entityType: string, id?: string): Promise<void> {
  await new Promise<void>(resolve => {
    observableStateMap.subscribe(`EntitySubscriptionState:${entityType}:${id}`, newValue => {
      if (newValue === 'completed') {
        resolve()
      }
    })
  })
}

export function isEntitySubscriptionCompleted (entityType: string, id?: string): boolean | null {
  const currentState = observableStateMap.get(`EntitySubscriptionState:${entityType}:${id}`)
  return currentState != null && currentState === 'completed'
}

export function useEntitySubscription (entityType: string, id?: string): SubscriptionResult<EntitySubscriptionPayload> {
  useEffect(() => {
    observableStateMap.set(`EntitySubscriptionState:${entityType}:${id}`, 'loading')

    return () => { // on unmount
      observableStateMap.set(`EntitySubscriptionState:${entityType}:${id}`, 'unsubscribed')
    }
  }, [])
  // const x = useSubscription<EntitySubscriptionPayload>(ENTITY_SUBSCRIPTION)
  return useSubscription<EntitySubscriptionPayload>(ENTITY_SUBSCRIPTION, {
    variables: { entityType, id },
    onData: ({ client, data }) => {
      const payload = data.data?.entity
      if (payload?.fullSnapshot != null) {
        client.writeQuery({
          query: ENTITY_QUERY,
          variables: { entityType, id },
          data: { entity: payload.fullSnapshot },
          overwrite: true
        })
        observableStateMap.set(`EntitySubscriptionState:${entityType}:${id}`, 'completed')
      }
      if (payload?.updates != null) {
        const updateMap = entityArrayToIdMap(payload.updates)
        client.cache.updateQuery({
          query: ENTITY_QUERY,
          variables: { entityType, id }
        },
        (data) => {
          const updatedData = data.entity
            .map((curVal: RecordWithId) => {
              const updatedVal = updateMap.get(curVal.id)
              if (updatedVal !== undefined) {
                updateMap.delete(curVal.id)
              }
              return updatedVal === undefined
                ? curVal
                : updatedVal === null
                  ? null
                  : { ...curVal, ...updatedVal }
            })
            .filter((v: any) => v !== null)
          return { entity: [...updatedData, ...updateMap.values()] }
        }
        )
      }
    }
  })
}
