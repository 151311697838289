import { type AuthData } from '../hooks/useGlobalState'

const csrfTokenElement = document.querySelector('meta[name=csrf-token]')
const csrfToken = csrfTokenElement instanceof HTMLMetaElement ? csrfTokenElement.content : null

export function getAuthHeaders (authData: AuthData | null, otherHeaders: Record<string, string> | null = null): Record<string, string> {
  const headers: HeadersInit = {}
  if (authData != null) {
    headers['access-token'] = authData['access-token']
    headers.client = authData.client
    headers.uid = authData.uid
  }
  if (csrfToken != null) {
    headers['X-CSRF-Token'] = csrfToken
  }
  return { ...otherHeaders, ...headers }
}
