import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import countries, { type LocaleData } from 'i18n-iso-countries'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import ja from 'i18n-iso-countries/langs/ja.json'

function registerLocale (localeData: LocaleData): void {
  countries.registerLocale(localeData)
}

registerLocale(en as LocaleData)
registerLocale(de as LocaleData)
registerLocale(ja as LocaleData)

const resources = {
  de: {
    common: {
      refreshAction_reload_HTML: '<b>Bleiben Sie dran für die neuesten Updates!</b><br/>' +
          '<br/>' +
          'Wir aktualisieren die Seite mit den neuesten Funktionen.<br/>' +
          '<br/>' +
          'Bitte warten Sie einen Moment!',
      refreshAction_reauth_HTML: '<b>Bleiben Sie dran für die neuesten Updates!</b><br/>' +
          '<br/>' +
          'Wir aktualisieren die Seite mit den neuesten Funktionen.<br/>' +
          '<br/>' +
          'Bitte warten Sie einen Moment!<br/>' +
          '<br/>' +
          'Bitte beachten Sie: Sie müssen sich <b>erneut einloggen</b>, um die Anwendung weiter zu nutzen.',
      updatedAt: 'Aktualisiert',
      itemWithIdNotFound: 'Eintrag {{id}} konnte nicht gefunden werden',
      changeLanguage: 'Sprache wechseln',
      notAvailable: 'N/A',
      notFound: 'Eintrag konnte nicht gefunden werden',
      notFoundSentence: 'Der gesuchte Eintrag konnte nicht gefunden werden.',
      download: 'Herunterladen',
      loading: 'Laden...',
      error: 'Fehler',
      saveFailed: 'Speichern fehlgeschlagen',
      id: 'Id',
      select: 'Auswählen',
      selectAll: 'Alle auswählen',
      data_entry: 'Dateneingabe',
      in_progress: 'In Bearbeitung',
      complete: 'Abgeschlossen',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      noItemsYet: 'Noch keine Einträge',
      valuesOfColMustBeUnique: 'Werte des Feldes "{{column}}" müssen unterschiedlich sein',
      changeFilterToShowItems: 'Ändern Sie den Filter, um Einträge anzuzeigen',
      createNew: 'Neu',
      createNewItem: 'Neuen Eintrag erstellen',
      requiredFields: 'Erforderliche Felder',
      considerAddingRecommendedFields: 'Erwägen Sie, empfohlene Felder hinzuzufügen',
      invalidFields: 'Ungültige Felder',
      fieldIsRequired: 'Feld {{field}} ist erforderlich',
      fieldIsRecommended: 'Feld {{field}} wird für die KI-Analyse empfohlen',
      itemCreated: 'Eintrag {{id}} erstellt',
      itemUpdated: 'Eintrag {{id}} aktualisiert',
      errorCreatingItem: 'Fehler beim Erstellen des Eintrags: {{reason}}',
      errorUpdatingItem: 'Fehler beim Aktualisieren des Eintrags {{id}}: {{reason}}',
      cannotDeleteItemWithDependents: 'Element kann nicht gelöscht werden: Es hat abhängige Objekte, die zuerst entfernt werden müssen',
      delete: 'Löschen',
      deleteItemQ: 'Eintrag löschen?',
      deleteItemsQ: '{{count}} Einträge löschen?',
      sureToDeleteItem_HTML: 'Dies wird Eintrag <b>{{id}}</b> dauerhaft löschen.<br><b>Dies kann nicht rückgängig gemacht werden.</b>',
      itemDeleted: 'Eintrag {{id}} gelöscht',
      errorDeletingItem: 'Fehler beim Löschen des Eintrags {{id}}: {{reason}}',
      errorDeletingItems_one: 'Fehler beim Löschen eines Eintrags',
      errorDeletingItems_other: 'Fehler beim Löschen von {{count}} Einträgen',
      deleteSelectedItems_one: 'Ausgewählten Eintrag löschen',
      deleteSelectedItems_other: '{{count}} ausgewählte Einträge löschen',
      downloadSelectedItems_one: 'Ausgewählten Eintrag herunterladen',
      downloadSelectedItems_other: '{{count}} ausgewählte Einträge herunterladen',
      sureToDeleteItems_HTML: 'Dies wird die <b>{{count}} ausgewählten Einträge</b> dauerhaft löschen.<br><b>Dies kann nicht rückgängig gemacht werden.</b>',
      itemsDeleted_one: 'Eintrag wurden gelöscht',
      itemsDeleted_other: '{{count}} Einträge wurden gelöscht',
      uncompletedActionsBlockerTitle: 'Unabgeschlossene Aktionen',
      uncompletedActionsBlockerText_HTML: 'Sie haben ungespeicherte Änderungen oder laufende Dateiübertragungen. Möchten Sie wirklich beenden und jeglichen Fortschritt verlieren oder bleiben und die Aktionen abschließen?',
      uncompletedActionsBlockerStayBtn: 'Bleiben und abschließen',
      uncompletedActionsBlockerDiscardBtn: 'Beenden und Fortschritt verlieren',
      discardChanges: 'Änderungen verwerfen',
      discardChangesQ: 'Änderungen verwerfen?',
      discardChangesText_HTML: 'Möchten Sie die Bearbeitung fortsetzen oder die Änderungen verwerfen?',
      discardChangesBtn: 'Änderungen verwerfen',
      continueEditingBtn: 'Bearbeitung fortsetzen',
      discard: 'Verwerfen',
      edit: 'Bearbeiten',
      save: 'Speichern',
      login: 'Anmelden',
      emailAddress: 'E-Mail Adresse',
      password: 'Passwort',
      forgotYourPassword: 'Passwort vergessen?',
      resetPassword: 'Passwort zurücksetzen',
      reset: 'Zurücksetzen',
      invalidLoginCredentials: 'Ungültige Anmeldeinformationen. Bitte versuchen Sie es erneut.',
      logout: 'Abmelden',
      required: 'Erforderlich',
      recommended: 'Empfohlen',
      optional: 'Optional',
      requiredField: 'Pflichtfeld',
      recommendedField: 'Empfohlenes Feld',
      optionalField: 'Optionales Feld',
      selectOption: '<Wählen>',
      noSelection: '<Keine Auswahl>',
      online: 'online',
      offline: 'offline',
      address: 'Adresse',
      city: 'Stadt',
      state: 'Bundesland',
      postalCode: 'PLZ',
      region: 'Region',
      country: 'Land',
      timezone: 'Zeitzone',
      latitude: 'Breitengrad',
      longitude: 'Längengrad',
      viewOnMap: 'Auf der Karte anzeigen',
      searchItems: 'Suche in {{itemCount}} Artikeln...',
      insights: 'Insights',
      site: 'Standort',
      sites: 'Standorte',
      turbine: 'Turbine',
      turbines: 'Turbinen',
      blade: 'Blatt',
      blades: 'Blätter',
      bladeLength: 'Blattlänge(m)',
      bladeRootDiameter: '⌀ Blattwurzel(m)',
      auxiliaryComponent: 'Hilfskomponente',
      installDate: 'Installationsdatum',
      removedDate: 'Entfernungsdatum',
      inspection: 'Inspektion',
      inspections: 'Inspektionen',
      inspectionDetails: 'Inspektionsdetails',
      inspectionDetailsStatus: ' - Status: {{status}}',
      anomalies: 'Anomalien',
      settings: 'Einstellungen',
      users: 'Benutzer',
      name: 'Name',
      siteName: 'Standort',
      capacity: 'Nennleistung(MW)',
      status: 'Status',
      maker: 'Hersteller',
      model: 'Modell',
      bladeModel: 'Blattmodell',
      serialNo: 'Seriennummer',
      hubHeight: 'Nabenhöhe(m)',
      inspectionDate: 'Inspektionsdatum',
      codDate: 'Inbetriebnahme',
      codDateTitle: 'Datum der kommerziellen Inbetriebnahme',
      eowDate: 'EOW',
      eowDateTitle: 'Gewährleistungsende',
      turbineName: 'Name',
      plannedDate: 'Geplantes Datum',
      actualDate: 'Inspektionsdatum',
      inspectionMethod: 'Inspektionsmethode',
      inspector: 'Inspektor',
      inspectionFirm: 'Inspektionsfirma',
      component: 'Komponente',
      cameraModel: 'Kameramodell',
      maxSeverity: 'Maximale Schwere',
      severity: 'Schweregrad',
      side: 'Seite',
      dragNDrop_one: 'Zum Hochladen Datei hierher ziehen oder klicken, um Datei auszuwählen',
      dragNDrop_other: 'Zum Hochladen Dateien hierher ziehen oder klicken, um Dateien auszuwählen',
      errorUploadingFile: 'Fehler beim Hochladen der Datei {{filename}}: {{msg}}',
      errorUploadingFiles: 'Fehler beim Hochladen der Dateien: {{msg}}',
      errorRequestingFile: 'Fehler beim Anfordern der Datei {{filename}}: {{msg}}',
      warningUploadingFile: 'Warnung beim Hochladen der Datei {{filename}}: {{msg}}',
      successUploadingFile: 'Datei {{filename}} erfolgreich hochgeladen',
      successUploadingFiles: '{{count}} Dateien erfolgreich hochgeladen',
      downloadReport: 'Bericht herunterladen'
    },
    header: {
      notMemberOfTenant: 'Sie sind kein Mitglied dieses Tenants, können aber als Administrator darauf zugreifen'
    },
    dashboard: {
      dashboardFor: '{{tenantName}}'
    },
    login: {
      userLogin: 'Benutzeranmeldung',
      userWasNotFound: 'Bitte überprüfen Sie die E-Mail-Adresse und versuchen Sie es erneut',
      passwordUpdateInstructionsHaveBeenEmailed: 'Anweisungen zum Aktualisieren des Passworts wurden an {{emailAddress}} gesendet'
    },
    updatePassword: {
      updatePassword: 'Passwort aktualisieren',
      newPassword: 'Neues Passwort',
      confirmNewPassword: 'Neues Passwort bestätigen',
      updatePasswordBtn: 'Aktualisieren',
      passwordUpdatedSuccessfully: 'Passwort erfolgreich aktualisiert'
    },
    navigationBar: {},
    siteTable: {
      name: 'Standort',
      sureToDeleteItem_HTML: 'Dies wird Standort "<b>{{name}}</b>" dauerhaft löschen.<br><b>Dies kann nicht rückgängig gemacht werden.</b>'
    },
    turbineTable: {
      name: 'Name',
      createSiteFirst: 'Bitte erstellen Sie zuerst einen Standort',
      sureToDeleteItem_HTML: 'Dies wird Turbine "<b>{{siteName}}</b> / <b>{{name}}</b>" dauerhaft löschen.<br><b>Dies kann nicht rückgängig gemacht werden.</b>',
      siteId: 'Standort',
      statusOnline: 'Status'
    },
    bladeTable: {
      statusActive: 'Status'
    },
    inspectionTable: {
      siteId: 'Standort',
      createNewItem: 'Neue Inspektion erstellen',
      createTurbineFirst: 'Bitte erstellen Sie zuerst eine Turbine',
      sureToDeleteItem_HTML: 'Dies wird Inspektion "<b>{{id}}</b>" für "<b>{{siteName}}</b> / <b>{{turbineName}}</b>" dauerhaft löschen.<br><b>Dies kann nicht rückgängig gemacht werden.</b>',
      autonomous_drone: 'Autonome Drohne',
      manual_drone: 'Manuelle Drohne',
      ground_based: 'Bodenbasierte Kamera',
      cannotDeleteItemWithDependents: 'Nur Inspektionen im Status "Dateneingabe" können gelöscht werden',
      report: 'Bericht',
      reportNotYetAvailable: 'Bericht noch nicht verfügbar',
      turbineId: 'Turbine'
    },
    inspectionDetails: {
      todoUploadMetadata: 'Metadaten hochladen (xslx, ods, csv)',
      todoUploadMetadataHelp: 'Ziehen Sie eine xslx-, ods- oder csv-Datei in den orangefarbenen Upload-Bereich',
      todoUploadImages: 'Bilder hochladen ({{types}})',
      todoUploadImagesWithCount: 'Bilder hochladen ({{types}}) ({{uploadedImageCount}} Bild(er) hochgeladen)',
      todoUploadImagesWithCounts: 'Bilder hochladen ({{types}}) ({{uploadedImageCount}}/{{allImageCount}})',
      todoUploadImagesHelp: 'Ziehen Sie Bilder ({{types}}) in den orangefarbenen Upload-Bereich',
      todoUploadReport: 'Bericht hochladen (pdf)',
      todoOverwriteReport: 'Bericht überschreiben (pdf)',
      todoUploadReportHelp: 'Ziehen Sei eine pdf-Datei in den orangefarbenen Upload-Bereich',
      sendNotificationEmails: 'Nach dem Hochladen eines Berichtes Benachrichtigungs-E-Mails an die Tenant-Mitglieder senden',
      createdAt: 'Upload-Datum',
      downloadImage: 'Bild herunterladen',
      downloadSelectedItems_one: 'Ausgewähltes Bild herunterladen...',
      downloadSelectedItems_other: '{{count}} ausgewählte Bilder herunterladen...',
      downloadSelectedImages_one: 'Bild herunterladen',
      downloadSelectedImages_other: '{{count}} Bilder herunterladen',
      downloadMultipleImages_HTML_one: 'Möchten Sie das ausgewählte Bild ({{totalSize}}) herunterladen?',
      downloadMultipleImages_HTML_other: 'Möchten Sie die {{count}} ausgewählten Bilder ({{totalSize}} gesamt) herunterladen?',
      requestInspectionAnalysis: 'Inspektionsanalyse anfordern...',
      requestInspectionAnalysisSuccess: 'Die Inspektionsanalyse wurde erfolgreich angefordert, und eine E-Mail-Benachrichtigung wurde gesendet.<br>Sie erhalten eine weitere E-Mail-Benachrichtigung, sobald der Inspektionsbericht fertiggestellt ist und zum Download bereitsteht.',
      ERR_FILE_ALREADY_UPLOADED: 'Datei bereits hochgeladen',
      ERR_DUPLICATE_METADATA: 'Nur eine Metadatendatei kann verarbeitet werden',
      ERR_METADATA_CONTENT: 'Unerwarteter Dateiinhalt',
      ERR_UNSUPPORTED_FILETYPE: 'Dateityp {{contentType}} wird nicht unterstützt',
      ERR_FILE_CONTENT_DOES_NOT_MATCH_EXTENSION: 'Der Inhalt der Datei entspricht nicht der Dateierweiterung. Erwarteter Inhalt: {{expected}}. Tatsächlicher Inhalt: {{actual}}',
      METADATA_OVERWRITTEN: 'Hochladen hat vorhandene Metadaten überschrieben',
      filename: 'Dateiname',
      contentTypeTitle: 'Dateiinhalts-Typ',
      byteSize: 'Größe',
      byteSizeTitle: 'Dateigröße',
      width: 'Breite',
      height: 'Höhe',
      imageNotYetUploaded: 'Bild noch nicht hochgeladen',
      metadataUploadCanceled: 'Metadaten-Upload abgebrochen',
      fieldMappingMustBeUnique: 'Spalten dürfen nicht mehrfach zugeordnet werden',
      sureToDeleteItem_HTML: 'Dies wird Eintrag "<b>{{id}}</b>" (Dateiname: <b>{{filename}}</b>) dauerhaft löschen.<br><b>Dies kann nicht rückgängig gemacht werden.</b>'
    },
    inspectionDetailsStartAnalysisDialog: {
      notSpecified: 'Nicht angegeben',
      title: 'Inspektionsanalyse anfordern',
      clickHereToEnterMissingInformation: 'Hier klicken, um die fehlende Information einzugeben',
      bladeLengthNecessary: 'Die <1>Blattlänge</1> ist erforderlich, um den Schadensbereich in cm² zu ermitteln.',
      bladeRootDiameterUseful: 'Mit dem <1>Blattwurzeldurchmesser</1> wird die Genauigkeit des Schadensbereichs in cm² im Bericht erhöht.',
      h5UploadLabel: 'Hochgeladene h5-Dateien',
      imageUploadLabel: 'Hochgeladene Bilder',
      imageUploadText: '{{imageUploadCount}} von {{imageCount}}',
      imageUploadNecessary: 'Bitte laden Sie fehlende Bilder hoch oder entfernen Sie die Zeilen mit nicht hochgeladenen Bildern',
      requiredImageMetadataLabel: 'Bilder mit erforderlichen Metadaten',
      requiredImageMetadataText: '{{imagesWithRequiredMetadataCount}} von {{imageCount}}',
      requiredImageMetadataNecessary: 'Die Blattinformationen sind erforderlich, um den Bericht nach Rotorblatt zu strukturieren.',
      recommendedImageMetadataLabel: 'Bilder mit empfohlenen Metadaten',
      recommendedImageMetadataText: '{{imagesWithRecommendedMetadataCount}} von {{imageCount}}',
      recommendedImageMetadataUseful: 'Mit den empfohlnen Bildmetadaten wird der Bericht nach Blatt und Blattseite strukturiert und die Position der Schäden visualisiert.',
      submitAnalysisBtn: 'Zur Analyse einreichen',
      submitAnalysisAsIsBtn: 'Unverändert einreichen',
      resolveIssues: 'Bitte lösen Sie die genannten Probleme',
      cancelBtn: 'Abbrechen',
      enterMissingDataBtn: 'Fehlende Daten eingeben',
      enterRecommendedDataBtn: 'Empfohlene Daten eingeben'
    },
    inspectionDetailsMetadata: {
      imageFilename: 'Bilddateiname',
      adjustAndConfirmColumnMapping: 'Metadaten-Spaltenzuordnung anpassen und bestätigen ({{metadataFilename}})',
      blade: 'Blatt',
      bladeSerialNo: 'Blattseriennr.',
      bladeSide: 'Blattseite',
      minRootDistance: 'Min Nabenabstand(m)',
      minRootDistanceTitle: 'Der Abstand der Nabe zu der Kante des Bildes, wo der Abstand am geringsten ist',
      centerRootDistance: 'Mittlerer Nabenabstand(m)',
      centerRootDistanceTitle: 'Der Abstand der Nabe zum Bildmittelpunkt',
      maxRootDistance: 'Max Nabenabstand(m)',
      maxRootDistanceTitle: 'Der Abstand der Nabe zu der Kante des Bildes, wo der Abstand am größten ist',
      cameraOrientation: 'Kameraausrichtung',
      cameraType: 'Kameratyp',
      distanceToBlade: 'Abstand zum Blatt(m)',
      distanceToBladeTitle: 'Abstand von der Kamera zum Blatt',
      angle: 'Winkel(°)'
    }
  },
  en: {
    common: {
      refreshAction_reload_HTML: '<b>Stay Tuned for the Latest Updates!</b><br/>' +
          '<br/>' +
          'We\'re updating the page with the latest features.<br/>' +
          '<br/>' +
          'Hang tight – this will only take a moment!',
      refreshAction_reauth_HTML: '<b>Stay Tuned for the Latest Updates!</b><br/>' +
          '<br/>' +
          'We\'re updating the page with the latest features.<br/>' +
          '<br/>' +
          'Hang tight – this will only take a moment!<br/>' +
          '<br/>' +
          'Please note: You will need to <b>re-login</b> to continue using the application.',
      updatedAt: 'Updated',
      itemWithIdNotFound: 'Item {{id}} not found',
      changeLanguage: 'Change language',
      notAvailable: 'N/A',
      notFound: 'Item not found',
      notFoundSentence: 'The item you are looking for could not be found.',
      download: 'Download',
      loading: 'Loading...',
      error: 'Error',
      saveFailed: 'Save failed',
      id: 'Id',
      select: 'Select',
      selectAll: 'Select all',
      data_entry: 'Data entry',
      in_progress: 'In progress',
      complete: 'Complete',
      cancel: 'Cancel',
      confirm: 'Confirm',
      noItemsYet: 'No items yet',
      valuesOfColMustBeUnique: 'Values of the field "{{column}}" must be unique',
      changeFilterToShowItems: 'Change filter to show items',
      createNew: 'New',
      createNewItem: 'Create new item',
      requiredFields: 'Required Fields',
      considerAddingRecommendedFields: 'Consider adding recommended fields',
      invalidFields: 'Invalid Fields',
      fieldIsRequired: 'Field {{field}} is required',
      fieldIsRecommended: 'Field {{field}} is recommended for AI analysis',
      itemCreated: 'Item {{id}} created',
      itemUpdated: 'Item {{id}} updated',
      errorCreatingItem: 'Error creating item: {{reason}}',
      errorUpdatingItem: 'Error updating item {{id}}: {{reason}}',
      cannotDeleteItemWithDependents: 'Cannot delete item: it has dependent objects that must be removed first',
      delete: 'Delete',
      deleteItemQ: 'Delete item?',
      deleteItemsQ: 'Delete {{count}} items?',
      sureToDeleteItem_HTML: 'This will permanently delete item "<b>{{id}}</b>".<br><b>This cannot be undone.</b>',
      itemDeleted: 'Item {{id}} deleted',
      errorDeletingItem: 'Error deleting item {{id}}: {{reason}}',
      errorDeletingItems_one: 'Error deleting one item',
      errorDeletingItems_other: 'Error deleting {{count}} items',
      deleteSelectedItems_one: 'Delete selected item',
      deleteSelectedItems_other: 'Delete {{count}} selected items',
      downloadSelectedItems_one: 'Download selected item',
      downloadSelectedItems_other: 'Download {{count}} selected items',
      sureToDeleteItems_HTML: 'This will permanently delete the <b>{{count}} selected items</b>.<br><b>This cannot be undone.</b>',
      itemsDeleted_one: 'Item was deleted',
      itemsDeleted_other: '{{count}} items were deleted',
      uncompletedActionsBlockerTitle: 'Uncompleted Actions',
      uncompletedActionsBlockerText_HTML: 'You have unsaved changes or ongoing file transfers. Do you want to exit and lose any progress, or stay to complete them?',
      uncompletedActionsBlockerStayBtn: 'Stay and complete',
      uncompletedActionsBlockerDiscardBtn: 'Exit and lose progress',
      discardChanges: 'Discard changes',
      discardChangesQ: 'Discard changes?',
      discardChangesText_HTML: 'Do you want to continue editing or discard changes?',
      discardChangesBtn: 'Discard changes',
      continueEditingBtn: 'Continue editing',
      discard: 'Discard',
      save: 'Save',
      edit: 'Edit',
      login: 'Login',
      emailAddress: 'Email Address',
      password: 'Password',
      forgotYourPassword: 'Forgot your password?',
      resetPassword: 'Reset password',
      reset: 'Reset',
      invalidLoginCredentials: 'Invalid login credentials. Please try again.',
      logout: 'Logout',
      required: 'Required',
      optional: 'Optional',
      recommended: 'Recommended',
      requiredField: 'Required field',
      optionalField: 'Optional field',
      recommendedField: 'Recommended field',
      selectOption: '<Select>',
      noSelection: '<No Selection>',
      online: 'online',
      offline: 'offline',
      address: 'Address',
      city: 'City',
      state: 'State',
      postalCode: 'Postal Code',
      region: 'Region',
      country: 'Country',
      timezone: 'Time Zone',
      latitude: 'Latitude',
      longitude: 'Longitude',
      viewOnMap: 'View on map',
      searchItems: 'Search in {{itemCount}} items...',
      insights: 'Insights',
      site: 'Site',
      sites: 'Sites',
      turbine: 'Turbine',
      turbines: 'Turbines',
      blade: 'Blade',
      blades: 'Blades',
      bladeLength: 'Blade Length(m)',
      bladeRootDiameter: '⌀ Blade Root(m)',
      auxiliaryComponent: 'Auxiliary Component',
      installDate: 'Install Date',
      removedDate: 'Removed Date',
      inspection: 'Inspection',
      inspections: 'Inspections',
      inspectionDetails: 'Inspection Details',
      inspectionDetailsStatus: ' - Status: {{status}}',
      anomalies: 'Anomalies',
      settings: 'Settings',
      users: 'Users',
      name: 'Name',
      siteName: 'Site',
      capacity: 'Capacity(MW)',
      status: 'Status',
      maker: 'Maker',
      model: 'Model',
      bladeModel: 'Blade Model',
      serialNo: 'Serial No',
      hubHeight: 'Hub Height(m)',
      inspectionDate: 'Inspection Date',
      codDate: 'COD',
      codDateTitle: 'Commercial operation date',
      eowDate: 'EOW',
      eowDateTitle: 'End of warranty date',
      turbineName: 'Name',
      plannedDate: 'Planned Date',
      actualDate: 'Inspection Date',
      inspectionMethod: 'Inspection Method',
      inspector: 'Inspector',
      inspectionFirm: 'Inspection Firm',
      component: 'Component',
      cameraModel: 'Camera Model',
      maxSeverity: 'Max Severity',
      severity: 'Severity',
      side: 'Side',
      dragNDrop_one: "To upload, drag 'n' drop file here, or click to select file",
      dragNDrop_other: "To upload, drag 'n' drop files here, or click to select files",
      errorUploadingFile: 'Error uploading file {{filename}}: {{msg}}',
      errorUploadingFiles: 'Error uploading files: {{msg}}',
      errorRequestingFile: 'Error requesting file {{filename}}: {{msg}}',
      warningUploadingFile: 'Warning uploading file {{filename}}: {{msg}}',
      successUploadingFile: 'Successfully uploaded file {{filename}}',
      successUploadingFiles: 'Successfully uploaded {{count}} files',
      downloadReport: 'Download report'
    },
    header: {
      notMemberOfTenant: 'You are not a member of this tenant, but can access it as an administrator'
    },
    dashboard: {
      dashboardFor: '{{tenantName}}'
    },
    login: {
      userLogin: 'User Login',
      userWasNotFound: 'Please check the email address and try again',
      passwordUpdateInstructionsHaveBeenEmailed: 'Instructions on how to update the password have been sent to {{emailAddress}}'
    },
    updatePassword: {
      updatePassword: 'Update password',
      newPassword: 'New password',
      confirmNewPassword: 'Confirm new password',
      updatePasswordBtn: 'Update',
      passwordUpdatedSuccessfully: 'Password updated successfully'
    },
    navigationBar: {},
    siteTable: {
      name: 'Site',
      sureToDeleteItem_HTML: 'This will permanently delete site "<b>{{name}}</b>".<br><b>This cannot be undone.</b>'
    },
    turbineTable: {
      name: 'Name',
      createSiteFirst: 'Please create a site first',
      sureToDeleteItem_HTML: 'This will permanently delete turbine "<b>{{siteName}}</b> / <b>{{name}}</b>".<br><b>This cannot be undone.</b>',
      siteId: 'Site',
      statusOnline: 'Status'
    },
    bladeTable: {
      statusActive: 'Status'
    },
    inspectionTable: {
      siteId: 'Site',
      createNewItem: 'Create new inspection',
      createTurbineFirst: 'Please create a turbine first',
      sureToDeleteItem_HTML: 'This will permanently delete inspection "<b>{{id}}</b>" for "<b>{{siteName}}</b> / <b>{{turbineName}}</b>".<br><b>This cannot be undone.</b>',
      autonomous_drone: 'Autonomous Drone',
      manual_drone: 'Manual Drone',
      ground_based: 'Ground-Based Camera',
      cannotDeleteItemWithDependents: 'Only inspections in state "Data entry" can be deleted',
      report: 'Report',
      reportNotYetAvailable: 'Report not yet available',
      turbineId: 'Turbine'
    },
    inspectionDetails: {
      todoUploadMetadata: 'Upload metadata (xslx, ods, csv)',
      todoUploadMetadataHelp: 'Drag an xslx, ods, or csv file to the orange upload zone',
      todoUploadImages: 'Upload images ({{types}})',
      todoUploadImagesWithCount: 'Upload images ({{types}}) ({{uploadedImageCount}} image(s) uploaded)',
      todoUploadImagesWithCounts: 'Upload images ({{types}}) ({{uploadedImageCount}}/{{allImageCount}})',
      todoUploadImagesHelp: 'Drag images ({{types}}) to the orange upload zone',
      todoUploadReport: 'Upload report (pdf)',
      todoOverwriteReport: 'Overwrite report (pdf)',
      todoUploadReportHelp: 'Drag pdf file to the orange upload zone',
      sendNotificationEmails: 'Send notification emails to tenant members after report upload',
      createdAt: 'Upload Date',
      downloadImage: 'Download image',
      downloadSelectedItems_one: 'Download selected image...',
      downloadSelectedItems_other: 'Download {{count}} selected images...',
      downloadSelectedImages_one: 'Download image',
      downloadSelectedImages_other: 'Download {{count}} images',
      downloadMultipleImages_HTML_one: 'Do you want to download the selected image ({{totalSize}})?',
      downloadMultipleImages_HTML_other: 'Do you want to download the {{count}} selected images ({{totalSize}} in total)?',
      requestInspectionAnalysis: 'Request inspection analysis...',
      requestInspectionAnalysisSuccess: 'The inspection analysis has been requested successfully and an email notification has been sent.<br>You will receive another email notification as soon as the inspection report is complete and available for download.',
      ERR_FILE_ALREADY_UPLOADED: 'File already uploaded',
      ERR_DUPLICATE_METADATA: 'Can only process one metadata file',
      ERR_METADATA_CONTENT: 'Unexpected file content',
      ERR_UNSUPPORTED_FILETYPE: 'Content type {{contentType}} not supported',
      ERR_FILE_CONTENT_DOES_NOT_MATCH_EXTENSION: 'File content does not match file extension. Expected content: {{expected}}. Actual content: {{actual}}',
      METADATA_OVERWRITTEN: 'Upload overwrote existing metadata',
      filename: 'Filename',
      contentType: 'Type',
      contentTypeTitle: 'File content type',
      byteSize: 'Size',
      byteSizeTitle: 'File size',
      width: 'Width',
      height: 'Height',
      imageNotYetUploaded: 'Image not yet uploaded',
      metadataUploadCanceled: 'Metadata upload canceled',
      fieldMappingMustBeUnique: 'Columns must not be assigned multiple times',
      sureToDeleteItem_HTML: 'This will permanently delete item "<b>{{id}}</b>" (File name: <b>{{filename}}</b>).<br><b>This cannot be undone.</b>'
    },
    inspectionDetailsStartAnalysisDialog: {
      notSpecified: 'Not specified',
      title: 'Request inspection analysis',
      clickHereToEnterMissingInformation: 'Click here to enter the missing information',
      bladeLengthNecessary: 'The <1>blade length</1> is necessary to gauge the damage area in cm².',
      bladeRootDiameterUseful: 'With the <1>blade root diameter</1>, the reported accuracy of the damage area in cm² will increase.',
      h5UploadLabel: 'h5 files uploaded',
      imageUploadLabel: 'Images uploaded',
      imageUploadText: '{{imageUploadCount}} of {{imageCount}}',
      imageUploadNecessary: 'Please upload any missing images or remove rows for images not uploaded',
      requiredImageMetadataLabel: 'Images with required metadata',
      requiredImageMetadataText: '{{imagesWithRequiredMetadataCount}} of {{imageCount}}',
      requiredImageMetadataNecessary: 'The blade information is necessary to structure the report by blade.',
      recommendedImageMetadataLabel: 'Images with recommended metadata',
      recommendedImageMetadataText: '{{imagesWithRecommendedMetadataCount}} of {{imageCount}}',
      recommendedImageMetadataUseful: 'With the recommended image metadata, the report will be structured by blade and blade side, and the location of the damages will be visualized.',
      submitAnalysisBtn: 'Submit for analysis',
      submitAnalysisAsIsBtn: 'Submit for analysis as-is',
      resolveIssues: 'Please resolve the mentioned issues',
      cancelBtn: 'Cancel',
      enterMissingDataBtn: 'Enter missing data',
      enterRecommendedDataBtn: 'Enter recommended data'
    },
    inspectionDetailsMetadata: {
      imageFilename: 'Image Filename',
      adjustAndConfirmColumnMapping: 'Adjust and confirm metadata column mapping ({{metadataFilename}})',
      blade: 'Blade',
      bladeSerialNo: 'Blade Serial No',
      bladeSide: 'Blade Side',
      minRootDistance: 'Min Root Distance(m)',
      minRootDistanceTitle: 'The distance from the blade root to the edge of the image where the distance is smallest.',
      centerRootDistance: 'Center Root Distance(m)',
      centerRootDistanceTitle: 'The distance of the center point of the image from the blade root. Limited by the blade length.',
      maxRootDistance: 'Max Root Distance(m)',
      maxRootDistanceTitle: 'The distance from the blade root to the edge of the image where the distance is largest.',
      cameraOrientation: 'Camera Orientation',
      cameraType: 'Camera Type',
      distanceToBlade: 'Distance To Blade(m)',
      distanceToBladeTitle: 'Camera-to-blade distance',
      angle: 'Angle(°)'
    }
  },
  ja: {
    common: {
      refreshAction_reload_HTML: '<b>最新のアップデートをお待ちください！</b><br/>' +
          '<br/>' +
          '最新の機能でページを更新しています。<br/>' +
          '<br/>' +
          '少々お待ちください！',
      refreshAction_reauth_HTML: '<b>最新のアップデートをお待ちください！</b><br/>' +
          '<br/>' +
          '最新の機能でページを更新しています。<br/>' +
          '<br/>' +
          '少々お待ちください！<br/>' +
          '<br/>' +
          'ご注意ください：アプリケーションを引き続き使用するには、<b>再ログイン</b>が必要です。',
      updatedAt: '更新日',
      itemWithIdNotFound: '項目 {{id}} が見つかりません',
      changeLanguage: '言語の切り替え',
      notAvailable: 'N/A',
      notFound: '項目が見つかりません',
      notFoundSentence: 'お探しの項目は見つかりませんでした。',
      download: 'ダウンロード',
      loading: '読み込み中...',
      error: 'エラー',
      saveFailed: '保存に失敗しました',
      id: 'Id',
      select: '選択',
      selectAll: 'すべて選択',
      data_entry: 'データ入力',
      in_progress: '進行中',
      complete: '完了',
      cancel: 'キャンセル',
      confirm: '確認',
      noItemsYet: '項目はまだありません',
      valuesOfColMustBeUnique: '「{{column}}」項目の値は一意でなければなりません',
      changeFilterToShowItems: '項目を表示するにはフィルターを変更ください',
      createNew: '作成',
      cannotDeleteItemWithDependents: '依存するオブジェクトが存在するため、項目を削除することはできません。先にこれらを削除ください',
      delete: '削除',
      deleteItemQ: '項目を削除しますか？',
      deleteItemsQ: '{{count}}項目を削除しますか？',
      sureToDeleteItem_HTML: 'これにより、項目"<b>{{id}}</b>"が永久に削除されます。<br><b>これは元に戻すことができません。</b>',
      itemDeleted: '項目{{id}}が削除されました',
      errorDeletingItem: '項目{{id}}の削除エラー：{{reason}}',
      errorDeletingItems: '{{count}}個の項目を削除する際にエラーが発生しました',
      deleteSelectedItems: '選択した{{count}}個の項目を削除',
      downloadSelectedItems: '選択した{{count}}個の項目をダウンロード',
      sureToDeleteItems_HTML: 'これにより、<b>{{count}}個の選択された項目</b>が永久に削除されます。<br><b>これは元に戻すことができません。</b>',
      itemsDeleted: '{{count}}個の項目が削除されました',
      createNewItem: '新しい項目を作成',
      requiredFields: '必須フィールド',
      considerAddingRecommendedFields: '推奨フィールドの追加を検討ください',
      invalidFields: '無効なフィールド',
      fieldIsRequired: 'フィールド {{field}} が必要です',
      fieldIsRecommended: 'フィールド {{field}} はAI分析に推奨されます',
      itemCreated: '項目{{id}}が作成されました',
      itemUpdated: '項目{{id}}が更新されました',
      errorCreatingItem: '項目の作成エラー：{{reason}}',
      errorUpdatingItem: '項目{{id}}の更新エラー：{{reason}}',
      uncompletedActionsBlockerTitle: '未完了のアクション',
      uncompletedActionsBlockerText_HTML: '未保存の変更またはファイル転送が進行中です。進捗を失って退出しますか、それとも完了するために残りますか？',
      uncompletedActionsBlockerStayBtn: '残って完了',
      uncompletedActionsBlockerDiscardBtn: '進捗を失って退出',
      discardChanges: '変更を破棄します',
      discardChangesQ: '変更を破棄しますか？',
      discardChangesText_HTML: '編集を続けますか、それとも変更を破棄しますか？',
      discardChangesBtn: '変更を破棄',
      continueEditingBtn: '編集を続ける',
      discard: '破棄',
      edit: '編集',
      save: '保存',
      login: 'ログイン',
      emailAddress: 'メールアドレス',
      password: 'パスワード',
      forgotYourPassword: 'パスワードをお忘れですか？',
      resetPassword: 'パスワードをリセットする',
      reset: 'リセット',
      invalidLoginCredentials: '無効なログイン資格情報です。もう一度お試しください。',
      logout: 'ログアウト',
      required: '必須',
      recommended: '推奨',
      optional: '任意',
      requiredField: '必須項目',
      recommendedField: '推奨項目',
      optionalField: '任意項目',
      selectOption: '<選択>',
      noSelection: '<選択なし>',
      online: 'オンライン',
      offline: 'オフライン',
      address: '住所',
      city: '市区町村',
      state: '都道府県/州',
      postalCode: '郵便番号',
      region: '地域',
      country: '国',
      timezone: 'タイムゾーン',
      latitude: '緯度',
      longitude: '経度',
      viewOnMap: '地図で見る',
      searchItems: '{{itemCount}}項目の中で検索...',
      insights: 'インサイト',
      site: 'サイト',
      sites: 'サイト',
      turbine: 'タービン',
      turbines: 'タービン',
      blade: 'ブレード',
      blades: 'ブレード',
      bladeLength: 'ブレード長(m)',
      bladeRootDiameter: 'ブレード翼根直径(m)',
      auxiliaryComponent: '補助部品',
      installDate: '取付日',
      removedDate: '取外日',
      inspection: '点検',
      inspections: '点検',
      inspectionDetails: '点検詳細',
      inspectionDetailsStatus: ' - 状態: {{status}}',
      anomalies: '異常',
      settings: '設定',
      users: 'ユーザ管理',
      siteName: 'サイト名',
      capacity: '容量(MW)',
      status: '状態',
      maker: 'メーカー',
      model: 'モデル',
      bladeModel: 'ブレードモデル',
      serialNo: '製造番号',
      hubHeight: 'ハブ高(m)',
      inspectionDate: '点検日',
      codDate: '運転開始',
      codDateTitle: '商業運転開始日',
      eowDate: '保証終了',
      eowDateTitle: '保証期間終了日',
      turbineName: 'タービン名',
      plannedDate: '予定日',
      actualDate: '点検日',
      inspectionMethod: '点検方法',
      inspector: '点検員',
      inspectionFirm: '点検会社',
      component: '部品',
      cameraModel: 'カメラモデル',
      maxSeverity: '最大重大度',
      severity: '重大度',
      side: '側面',
      dragNDrop: 'アップロードは、ここにファイルをドラッグ＆ドロップまたはクリックして選択ください',
      errorUploadingFile: 'ファイル {{filename}} のアップロード中にエラーが発生しました: {{msg}}',
      errorUploadingFiles: 'ファイルのアップロード中にエラーが発生しました: {{msg}}',
      errorRequestingFile: 'ファイル {{filename}} のリクエストエラー：{{msg}}',
      warningUploadingFile: 'ファイル {{filename}} のアップロード時に警告: {{msg}}',
      successUploadingFile: 'ファイル {{filename}} のアップロードに成功しました',
      successUploadingFiles: '{{count}}個のファイルのアップロードに成功しました',
      report: '点検報告書',
      downloadReport: '点検報告書をダウンロード'
    },
    header: {
      notMemberOfTenant: 'あなたはこのテナントのメンバーではありませんが、管理者としてアクセスできます'
    },
    dashboard: {
      dashboardFor: '{{tenantName}}'
    },
    login: {
      userLogin: 'ユーザーログイン',
      userWasNotFound: 'メールアドレスを確認して、もう一度お試しください',
      passwordUpdateInstructionsHaveBeenEmailed: 'パスワードを更新する方法についての指示が{{emailAddress}}に送信されました'
    },
    updatePassword: {
      updatePassword: 'パスワードを更新する',
      newPassword: '新しいパスワード',
      confirmNewPassword: '新しいパスワードの確認',
      updatePasswordBtn: 'パスワードを更新',
      passwordUpdatedSuccessfully: 'パスワードが正常に更新されました'
    },
    navigationBar: {},
    siteTable: {
      name: 'サイト名',
      turbines: 'タービン数',
      sites: 'サイト一覧',
      sureToDeleteItem_HTML: 'これにより、サイト"<b>{{name}}</b>"が永久に削除されます。<br><b>これは元に戻すことができません。</b>'
    },
    turbineTable: {
      name: 'タービン名',
      createSiteFirst: '最初にサイトを作成ください',
      sureToDeleteItem_HTML: 'これにより、タービン"<b>{{siteName}}</b> / <b>{{name}}</b>"が永久に削除されます。<br><b>これは元に戻すことができません。</b>',
      siteId: 'サイト名',
      statusOnline: '状態',
      turbines: 'タービン一覧'
    },
    bladeTable: {
      statusActive: '状態',
      active: 'アクティブ',
      storage: 'ストレージ',
      blades: 'ブレード一覧'
    },
    inspectionTable: {
      siteId: 'サイト名',
      createNewItem: '新しい点検を作成',
      createTurbineFirst: '最初にタービンを作成ください',
      sureToDeleteItem_HTML: 'これにより、"<b>{{siteName}}</b> / <b>{{turbineName}}</b>" の点検 "<b>{{id}}</b>" が永久に削除されます。<br><b>これは元に戻すことができません。</b>',
      inspections: '点検一覧',
      autonomous_drone: '自律型ドローン',
      manual_drone: '手動ドローン',
      ground_based: '地上設置型カメラ',
      cannotDeleteItemWithDependents: '「データ入力」の状態の点検のみ削除できます',
      reportNotYetAvailable: 'レポートはまだ利用できません',
      turbineId: 'タービン'
    },
    inspectionDetails: {
      todoUploadMetadata: 'メタデータをアップロードする（xslx、ods、csv）',
      todoUploadMetadataHelp: 'xslx、ods、またはcsvファイルをオレンジ色のアップロードゾーンにドラッグください',
      todoUploadImages: '画像をアップロード ({{types}})',
      todoUploadImagesWithCount: '画像をアップロードする ({{types}}) (アップロード済み {{uploadedImageCount}} 枚)',
      todoUploadImagesWithCounts: '画像をアップロードする ({{types}}) ({{uploadedImageCount}}/{{allImageCount}} 枚)',
      todoUploadImagesHelp: '画像 ({{types}}) をオレンジ色のアップロードゾーンにドラッグください',
      todoUploadReport: '点検報告書をアップロード (pdf)',
      todoOverwriteReport: '点検報告書を上書きする (pdf)',
      todoUploadReportHelp: 'pdfファイルをオレンジ色のアップロードゾーンにドラッグください',
      sendNotificationEmails: '点検報告書アップロード後にテナントメンバーに通知メールを送信する',
      createdAt: 'アップロード日',
      downloadImage: '画像をダウンロード',
      downloadSelectedItems: '選択した{{count}}枚の画像をダウンロード...',
      downloadSelectedImages: '{{count}}枚の画像をダウンロード',
      downloadMultipleImages_HTML: '選択した{{count}}枚の画像（合計{{totalSize}}）をダウンロードしますか？',
      requestInspectionAnalysis: '点検分析をリクエストする...',
      requestInspectionAnalysisSuccess: '点検報告書のリクエストが成功し、メール通知が送信されました。<br>点検報告書が完成しダウンロード可能になり次第、もう一度メール通知が送られます。',
      ERR_FILE_ALREADY_UPLOADED: 'ファイルはすでにアップロードされています',
      ERR_DUPLICATE_METADATA: 'メタデータファイルは1つだけ処理できます',
      ERR_METADATA_CONTENT: '予期しないファイル内容',
      ERR_UNSUPPORTED_FILETYPE: 'コンテンツタイプ {{contentType}} はサポートされていません',
      ERR_FILE_CONTENT_DOES_NOT_MATCH_EXTENSION: 'ファイルの内容がファイルの拡張子と一致しません。予期される内容: {{expected}}。実際の内容: {{actual}}',
      METADATA_OVERWRITTEN: 'アップロードにより既存のメタデータが上書きされました',
      filename: 'ファイル名',
      contentType: '形式',
      contentTypeTitle: 'ファイルの内容タイプ',
      byteSize: 'サイズ',
      byteSizeTitle: 'ファイルサイズ',
      width: '幅',
      height: '高さ',
      imageNotYetUploaded: '画像はまだアップロードされていません',
      metadataUploadCanceled: 'メタデータアップロードがキャンセルされました',
      fieldMappingMustBeUnique: '列は複数回割り当ててはいけません',
      sureToDeleteItem_HTML: 'これにより、項目"<b>{{id}}</b>" (ファイル名：<b>{{filename}})</b>が永久に削除されます。<br><b>これは元に戻すことができません。</b>'
    },
    inspectionDetailsStartAnalysisDialog: {
      notSpecified: '未入力',
      title: '点検分析のリクエスト',
      clickHereToEnterMissingInformation: 'ここをクリックして不足情報を入力ください',
      bladeLengthNecessary: '異常面積をcm²で計測するためには、<1>ブレード</1>長が必要です。',
      bladeRootDiameterUseful: '<1>ブレード翼根直径</1>で、異常面積の報告精度がcm²で向上します。',
      h5UploadLabel: 'アップロードされたh5ファイル',
      imageUploadLabel: 'アップロードされた画像',
      imageUploadText: '{{imageUploadCount}} / {{imageCount}}',
      imageUploadNecessary: '不足している画像をアップロードするか、アップロードされていない画像の行を削除ください',
      requiredImageMetadataLabel: '必須メタデータを含む画像',
      requiredImageMetadataText: '{{imagesWithRequiredMetadataCount}} / {{imageCount}}',
      requiredImageMetadataNecessary: '報告をブレード別に構成するためには、ブレード情報が必要です。',
      recommendedImageMetadataLabel: '推奨メタデータを含む画像',
      recommendedImageMetadataText: '{{imagesWithRecommendedMetadataCount}} / {{imageCount}}',
      recommendedImageMetadataUseful: '推奨される画像メタデータを使用すると、を提供することで、報告書はブレードとブレードの側面ごとに構成され、異常の位置が視覚化されます。',
      submitAnalysisBtn: '分析提出',
      submitAnalysisAsIsBtn: 'このまま提出',
      resolveIssues: '挙げられた問題を解決ください',
      cancelBtn: 'キャンセル',
      enterMissingDataBtn: '不足データを入力',
      enterRecommendedDataBtn: '推奨データを入力'
    },
    inspectionDetailsMetadata: {
      imageFilename: '画像ファイル名',
      adjustAndConfirmColumnMapping: 'メタデータの列マッピングの調整と確認 ({{metadataFilename}})',
      blade: 'ブレード',
      bladeSerialNo: 'ブレード製造番号',
      bladeSide: 'ブレード側面',
      minRootDistance: '最小翼根距離(m)',
      minRootDistanceTitle: '翼根部から画像の端までの最小距離',
      centerRootDistance: '中心根距離(m)',
      centerRootDistanceTitle: '画像の中心点から翼根部までの距離。ブレード長によって制限される。',
      maxRootDistance: '最大根距離(m)',
      maxRootDistanceTitle: '翼根部から画像の端までの最大距離',
      cameraOrientation: 'カメラの向き',
      cameraType: 'カメラの種類',
      distanceToBlade: 'ブレードまでの距離(m)',
      distanceToBladeTitle: 'カメラからブレードまでの距離',
      angle: '角度(°)'
    },
    anomalyTable: {
      anomalies: '異常一覧'
    }
  }
}

export const supportedLanguages: Record<string, string> = Object.fromEntries(
  Object
    .keys(resources)
    .map(lang2Chars => [lang2Chars, getLanguageDisplayName(lang2Chars)])
    .sort(([_1, lName1], [_2, lName2]) =>
      (lName1 ?? '').localeCompare(lName2 ?? ''))
)

export const l10nCountry = (country: string, i18n: { language: string }): string => {
  const lang = i18n.language.split('-')[0] ?? 'en'
  return countries.getName(country, lang) ??
        countries.getName(country, 'en') ?? // fallback to English
        country // fallback to input
}

function getLanguageDisplayName (languageCode: string): string | undefined {
  const displayNames = new Intl.DisplayNames([languageCode, 'en'], { type: 'language' })
  return displayNames.of(languageCode)
}

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.language,
  fallbackLng: 'en',
  defaultNS: 'common',
  fallbackNS: 'common',
  interpolation: {
    escapeValue: false
  }
}).catch(reason => { console.error('i18next init error', reason) })

export default i18n
