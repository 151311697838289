import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { getMeta, stripOffUrlBase, urlIsAtRoot } from '../utils/utils'
import { useApolloClient } from '@apollo/client'
import useSyncedRef from './useSyncedRef'
import { useGlobalState } from './useGlobalState'

export default function useForceReLogin (): () => void {
  const location = useLocation()
  const navigateTo: string | null = urlIsAtRoot(location.pathname) ? '' : ('#' + stripOffUrlBase(location.pathname))
  const navigateToRef = useSyncedRef(navigateTo)
  const { setTenantId, onLogout } = useGlobalState()
  const client = useApolloClient()

  return useCallback((): void => {
    // setTimeout is used to address this error:
    // Unexpected Application Error!
    // Too many re-renders. React limits the number of renders to prevent an infinite loop.
    setTimeout(() => {
      setTenantId(null)
      void Promise.allSettled([client.resetStore(), onLogout()])
        .finally(() => { window.location.href = getMeta('urlBase') + '/login' + navigateToRef.current })
    }, 0)
  }, [])
}
