import React from 'react'

const SuspenseFallback: React.FC = () => {
  return (
        <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-latoda-orange"></div>
        </div>
  )
}

export default SuspenseFallback
