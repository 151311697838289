import * as React from 'react'
import { Outlet } from 'react-router-dom'
import useForceReLogin from '../../hooks/useForceReLogin'
import { useGlobalState } from '../../hooks/useGlobalState'

export const ProtectedLayout = (): React.JSX.Element => {
  const { user } = useGlobalState()
  const forceRelogin = useForceReLogin()

  if (user == null) {
    forceRelogin()
  }
  return user != null ? <Outlet /> : <></>
}
