import { useState } from 'react'

export const useStorage = <T>(storage: Storage, keyName: string, defaultValue: T | (() => T) | null = null): [T, (newValue: T | null) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = storage.getItem(keyName)
      if (value != null) {
        return JSON.parse(value)
      } else {
        let valueFromDefaultValue
        if (defaultValue == null) {
          valueFromDefaultValue = null
        } else {
          valueFromDefaultValue = typeof defaultValue === 'function' ? (defaultValue as () => T)() : defaultValue
          storage.setItem(keyName, JSON.stringify(valueFromDefaultValue))
        }
        return defaultValue
      }
    } catch (err) {
      console.error(err)
      return defaultValue
    }
  })
  const setValue = (newValue: T | null): void => {
    try {
      if (newValue == null) {
        storage.removeItem(keyName)
      } else {
        const curValueStr = storage.getItem(keyName) // todo: perhaps we should keep our own local copy of the string around
        const newValueStr = JSON.stringify(newValue)
        if (newValueStr === curValueStr) {
          return // we don't want to call setStoredValue, which would cause an unnecessary reactive chain reaction
        }
        storage.setItem(keyName, newValueStr)
      }
    } catch (err) {
      console.error(err)
    }
    setStoredValue(newValue)
  }
  return [storedValue, setValue]
}
