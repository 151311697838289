import { type DocumentNode } from 'graphql/language'
import { type FetchResult, useMutation } from '@apollo/client'
import { type IdResult, type Scalars } from '../graphql/graphql'

export class MutationError extends Error {
  errors: Array<Scalars['String']['output']>

  constructor (message: string, errors: Array<Scalars['String']['output']>) {
    super(message)
    this.name = 'MutationError'
    this.errors = errors
  }
}

export type GetResultsFunction = (result: FetchResult<any>) => IdResult[]

function useDeleteEntitiesMutation (mutation: DocumentNode, getResults: GetResultsFunction): (ids: string[]) => Promise<IdResult[]> {
  const [mutate] = useMutation(mutation)
  return async (ids: string[]): Promise<IdResult[]> => {
    return await mutate({
      variables: { ids }
    }).then(getResults)
  }
}

export default useDeleteEntitiesMutation
