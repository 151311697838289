import { useEffect } from 'react'

const appName = 'LATODA'

export default function useDocumentTitle (title: string): void {
  useEffect(() => {
    document.title = title !== '' ? `${appName} - ${title}` : appName
    return () => {
      document.title = appName
    }
  },
  [title])
}
