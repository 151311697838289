import * as React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  type SortingState,
  type ColumnFiltersState,
  getFilteredRowModel,
  type TableOptions, type ColumnDef, type Row
} from '@tanstack/react-table'
import type { Inspection, InspectionImage, Site, TenantProperties, Turbine } from '../../graphql/graphql'
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Table, { type ColumnMeta, type TableMeta } from '../table/table'
import { useTranslation } from 'react-i18next'
import { useApolloClient, useMutation } from '@apollo/client'
import {
  DELETE_INSPECTION_IMAGES_MUTATION,
  UPDATE_INSPECTION_IMAGE_MUTATION,
  REQUEST_INSPECTION_REPORT
} from '../../graphql/queriesAndMutations'
import InspectionFileUploadForm from './inspectionFileUploadForm'
import CheckBox from '../common/checkBox'
import { useLocalizedToast } from '../../hooks/useLocalizedToast'
import { DocumentIcon, PhotoIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import { FolderArrowDownIcon, PhotoIcon as PhotoIconOutline, DocumentIcon as DocumentIconOutline } from '@heroicons/react/24/outline'
import MetadataFormatConfirm, { type ColumnMatch } from './metadataFormatConfirm'
import { byteSizeToHumanReadable, shortId, stopPropagation, getMeta } from '../../utils/utils'
import TableCell from '../table/tableCell'
import { getAuthHeaders } from '../../utils/auth'
import { buildReportFilename, downloadMultiple, downloadSingle } from '../../utils/download'
import { type ProgressBarProps } from '../common/progressBar'
import { type UpdateEvent } from '../../types/cableEvents'
import { type Accept } from 'react-dropzone'
import useForceReLogin from '../../hooks/useForceReLogin'
import { useActionCable } from '../../hooks/useActionCable'
import useEntityMutation from '../../hooks/useEntityMutation'
import useEntityQuery from '../../hooks/useEntityQuery'
import { NotFoundError } from '../../errors/notFoundError'
import { getCachedObject } from '../apolloClientManager'
import { useEntitySubscription } from '../../hooks/useEntitySubscription'
import SuspenseFallback from '../common/suspenseFallback'
import useDeleteEntitiesMutation from '../../hooks/useDeleteEntitiesMutation'
import { setToValueOtherThanThis, validateNumber, validatePositiveNumber } from '../table/validations'
import {
  MAX_CENTER_ROOT_DISTANCE_M, MAX_DISTANCE_TO_BLADE_M, MAX_MAX_ROOT_DISTANCE_M, MAX_MIN_ROOT_DISTANCE_M,
  MIN_CENTER_ROOT_DISTANCE_M, MIN_DISTANCE_TO_BLADE_M, MIN_MAX_ROOT_DISTANCE_M, MIN_MIN_ROOT_DISTANCE_M
} from '../../utils/constants'
import { optionsFromValues } from '../table/tableUtils'
import RequestAnalysisDialog from './requestAnalysisDialog'
import useSyncedRef from '../../hooks/useSyncedRef'
import usePermissions from '../../hooks/usePermissions'
import { useGlobalState } from '../../hooks/useGlobalState'
import ConfirmationDialog from '../common/confirmationDialog'
import useStateMap from '../../hooks/useStateMap'
import ProgressBars from '../common/progressBars'
import useDocumentTitle from '../../hooks/useDocumentTitle'

interface ErrorType {
  type: string
  i18nOpts?: object
}

interface UploadResponseItem {
  filename: string
  content_type: string
  error?: ErrorType
  confirm_metadata?: ConfirmMetadata
}

export interface UploadResponse {
  result: UploadResponseItem
}

export interface BeforeUploadValidationResult {
  response?: UploadResponse
  error?: any
  additionalFormData?: Record<string, string>
}

interface ConfirmMetadata {
  details: ConfirmMetadataDetails
}

interface ConfirmMetadataDetails {
  matches: Record<string, ColumnMatch>
  data: string[][]
}

export type ParsedInspectionImage = Omit<InspectionImage, 'metadata'> & {
  metadata: any
}

export const H5_CONTENT_TYPE = 'application/x-hdf'

const METADATA_CONTENT_TYPES_AND_EXTENSIONS: Accept = {
  'application/vnd.ms-excel': ['*.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['*.xlsx'],
  'application/vnd.oasis.opendocument.spreadsheet': ['*.ods'],
  'text/csv': ['*.csv']
}

const REPORT_CONTENT_TYPES_AND_EXTENSIONS: Accept = {
  'application/pdf': ['*.pdf']
}

interface ImageTypeInfo {
  contentTypeAndExtensions: Array<[string, string[]]>
  extensionRegex?: RegExp
}
const iti = function (contentTypeAndExtensions: Array<[string, string[]]>, extensionRegex?: RegExp): ImageTypeInfo {
  return {
    contentTypeAndExtensions,
    extensionRegex
  }
}

const CLIENT_SIDE_IMAGE_TYPES_AND_EXTENSIONS: Record<string, ImageTypeInfo> = {
  jpeg: iti([['image/jpeg', ['.jpeg', '.jpg']]]),
  png: iti([['image/png', ['.png']]]),
  h5: iti([ // It's up to the OS to determine the mime type, so we need to cover all possible cases
    ['application/x-hdf', ['.h5']], // the mime type we use on the server side - that's what Marcel::MimeType returns
    ['application/x-hdf5', ['.h5']], // another possible mime type for h5 files
    ['text/x-hdf5', ['.h5']] // on a Windows machine (supposedly tested in Chrome and Edge)
    // ['']. ['.h5']] // on MacOS (and Linux?) - but in that case we need to check the file extension, which is why we don't set this here
  ], /\.h5$/i)
}

const columnHelper = createColumnHelper<ParsedInspectionImage>()

const InspectionDetails: React.FC = () => {
  const { id } = useParams()
  const { error: inspectionImagesSubError } = useEntitySubscription('InspectionImage', id)
  if (inspectionImagesSubError != null) {
    throw inspectionImagesSubError
  }
  return (<Suspense fallback={<SuspenseFallback/>}>
            <InspectionDetailsInner/>
          </Suspense>)
}

function estimateTotalZipSize (multiDownloadImages: ParsedInspectionImage[]): number {
  const perZipOverhead = 51
  const perFileEntryOverhead = 94
  return perZipOverhead + multiDownloadImages.reduce((acc: number, cur: ParsedInspectionImage) => {
    return acc + perFileEntryOverhead + (cur.byteSize ?? 0) + (2 * (cur.filename ?? '').length)
  }, 0)
}

const InspectionDetailsInner: React.FC = () => {
  const { id } = useParams()

  const sites = useEntityQuery<Site>('Site', { suspense: true })
  const turbines = useEntityQuery<Turbine>('Turbine', { suspense: true })
  const inspections = useEntityQuery<Inspection>('Inspection', { suspense: true })
  const imageData = useEntityQuery<InspectionImage>('InspectionImage', { suspense: true, id })
  const tenantProperties = useEntityQuery<TenantProperties>('TenantProperties')?.[0]
  const imageTypes: string[] = tenantProperties?.properties.inspectionImageTypes ?? ['jpeg']
  const imageTypesStr = imageTypes.join(', ')

  const { t, i18n } = useTranslation('inspectionDetails')
  const { t: tMetadata } = useTranslation('inspectionDetailsMetadata')
  const { tenantId, authData, setHasUnsavedChanges } = useGlobalState()
  const forceReLogin = useForceReLogin()
  const [isRequestAnalysisDialogOpen, setRequestInspectionAnalysisConfirmDialogOpen] = useState(false)
  const [metadataConfirmResult, setMetadataConfirmResult] = useState<UploadResponseItem | null>(null)
  const [metadataColumnSelections, setMetadataColumnSelections] = useState<Record<string, number> | null>(null)
  const { map: uploadingFiles, setOrDelete: setProgressUploading } = useStateMap<string, ProgressBarProps>()
  const { map: downloadingFiles, setOrDelete: setProgressDownloading } = useStateMap<string, ProgressBarProps>()
  const [multiDownloadImages, setMultiDownloadImages] = useState<ParsedInspectionImage[]>([])

  useEffect(() => {
    setHasUnsavedChanges(uploadingFiles.size > 0 || downloadingFiles.size > 0)
  }, [uploadingFiles, downloadingFiles])

  const showToast = useLocalizedToast()

  const client = useApolloClient()
  const getSite = (siteId: string): Site => getCachedObject<Site>(client, 'Site', siteId)
  const getTurbine = (turbineId: string): Turbine => getCachedObject<Turbine>(client, 'Turbine', turbineId)

  const [site, turbine, inspection] = useMemo(() => {
    const inspection = inspections?.find(i => i.id === id)
    const turbine = inspection == null ? null : getTurbine(inspection.turbineId)
    const site = turbine == null ? null : getSite(turbine.siteId)
    return [site, turbine, inspection]
  }, [sites, turbines, inspections])

  if (site == null || turbine == null || inspection == null) {
    throw new NotFoundError(`${t('inspection')} "${id}"`)
  }

  useDocumentTitle(t('inspectionDetails') + ' ' + site.name + ' / ' + turbine.name)

  const [sendEmails, setSendEmails] = useState(true)
  const sendEmailsRef = useSyncedRef(sendEmails)

  const status = inspection.status
  const inspectionImages = useMemo<ParsedInspectionImage[]>(() => {
    const map: ParsedInspectionImage[] = imageData.map((row: InspectionImage) => {
      const parsedMetadata = row.metadata != null ? JSON.parse(row.metadata) : {}
      const parsedInspectionImage: ParsedInspectionImage = { ...row, metadata: { ...parsedMetadata } }
      return parsedInspectionImage
    })
    return map
  }, [imageData, inspection])

  const nonH5Images = imageData == null ? [] : imageData.filter(i => i.contentType !== H5_CONTENT_TYPE)
  const imageCount = nonH5Images.length
  const uploadedImageCount = nonH5Images.reduce((agg: number, cur: InspectionImage) => (cur.byteSize ?? 0) > 0 ? agg + 1 : agg, 0)
  const allImagesUploaded = imageCount > 0 && uploadedImageCount === imageCount
  const someImagesUploaded = !allImagesUploaded && uploadedImageCount > 0
  const readyForAnalysis = uploadedImageCount > 0

  const inspectionImagesRef = useRef(inspectionImages)
  inspectionImagesRef.current = inspectionImages

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'filename', desc: false }
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const contentTypeMap = new Map([
    ['image/null', t('notAvailable')],
    ['image/jpeg', 'jpeg'],
    ['image/png', 'png'],
    ['application/x-hdf', 'h5']
  ])

  const updateInspectionImageMutation = useEntityMutation(UPDATE_INSPECTION_IMAGE_MUTATION, r => r.data.updateInspectionImage.errors, r => r.data.updateInspectionImage.inspectionImage)
  const deleteRows = useDeleteEntitiesMutation(DELETE_INSPECTION_IMAGES_MUTATION, r => r.data.deleteInspectionImages.results)

  const saveEdit = async (inspectionImage: ParsedInspectionImage): Promise<string> => {
    return await updateInspectionImageMutation({ id: inspectionImage.id, customMetadata: JSON.stringify(inspectionImage.metadata.custom) })
  }

  const [requestInspectionReport] = useMutation(REQUEST_INSPECTION_REPORT)

  async function requestInspectionAnalysis (inspectionId: string): Promise<void> {
    await requestInspectionReport({ variables: { inspectionId } })
      .then(response => {
        if (response.data.requestInspectionAnalysis.errors != null) {
          throw response.data.requestInspectionAnalysis.errors
        }
      })
  }

  const downloadImage = (imageBlobId: string, _: string, filename: string, expectedFileSize: number): void => {
    if (authData == null || tenantId == null) {
      forceReLogin()
      return
    }

    downloadSingle(imageBlobId, authData, tenantId, { save: true, filename, setProgress: setProgressDownloading, expectedFileSize })
      .catch((error) => {
        showToast('error', () => t('errorRequestingFile', { filename, msg: JSON.stringify(error) }))
      })
  }

  const downloadRows = async (rows: ParsedInspectionImage[]): Promise<void> => {
    setMultiDownloadImages(rows)
  }

  const doDownloadRows = (rows: ParsedInspectionImage[]): void => {
    setMultiDownloadImages([])
    if (authData == null || tenantId == null) {
      forceReLogin()
      return
    }
    const blobIds: string[] = rows.map(image => {
      return image.imageBlobId != null && image.contentType != null && image.filename != null ? image.imageBlobId : null
    }).filter((blobId): blobId is string => blobId != null)

    downloadMultiple(blobIds, authData, tenantId, {
      save: true,
      setProgress: setProgressDownloading,
      expectedFileSize: estimateTotalZipSize(rows),
      filename: `LATODA_${inspection.id}_images.zip`
    })
      .catch((error) => {
        console.error(error)
        showToast('error', () => 'xxx ' + t('errorRequestingFile', { filename: '', msg: JSON.stringify(error) }))
      })
  }

  function required (_row: Row<ParsedInspectionImage>, _: string): 'required' | undefined {
    return status !== 'complete' ? 'required' : undefined
  }

  function recommendedIfH5Image (row: Row<ParsedInspectionImage>, _: string): 'recommended' | undefined {
    return status !== 'complete' && row.original.metadata?.custom?.origContentType === H5_CONTENT_TYPE ? 'recommended' : undefined
  }

  function recommendedUnlessH5Image (row: Row<ParsedInspectionImage>, _: string): 'recommended' | undefined {
    return status !== 'complete' && row.original.contentType !== H5_CONTENT_TYPE && row.original.metadata?.custom?.origContentType !== H5_CONTENT_TYPE
      ? 'recommended'
      : undefined
  }

  function recommendedExceptForH5Original (row: Row<ParsedInspectionImage>, _: string): 'recommended' | undefined {
    return status !== 'complete' && row.original.contentType !== H5_CONTENT_TYPE ? 'recommended' : undefined
  }
  function requiredExceptForH5Original (row: Row<ParsedInspectionImage>, _: string): 'required' | undefined {
    return status !== 'complete' && row.original.contentType !== H5_CONTENT_TYPE ? 'required' : undefined
  }

  const metadataColumns = new Map<string, ColumnMeta<ParsedInspectionImage> | null>([
    ['blade', { required: requiredExceptForH5Original, options: optionsFromValues(['A', 'B', 'C']) }],
    ['bladeSerialNo', { required: recommendedExceptForH5Original }],
    ['bladeSide', { required: recommendedExceptForH5Original }],
    ['minRootDistance', validateNumber<ParsedInspectionImage>({ required: recommendedUnlessH5Image, min: MIN_MIN_ROOT_DISTANCE_M, max: MAX_MIN_ROOT_DISTANCE_M })],
    ['centerRootDistance', validateNumber<ParsedInspectionImage>({ required: recommendedExceptForH5Original, min: MIN_CENTER_ROOT_DISTANCE_M, max: MAX_CENTER_ROOT_DISTANCE_M })],
    ['maxRootDistance', validateNumber<ParsedInspectionImage>({ required: recommendedUnlessH5Image, min: MIN_MAX_ROOT_DISTANCE_M, max: MAX_MAX_ROOT_DISTANCE_M })],
    ['distanceToBlade', validateNumber<ParsedInspectionImage>({ required: recommendedIfH5Image, min: MIN_DISTANCE_TO_BLADE_M, max: MAX_DISTANCE_TO_BLADE_M })]
    // ['angle', validatePositiveNumber<ParsedInspectionImage>({ min: MIN_CAMERA_ANGLE_DEGREES, max: MAX_CAMERA_ANGLE_DEGREES })],
    // ['bladeLength', validateNumber<ParsedInspectionImage>({ min: MIN_BLADE_LENGTH_M, max: MAX_BLADE_LENGTH_M })]
  ])

  // metadata editing needs special attention, as it isn't a normal row attribute, but instead metadata is stored inside
  // row.metadata.custom
  const rowEditor = (curRow: ParsedInspectionImage | null, _: number, colId: string, newVal: any): ParsedInspectionImage | null => {
    if (curRow == null) {
      return null
    }
    if (metadataColumns.has(colId)) {
      const newCustomMetadata = { ...curRow.metadata.custom }
      newCustomMetadata[colId] = newVal
      return { ...curRow, metadata: { ...curRow.metadata, custom: newCustomMetadata } }
    }
    return { ...curRow, [colId]: newVal }
  }

  const columns: Array<ColumnDef<ParsedInspectionImage, any>> = [
    columnHelper.accessor('id', {
      header: 'id',
      cell: info => {
        const id = info.getValue()
        return typeof id === 'string' ? <span title={id}>{shortId(id)}</span> : null
      }
    }),
    columnHelper.accessor('imageBlobId', {
      header: () => <FolderArrowDownIcon title={t('downloadImage')} className="h-6"/>,
      enableSorting: false,
      cell: info => {
        const imageBlobId = info.getValue()
        return typeof imageBlobId !== 'string'
          ? <span title={t('imageNotYetUploaded')} onClick={stopPropagation}>
              <PhotoIconOutline className="h-6 opacity-0 cursor-default"/>
            </span>
          : <span title={t('downloadImage')} className="cursor-pointer"
                    onClick={e => {
                      e.stopPropagation()
                      if (info.row.original.filename != null && info.row.original.contentType != null) {
                        downloadImage(imageBlobId, info.row.original.contentType, info.row.original.filename, info.row.original.byteSize ?? 0)
                      }
                    }}>
            {info.row.original.contentType?.startsWith('image/') === true
              ? <PhotoIconOutline className="h-6"/>
              : <DocumentIconOutline className="h-6"/>
            }
          </span>
      }
    }),
    columnHelper.accessor('filename', { header: 'filename' }),
    columnHelper.accessor('contentType', {
      header: 'contentType',
      cell: TableCell,
      meta: {
        readonly: true,
        required,
        requiredText: t('imageNotYetUploaded'),
        validate: setToValueOtherThanThis('image/null'),
        render: (type: string) => contentTypeMap.get(type ?? '') ?? type
      } satisfies ColumnMeta<ParsedInspectionImage>
    }),
    columnHelper.accessor('byteSize', {
      header: 'byteSize',
      cell: TableCell,
      meta: validatePositiveNumber<ParsedInspectionImage>({
        readonly: true,
        required,
        requiredText: t('imageNotYetUploaded'),
        render: (size: number) => size === 0 ? t('notAvailable') : byteSizeToHumanReadable(size ?? 0, i18n.language)
      })
    })
  ]

  for (const [key, meta] of metadataColumns) {
    const defaultMeta = { captionT: tMetadata }
    const mergedMeta = meta != null ? { ...defaultMeta, ...meta } : defaultMeta
    columns.push({
      id: key,
      accessorFn: (row: ParsedInspectionImage) => row.contentType === H5_CONTENT_TYPE ? '-' : row.metadata?.custom?.[key],
      cell: TableCell,
      header: key,
      meta: mergedMeta
    })
  }

  columns.push(columnHelper.accessor('updatedAt', { header: 'updatedAt', cell: TableCell, meta: { type: 'datetime', readonly: true } }))

  const tableMeta: TableMeta<ParsedInspectionImage> = status !== 'in_progress'
    ? {
        t,
        i18n,
        enableMultiDelete: true,
        deleteRows,
        downloadRows,
        saveEdit
      }
    : {
        t,
        i18n,
        downloadRows
      }

  const tableOptions: TableOptions<ParsedInspectionImage> = {
    data: inspectionImages,
    columns,
    getRowId: (row: ParsedInspectionImage) => row.id,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter: _ => true,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnFilters
    },
    meta: tableMeta
  }

  const handleUploadResult = (response: UploadResponse | null, err: any): void => {
    if (err != null) {
      showToast('error', () => t('errorUploadingFiles', { msg: err }))
      console.error('Error uploading files', err)
      return
    }
    const appendDetails = (msg: string, error: any): string => error.details !== undefined ? msg + '\n' + JSON.stringify(error.details) : msg
    if (response?.result != null) {
      const result = response?.result
      const i18nOpts = { filename: result.filename, contentType: result.content_type }
      const resultError = result.error
      if (resultError != null) {
        const msg = t(resultError.type, { ...i18nOpts, ...resultError.i18nOpts })
        showToast('error', () => appendDetails(t('errorUploadingFile', { ...i18nOpts, msg }), resultError))
      } else if (result.confirm_metadata != null) {
        setMetadataConfirmResult(result)
      }
    }
  }

  const imageFilenameRegexes: RegExp[] = useMemo(() => {
    return imageTypes
      .map(t => CLIENT_SIDE_IMAGE_TYPES_AND_EXTENSIONS[t]?.extensionRegex)
      .filter(Boolean) as RegExp[]
  }, [imageTypes])
  const needsFilenameMatching = imageFilenameRegexes.length > 0
  // we cannot the reject class red if we have to be able to match by file extension (h5 files)
  const imageUploadDragRejectClass = needsFilenameMatching ? 'bg-lime-200' : 'bg-red-400'

  const dropZoneImageAccept: Accept | null = useMemo(() => {
    return needsFilenameMatching
      ? null
      /*  // Unfortunately, the following content-type map leads to warnings of this kind:
          //   Skipped "" because it is not a valid MIME type. Check https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types for a list of valid MIME types.
          // which is why I'm just not setting dropZoneImageAccept, which means any file will be considered acceptable
       {
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
          // the following unfortunately only kind of works in terms of isDragAccept,
          // hence the need to make imageUploadDragRejectClass not red
          '': ['.h5']
        }
        */
      : Object.fromEntries(imageTypes
        .flatMap(t => CLIENT_SIDE_IMAGE_TYPES_AND_EXTENSIONS[t]?.contentTypeAndExtensions)
        .filter(Boolean))
  }, [imageTypes])
  const validImageContentTypes = useMemo(() => {
    return imageTypes
      .flatMap(t => CLIENT_SIDE_IMAGE_TYPES_AND_EXTENSIONS[t]?.contentTypeAndExtensions)
      .filter(Boolean)
      .map(a => a[0])
  }, [imageTypes])

  function beforeUploadValidationMetadata (file: File | null): BeforeUploadValidationResult | null {
    if (file === null) { // all dropped files were rejected
      showToast('warn', () => t('todoUploadMetadataHelp'))
      return null
    }
    if (!Object.prototype.hasOwnProperty.call(METADATA_CONTENT_TYPES_AND_EXTENSIONS, file.type)) {
      return createUploadValidationError(file, 'ERR_UNSUPPORTED_FILETYPE')
    }
    return null
  }

  function beforeUploadValidationImage (file: File | null): BeforeUploadValidationResult | null {
    // we need to use inspectionImagesRef, rather than inspectionImages, because we pass this function
    // to a child component
    if (file === null) { // all dropped files were rejected
      showToast('warn', () => t('todoUploadImagesHelp', { types: imageTypesStr }))
      return null
    }
    if (inspectionImagesRef.current.find(img => img.filename === file.name && Number(img.byteSize) < 0) !== undefined) {
      return createUploadValidationError(file, 'ERR_FILE_ALREADY_UPLOADED')
    }
    if (!(validImageContentTypes.includes(file.type) || imageFilenameRegexes.some(re => file.name.match(re) != null))) {
      return createUploadValidationError(file, 'ERR_UNSUPPORTED_FILETYPE')
    }
    return null
  }

  function beforeUploadValidationReport (file: File | null): BeforeUploadValidationResult | null {
    if (file === null) { // all dropped files were rejected
      showToast('warn', () => t('todoUploadReportHelp'))
      return null
    }
    if (!Object.prototype.hasOwnProperty.call(REPORT_CONTENT_TYPES_AND_EXTENSIONS, file.type)) {
      return createUploadValidationError(file, 'ERR_UNSUPPORTED_FILETYPE')
    }
    return { additionalFormData: { sendEmails: sendEmailsRef.current + '' } }
  }

  function createUploadValidationError (file: File, errorType: string): BeforeUploadValidationResult {
    return {
      response: {
        result: {
          filename: file.name,
          content_type: file.type,
          error: {
            type: errorType
          }
        }
      },
      error: null
    }
  }

  const handleRequestInspectionAnalysis = (): void => { setRequestInspectionAnalysisConfirmDialogOpen(true) }
  const requestInspectionConfirm = async (): Promise<void> => {
    if (id == null) {
      throw new Error('id not set')
    }
    await requestInspectionAnalysis(id)
      .catch((error: any) => {
        const errs = Array.isArray(error) ? error : [error]
        errs.forEach(e => {
          showToast('error', () => t('errorUpdatingItem', { reason: e, id }))
        })
      })
      .finally(() => {
        setRequestInspectionAnalysisConfirmDialogOpen(false)
      })
      .then(() => {
        showToast('success', () =>
            <p dangerouslySetInnerHTML={{ __html: t('requestInspectionAnalysisSuccess', { id }) }}></p>
        )
      })
  }

  const requestAnalysisCancel = (): void => { setRequestInspectionAnalysisConfirmDialogOpen(false) }

  const cancelMetadataConfirm = (): void => {
    setMetadataConfirmResult(null)
  }

  const confirmMetadataConfirm = useCallback((): void => {
    if (authData == null || tenantId == null) {
      forceReLogin()
      return
    }
    const requestData = {
      metadata: {
        filename: metadataConfirmResult?.filename,
        columnSelections: metadataColumnSelections,
        // rows as JSON string, because otherwise satisfying "Strong Parameters" on the Rails side would require quite an effort
        rows: JSON.stringify(metadataConfirmResult?.confirm_metadata?.details?.data)
      }
    }
    fetch(getMeta('urlBase') + `/inspections/${id}/confirm_metadata`, {
      method: 'PATCH',
      body: JSON.stringify(requestData),
      headers: getAuthHeaders(authData, { 'Content-Type': 'application/json', tid: tenantId })
    })
      .then(async (response) => await response.json())
      .then((_) => {
        showToast('success', () => t('successUploadingFile', { filename: metadataConfirmResult?.filename }))
      })
      .catch((error) => {
        console.error('confirm metadata', error)
        showToast('error', () => t('errorUploadingFile', { filename: metadataConfirmResult?.filename, msg: JSON.stringify(error) }))
      })

    setMetadataConfirmResult(null)
  }, [authData, forceReLogin, metadataConfirmResult, metadataColumnSelections])

  const reportUploaded = inspection.reportBlobId != null
  const reportBlobId = inspection.reportBlobId // we need this for TypeScript's null check
  const permissions = usePermissions()
  const permissionedToRequestReport = permissions?.canDo('inspection_report', 'request', inspection.id)
  const permissionedToCreateReport = permissions?.canDo('inspection_report', 'create', inspection.id)
  const permissionedToUpdateReport = permissions?.canDo('inspection_report', 'update', inspection.id)

  const downloadReport = (): void => {
    if (typeof reportBlobId !== 'string') {
      return
    }
    if (authData == null || tenantId == null) {
      forceReLogin()
      return
    }
    const filename = buildReportFilename(inspection.id, String(inspection.updatedAt))
    downloadSingle(reportBlobId, authData, tenantId, { save: true, filename, setProgress: setProgressDownloading })
      .catch((error) => {
        showToast('error', () => t('errorRequestingFile', { filename, msg: JSON.stringify(error) }))
      })
  }

  const onReceived = useCallback<(channel: string, data: any) => void>((_: string, data: any) => {
    switch (data?.event) {
      case 'connectionEstablished': {
        // void imageRefetch() // we may have missed messages between disconnect and reconnect
        break
      }
      case 'update': {
        const updateEvent = data as UpdateEvent
        switch (updateEvent.resource) {
          case 'inspectionImage': {
            const associatedResources = updateEvent.associatedResources
            for (const associatedResource of associatedResources ?? []) {
              if (associatedResource.id === inspection.id) {
                // void imageRefetch()
                break
              }
            }
          }
        }
        break
      }
    }
  }, [])
  const { subscribe, unsubscribe } = useActionCable()
  useEffect(() => {
    subscribe('AppChannel', onReceived)
    return () => {
      unsubscribe('AppChannel', onReceived)
    }
  }, [])

  return imageData == null
    ? <div className="mx-2">{t('loading')}</div>
    : <div className="relative flex flex-col h-full">
        <div className="m-1 border-gray-200">
          <ul className="flex flex-wrap">
            <li className="mr-2 rounded-lg border border-gray-400">
              <Link to="/inspections"
                    className={'flex flex-col items-center hover:text-yellow-500 focus:outline-1'}
              ><h1 className="text-lg mx-2">{t('inspections')}</h1></Link>
            </li>
            <li className="mr-2 rounded-lg border border-gray-400">
              <h1 className="text-lg mx-2">
                <span className="font-bold">{t('inspectionDetails')} &nbsp;&nbsp;&nbsp;</span>
                <span className="font-gray-600">{site.name} / {turbine.name}</span>
                {status !== 'data_entry' && t('inspectionDetailsStatus', { status: t(status) })}
              </h1>
            </li>
          </ul>
        </div>
        <div className="mx-2">
          <div className="min-h-16 flex mb-2">
            {status === 'data_entry' &&
              <>
                <InspectionFileUploadForm
                  className="w-full min-h-full"
                  title={t('todoUploadMetadataHelp')}
                  onUploadResult={handleUploadResult}
                  dropZoneOpts={{
                    maxFiles: 1,
                    accept: METADATA_CONTENT_TYPES_AND_EXTENSIONS
                  }}
                  setProgress={setProgressUploading}
                  beforeUploadValidation={beforeUploadValidationMetadata}>
                  <div className="flex items-center">
                    <TableCellsIcon className="w-16 h-16"/>
                    <div>
                      <ul>
                        <li className={'flex'}>
                          <span className="font-bold">{t('todoUploadMetadata')}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </InspectionFileUploadForm>
                <InspectionFileUploadForm
                  className="w-full min-h-full ml-2"
                  title={t('todoUploadImagesHelp', { types: imageTypesStr })}
                  onUploadResult={handleUploadResult}
                  dragRejectClass={imageUploadDragRejectClass}
                  dropZoneOpts={dropZoneImageAccept == null ? {} : { accept: dropZoneImageAccept }}
                  setProgress={setProgressUploading}
                  beforeUploadValidation={beforeUploadValidationImage}>
                  <div className="flex items-center">
                    <PhotoIcon className="w-16 h-16"/>
                    <div>
                      <ul>
                        <li className={`flex ${allImagesUploaded
                          ? 'cursor-default text-green-700'
                          : someImagesUploaded
                            ? 'text-yellow-700'
                            : ''}`}>
                          <CheckBox
                            checked={allImagesUploaded ? true : someImagesUploaded ? 'half' : false}/>
                          <span className="font-bold">
                                            {imageCount != null
                                              ? t('todoUploadImagesWithCounts', {
                                                types: imageTypesStr,
                                                uploadedImageCount,
                                                allImageCount: imageCount
                                              })
                                              : uploadedImageCount > 0
                                                ? t('todoUploadImagesWithCount', {
                                                  types: imageTypesStr,
                                                  uploadedImageCount
                                                })
                                                : t('todoUploadImages', { types: imageTypesStr })}
                                            </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </InspectionFileUploadForm>
              </>
            }
            {((reportUploaded && permissionedToUpdateReport) || (!reportUploaded && permissionedToCreateReport)) &&
              <div className="w-full h-full ml-2 border border-orange-300 rounded flex flex-col items-center">
                <InspectionFileUploadForm
                  className="w-full"
                  title={reportUploaded ? '' : t('todoUploadReportHelp')}
                  onUploadResult={handleUploadResult}
                  dropZoneOpts={{
                    maxFiles: 1,
                    accept: REPORT_CONTENT_TYPES_AND_EXTENSIONS
                  }}
                  setProgress={setProgressUploading}
                  beforeUploadValidation={beforeUploadValidationReport}>
                  <div className="flex items-center">
                    <DocumentIcon className="w-16 h-16"/>
                    <div>
                      <ul>
                        <li className="flex">
                          <span
                            className="font-bold">{t(reportUploaded ? 'todoOverwriteReport' : 'todoUploadReport')}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </InspectionFileUploadForm>
                <div className="flex flex-col p-2 w-fit">
                  <label>
                    <input className="mr-1" type="checkbox" checked={sendEmails} onChange={e => {
                      setSendEmails(e.target.checked)
                    }}/>
                    {t('sendNotificationEmails')}
                  </label>
                </div>
              </div>
            }
          </div>
          <div>
            <ProgressBars itemProgresses={Array.from(uploadingFiles.values())}/>
            <ProgressBars itemProgresses={Array.from(downloadingFiles.values())} progressColor="bg-green-500"/>
          </div>
          {status === 'data_entry' && permissionedToRequestReport &&
            <div className="flex justify-center">
              <div>
                <button onClick={readyForAnalysis ? handleRequestInspectionAnalysis : () => {}}
                        className={`h-10 ml-1 px-3 ${readyForAnalysis ? 'btn' : 'btn-disabled cursor-default'}`}>{t('requestInspectionAnalysis')}</button>
                <RequestAnalysisDialog site={site} turbine={turbine} inspectionImages={inspectionImages}
                                       isDialogOpen={isRequestAnalysisDialogOpen}
                                       cancelAnalysis={requestAnalysisCancel}
                                       startAnalysis={requestInspectionConfirm}/>
              </div>
            </div>
          }
        </div>
      {reportBlobId != null &&
        <div className="flex justify-center">
                    <span
                      className={'m-2 flex items-center cursor-pointer text-blue-700 text-xl font-bold border-2 border-blue-700 rounded-lg p-2 pl-1'}
                      onClick={e => {
                        e.stopPropagation()
                        downloadReport()
                      }}>
                      <DocumentIcon className="h-10"/>{t('downloadReport')}
                    </span>
        </div>
      }
      <div className="overflow-auto">
        <Table tableOptions={tableOptions} t={t} rowEditor={rowEditor}/>
      </div>
      {metadataConfirmResult?.confirm_metadata != null &&
        <MetadataFormatConfirm
          columnMatches={metadataConfirmResult.confirm_metadata.details.matches}
          rows={metadataConfirmResult.confirm_metadata.details.data}
          metadataFilename={metadataConfirmResult.filename}
          onCancel={cancelMetadataConfirm}
          onConfirm={confirmMetadataConfirm}
          setColumnSelections={setMetadataColumnSelections}
        />
      }
      {multiDownloadImages.length > 0 &&
        <ConfirmationDialog
          onCancel={() => {
            setMultiDownloadImages([])
          }}
          onConfirm={() => {
            doDownloadRows(multiDownloadImages)
          }}
          title={t('downloadSelectedImages', { count: multiDownloadImages.length })}
          confirmText={t('download')}
          textHtml={t('downloadMultipleImages_HTML', {
            count: multiDownloadImages.length,
            totalSize: byteSizeToHumanReadable(estimateTotalZipSize(multiDownloadImages), i18n.language)
          })}
        >
        </ConfirmationDialog>
      }
    </div>
}
export default InspectionDetails
