import * as React from 'react'
import { ToastContainer } from 'react-toastify'
import { Outlet, useBlocker } from 'react-router-dom'
import { GlobalStateProvider, useGlobalState } from '../hooks/useGlobalState'
import { PROD } from '../utils/utils'
import ConfirmationDialog from './common/confirmationDialog'
import { useTranslation } from 'react-i18next'

function GlobalUnsavedChangesDialog (): React.ReactNode {
  const { t } = useTranslation()
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges &&
      currentLocation.pathname !== nextLocation.pathname
  )

  const { hasUnsavedChanges } = useGlobalState()
  return blocker.state === 'blocked' &&
        <ConfirmationDialog
          title={t('uncompletedActionsBlockerTitle')}
          textHtml={t('uncompletedActionsBlockerText_HTML')}
          onCancel={() => { blocker.reset() }}
          confirmBtnClass="btn-destructive"
          onConfirm={() => { blocker.proceed() }}
          cancelText={t('uncompletedActionsBlockerStayBtn')}
          confirmText={t('uncompletedActionsBlockerDiscardBtn')}
        />
}

const RootComponent: React.FC = () => {
  return <GlobalStateProvider>
        <GlobalUnsavedChangesDialog />
        <ToastContainer className={PROD ? '' : 'mr-12'}/>
        <div className="h-screen grid grid-rows-[auto,1fr] grid-cols-[auto,1fr]">
            <Outlet/>
        </div>
    </GlobalStateProvider>
}

export default RootComponent
