import { type ReactElement, type ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ConfirmationDialog from '../common/confirmationDialog'
import { Link } from 'react-router-dom'
import * as React from 'react'
import type { Site, Turbine } from '../../graphql/graphql'
import { H5_CONTENT_TYPE, type ParsedInspectionImage } from './inspectionDetails'
import { CheckIcon, ExclamationTriangleIcon, NoSymbolIcon } from '@heroicons/react/24/outline'

interface InspectionInputDataStats extends Record<string, number> {
  h5Count: number
  imageCount: number
  imageUploadCount: number
  imagesWithRecommendedMetadataCount: number
  imagesWithRequiredMetadataCount: number
}

function hasAllRecommendedMetadata (inspectionImage: ParsedInspectionImage): boolean {
  const metadata = inspectionImage.metadata.custom
  return metadata.bladeSide != null && metadata.bladeSide !== '' &&
    ((metadata.centerRootDistance != null && metadata.centerRootDistance > 0) ||
      (metadata.minRootDistance != null && metadata.minRootDistance > 0 &&
        metadata.maxRootDistance != null && metadata.maxRootDistance > 0))
}

const BLADES = new Set<string>(['A', 'B', 'C'])

function hasAllRequiredMetadata (inspectionImage: ParsedInspectionImage): boolean {
  const metadata = inspectionImage.metadata.custom
  const blade = metadata.blade
  return typeof blade === 'string' && BLADES.has(blade)
}

function checkInspectionImageInputData (_: Turbine, imageData: ParsedInspectionImage[]/*, columns */): InspectionInputDataStats {
  const nonH5Images = imageData == null ? [] : imageData.filter(i => i.contentType !== H5_CONTENT_TYPE)
  const h5Images = imageData == null ? [] : imageData.filter(i => i.contentType === H5_CONTENT_TYPE)
  return {
    h5Count: h5Images.length,
    imageCount: nonH5Images.length,
    imageUploadCount: nonH5Images.reduce((agg: number, cur: ParsedInspectionImage) => (cur.byteSize ?? 0) > 0 ? agg + 1 : agg, 0),
    imagesWithRequiredMetadataCount: nonH5Images.filter(hasAllRequiredMetadata).length,
    imagesWithRecommendedMetadataCount: nonH5Images.filter(hasAllRecommendedMetadata).length
  }
}

interface RequestAnalysisDialogProps {
  site: Site
  turbine: Turbine
  inspectionImages: ParsedInspectionImage[]
  isDialogOpen: boolean
  cancelAnalysis: () => void
  startAnalysis: () => Promise<void>
}

const RequestAnalysisDialog = ({
  site, turbine, inspectionImages,
  isDialogOpen, cancelAnalysis, startAnalysis
}: RequestAnalysisDialogProps): ReactElement | undefined => {
  const { t } = useTranslation('inspectionDetailsStartAnalysisDialog')
  if (isDialogOpen) {
    let footNoteCount = 0
    const errCol = 'text-red-600'
    const warnCol = 'text-yellow-500'
    const okayCol = 'text-green-600'

    const check = <span className={okayCol}><CheckIcon className="h-4 stroke-[4px]"/></span>
    const checkOrErr = (errNo: number): ReactElement => errNo === 0
      ? check
      : <span className={`${errCol} font-bold inline-flex items-center`}><NoSymbolIcon className="h-4 stroke-[3px]"/><sup>{errNo}</sup></span>
    const checkOrWarn = (warnNo: number): ReactElement => warnNo === 0
      ? check
      : <span className={`${warnCol} font-bold inline-flex items-center`}><ExclamationTriangleIcon className="h-4 stroke-[3px]"/><sup>{warnNo}</sup></span>

    const stats = checkInspectionImageInputData(turbine, inspectionImages)

    const bladeLengthErrNo = turbine.bladeLength != null && turbine.bladeLength > 0 ? 0 : ++footNoteCount
    const bladeLength = bladeLengthErrNo === 0 ? turbine.bladeLength : t('notSpecified')

    interface LinkIfErrProps {
      children: ReactNode
      errIdx: number
    }
    const turbineLink = (
      <Link
        className="font-bold hover:underline"
        tabIndex={-1}
        title={t('clickHereToEnterMissingInformation')}
        to="/turbines"
        state={{ filter: turbine.id }}
      />)

    const LinkIfErr: React.FC<LinkIfErrProps> = ({ children, errIdx }) => {
      return errIdx > 0
        ? <Link className="hover:underline" title={t('clickHereToEnterMissingInformation')} to="/turbines" state={{ filter: turbine.id }}>{children}</Link>
        : <>{children}</>
    }

    const bladeRootDiameterWarnNo = turbine.bladeRootDiameter != null && turbine.bladeRootDiameter > 0 ? 0 : ++footNoteCount
    const bladeRootDiameter = bladeRootDiameterWarnNo === 0 ? turbine.bladeRootDiameter : t('notSpecified')

    const imageUploadErrNo = stats.imageCount === stats.imageUploadCount ? 0 : ++footNoteCount

    const reqImgMetadataErrNo = stats.imageCount === stats.imagesWithRequiredMetadataCount ? 0 : ++footNoteCount

    const recImgMetadataWarnNo = stats.imageCount === stats.imagesWithRecommendedMetadataCount ? 0 : ++footNoteCount

    const allRequiredGiven = bladeLengthErrNo === 0 && reqImgMetadataErrNo === 0 && imageUploadErrNo === 0
    const allRecommendedGiven = bladeRootDiameterWarnNo === 0 && recImgMetadataWarnNo === 0

    const canProceed = allRequiredGiven
    const allGiven = allRequiredGiven && allRecommendedGiven

    interface TableContentRow {
      k: string
      v: any
      errCat: boolean | null
      errNo: number
      linkComp?: React.FC<LinkIfErrProps>
    }
    const tableContents: TableContentRow[] = [
      { k: 'site', v: site.name, errCat: null, errNo: 0 },
      { k: 'turbine', v: turbine.name, errCat: null, errNo: 0 },
      { k: 'bladeLength', v: bladeLength, errCat: true, errNo: bladeLengthErrNo, linkComp: LinkIfErr },
      { k: 'bladeRootDiameter', v: bladeRootDiameter, errCat: false, errNo: bladeRootDiameterWarnNo, linkComp: LinkIfErr }
    ]
    if (stats.h5Count > 0) {
      tableContents.push({ k: 'h5UploadLabel', v: stats.h5Count, errCat: true, errNo: 0 })
    }
    tableContents.push(
      { k: 'imageUploadLabel', v: t('imageUploadText', stats), errCat: true, errNo: imageUploadErrNo },
      { k: 'requiredImageMetadataLabel', v: t('requiredImageMetadataText', stats), errCat: true, errNo: reqImgMetadataErrNo },
      { k: 'recommendedImageMetadataLabel', v: t('recommendedImageMetadataText', stats), errCat: false, errNo: recImgMetadataWarnNo }
    )

    return <ConfirmationDialog
      className="max-w-prose mx-auto"
      title={t('title')}
      confirmText={t(allGiven || !canProceed ? 'submitAnalysisBtn' : 'submitAnalysisAsIsBtn')}
      confirmTitle={canProceed ? undefined : t('resolveIssues')}
      cancelText={t(allGiven ? 'cancelBtn' : allRequiredGiven ? 'enterRecommendedDataBtn' : 'enterMissingDataBtn')}
      onCancel={cancelAnalysis}
      onConfirm={canProceed ? () => { void startAnalysis() } : undefined }
      confirmBtnClass={canProceed ? 'btn' : 'btn-disabled' }
      cancelBtnClass={canProceed ? 'btn-lowkey' : 'btn'}
      autoFocusOnConfirm={allRecommendedGiven}
    >
      <div>
        <div className="flex justify-center">
          <table className="divide-y divide-gray-200 mt-2">
            <tbody className="bg-white divide-y divide-gray-400">
            {tableContents.map(item => {
              const LinkComp = item.linkComp
              const checkSymbol = item.errCat === true
                ? checkOrErr(item.errNo)
                : item.errCat === false
                  ? checkOrWarn(item.errNo)
                  : null
              return (
                <tr key={item.k}>
                  <td className="px-6 py-3 whitespace-nowrap font-medium text-gray-900">{t(item.k)}</td>
                  <td
                    className={`px-6 py-3 whitespace-nowrap font-bold ${item.errCat == null
                      ? ''
                      : item.errNo === 0
                        ? okayCol
                        : item.errCat
                          ? errCol
                          : warnCol}`}>
                    {LinkComp != null
                      ? <LinkComp errIdx={item.errNo}>{item.v}</LinkComp>
                      : item.v
                    }
                  </td>
                  <td>{checkSymbol}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
        {bladeLengthErrNo > 0 && (
          <p className="mt-2 text-sm text-gray-500">{checkOrErr(bladeLengthErrNo)}&nbsp;
            <Trans ns="inspectionDetailsStartAnalysisDialog" i18nKey="bladeLengthNecessary" components={{ 1: turbineLink }}/>
          </p>
        )}
        {bladeRootDiameterWarnNo > 0 && (
          <p className="mt-2 text-sm text-gray-500">{checkOrWarn(bladeRootDiameterWarnNo)}&nbsp;
            <Trans ns="inspectionDetailsStartAnalysisDialog" i18nKey="bladeRootDiameterUseful" components={{ 1: turbineLink }}/>
          </p>
        )}
        { imageUploadErrNo > 0 && <p className="mt-2 text-sm text-gray-500">{checkOrErr(imageUploadErrNo)}&nbsp;{t('imageUploadNecessary')}</p>}
        { reqImgMetadataErrNo > 0 && <p className="mt-2 text-sm text-gray-500">{checkOrErr(reqImgMetadataErrNo)}&nbsp;{t('requiredImageMetadataNecessary')}</p>}
        { recImgMetadataWarnNo > 0 && <p className="mt-2 text-sm text-gray-500">{checkOrWarn(recImgMetadataWarnNo)}&nbsp;{t('recommendedImageMetadataUseful')}</p>}
      </div>
    </ConfirmationDialog>
  }
}

export default RequestAnalysisDialog
