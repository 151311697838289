import { type Map } from 'maplibre-gl'

/*
airport_gate "{ref}"
airport_label ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
continent "{name:latin}"
country_1 "{name:latin}"
country_2 "{name:latin}"
country_3 "{name:latin}"
country_other "{name:latin}"
highway-junction "Exit {ref}"
highway-shield "{ref}"
highway-shield-us-interstate "{ref}"
highway-shield-us-other "{ref}"
housenumber "{housenumber}"
place_city ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
place_other ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
place_park ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
place_region ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
place_state ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
place_town ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
place_village ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
poi_transit ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
poi_z14 ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
poi_z15 ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
poi_z16 ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
poi_z16_subclass ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
road_ferry_label ["concat",["get","name:latin"]," ",["get","name:nonlatin"]]
road_gondola_label ["concat",["get","name:latin"]," ",["get","name:nonlatin"]]
road_label ["concat",["get","name:latin"]," ",["get","name:nonlatin"]]
road_oneway undefined
road_oneway_opposite undefined
water_name_line ["concat",["get","name:latin"]," ",["get","name:nonlatin"]]
water_name_point ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
water_ocean_name_point ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]

Road ["coalesce",["get","name:en"],["get","name"]]
Place "{name}"
State ["coalesce",["get","name:en"],["get","name"]]
Country "{name:en}"
Continent "{name}"

State ["coalesce",["get","name:en"],["get","name"]]
airport_label ["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]
continent "{name:latin}"
Country "{name:en}"
Continent "{name}"

 */

function categorizeLanguage (lang: string): string {
  switch (lang) {
    case 'ja':
      return 'nonlatin'
    default:
      return 'latin'
  }
}

/**
 * mapbox expression docs: https://docs.mapbox.com/style-spec/reference/expressions/#decision
 */
export const setLanguage = (map: Map, lang: string): void => {
  lang = lang.split('-')[0]
  // console.log("layers order", map.getLayersOrder())
  const langCat = categorizeLanguage(lang)
  if (langCat == null) {
    console.error('Unsupported language ' + lang)
    return
  }
  // console.log("country-text-fields",
  //     node.getLayoutProperty("continent", "text-field"),
  //     node.getLayoutProperty("country_1", "text-field"),
  //     node.getLayoutProperty("country_2", "text-field"),
  //     node.getLayoutProperty("country_3", "text-field"))

  // node.getMap().setLayoutProperty("country_1", 'text-field',
  //     ["concat", ["get", "name:de"], " /1/ ", ["get","name:latin"],"\n",["get","name:nonlatin"]])
  // node.getMap().setLayoutProperty("country_2", 'text-field',
  //     ["concat", ["get", "name:de"], " /2/ ", ["get","name:latin"],"\n",["get","name:nonlatin"]])
  // node.getMap().setLayoutProperty("country_3", 'text-field',
  //     ["concat", ["get", "name:de"], " /3/ ", ["get","name:latin"],"\n",["get","name:nonlatin"]])
  // "coalesce"
  map.getLayersOrder().forEach(layer => {
    // if (!LANGUAGE_LAYERS.has(layer))
    //     return
    // console.log("Now doing layer", layer)
    try {
      const origValue = JSON.stringify(map.getLayoutProperty(layer, 'text-field'))
      let newValue: Array<string | Array<string | string[]>> | null
      switch (origValue) {
        case '["concat",["get","name:latin"]," ",["get","name:nonlatin"]]':
          newValue = langCat === 'latin'
            ? ['concat', ['coalesce', ['get', 'name:' + lang], ['get', 'name:latin']], ' ', ['get', 'name:nonlatin']]
            : ['concat', ['get', 'name:latin'], ' ', ['coalesce', ['get', 'name:lang'], ['get', 'name:nonlatin']]]
          break
        case '["concat",["get","name:latin"],"\n",["get","name:nonlatin"]]':
          newValue = langCat === 'latin'
            ? ['concat', ['coalesce', ['get', 'name:' + lang], ['get', 'name:latin']], '\n', ['get', 'name:nonlatin']]
            : ['concat', ['get', 'name:latin'], '\n', ['coalesce', ['get', 'name:lang'], ['get', 'name:nonlatin']]]
          break
        case '["coalesce",["get","name:en"],["get","name"]]':
          newValue = ['coalesce', ['get', 'name:' + lang], ['get', 'name:en'], ['get', 'name']]
          break
        case '"{name:latin}"':
          newValue = ['coalesce', ['get', 'name:' + lang], ['get', 'name:latin']]
          break
        case '"{name:en}"':
          newValue = ['coalesce', ['get', 'name:' + lang], ['get', 'name:en']]
          break
        case '"{name}"':
          newValue = langCat === 'latin'
            ? ['coalesce', ['get', 'name:' + lang], ['get', 'name:latin'], ['get', 'name']]
            : ['coalesce', ['get', 'name:' + lang], ['get', 'name:nonlatin'], ['get', 'name']]
          break
        default:
          newValue = null
          break
      }
      if (newValue != null) {
        map.setLayoutProperty(layer, 'text-field', newValue)
      }
    } catch (e) {
    }
  })
}
