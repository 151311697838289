import { gql } from '@apollo/client'

export const ENTITY_QUERY = gql`
    query entity($entityType: String!) {
        entity(entityType: $entityType) {
            __typename
            ... on Tenant {
                ...TenantFields
            }
            ... on TenantProperties {
                ...TenantPropertiesFields
            }
            ... on UserProperties {
                ...UserPropertiesFields
            }
            ... on Permission {
                ...PermissionFields
            }
            ... on Site {
                ...SiteFields
            }
            ... on Turbine {
                ...TurbineFields
            }
            ... on Inspection {
                ...InspectionFields
            }
            ... on InspectionImage {
                ...InspectionImageFields
            }
        }
    }
`

export const UPSERT_SITE_MUTATION = gql`
    mutation UpsertSite($id: String, $name: String!, $address: String, $city: String, $state: String,
        $postalCode: String, $region: String, $country: String!, $timezone: String!) {
        upsertSite(input: {
            id: $id,
            name: $name,
            address: $address
            city: $city
            state: $state
            postalCode: $postalCode
            region: $region
            country: $country,
            timezone: $timezone,
        }) {
            site {
                id
            }
            errors
        }
    }
`

export const UPSERT_INSPECTION_MUTATION = gql`
    mutation UpsertInspection($id: String, $turbineId: String!, $plannedDate: ISO8601Date, $actualDate: ISO8601Date,
        $inspectionMethod: String!, $inspector: String, $cameraModel: String,
        $inspectionFirm: String,
    ) {
        upsertInspection(input: {
            id: $id,
            turbineId: $turbineId,
            plannedDate: $plannedDate,
            actualDate: $actualDate,
            inspectionMethod: $inspectionMethod,
            inspector: $inspector,
            cameraModel: $cameraModel,
            inspectionFirm: $inspectionFirm,
        }) {
            inspection {
                id
            }
            errors
        }
    }
`

export const DELETE_INSPECTIONS_MUTATION = gql`
    mutation DeleteInspections($ids: [String!]!) {
        deleteInspections(input: {
            ids: $ids
        }) {
            results {
                id
                error {
                    type
                    i18nOpts
                }
            }
        }
    }
`

export const UPDATE_INSPECTION_IMAGE_MUTATION = gql`
    mutation UpdateInspectionImage($id: String!, $customMetadata: String!,
    ) {
            updateInspectionImage(input: {
            id: $id,
            customMetadata: $customMetadata,
        }) {
            inspectionImage {
                id
            }
            errors
        }
    }
`

export const DELETE_INSPECTION_IMAGES_MUTATION = gql`
    mutation DeleteInspectionImages($ids: [String!]!) {
        deleteInspectionImages(input: {
            ids: $ids
        }) {
            results {
                id
                error {
                    type
                    i18nOpts
                }
            }
        }
    }
`

export const DELETE_SITES_MUTATION = gql`
    mutation DeleteSites($ids: [String!]!) {
        deleteSites(input: {
            ids: $ids
        }) {
            results {
                id
                error {
                    type
                    i18nOpts
                }
            }
        }
    }
`

export const INSPECTION_IMAGES_QUERY = gql`
    query InspectionImagesQuery($inspectionId: ID!) {
        inspectionImages(inspectionId: $inspectionId) {
            ...InspectionImageFields
        }
    }
`

export const UPSERT_TURBINE_MUTATION = gql`
    mutation UpsertTurbine($id: String, $name: String!, $siteId: String!, $serialNo: String, $model: String, $bladeModel: String,
        $bladeLength: Float, $latitude: Float, $longitude: Float, $codDate: ISO8601Date, $eowDate: ISO8601Date,
        $hubHeight: Float, $capacity: Float, $maker: String, $statusOnline: Boolean, $bladeRootDiameter: Float) {
        upsertTurbine(input: {
            id: $id,
            name: $name,
            siteId: $siteId,
            serialNo: $serialNo,
            model: $model,
            bladeModel: $bladeModel,
            bladeLength: $bladeLength,
            latitude: $latitude,
            longitude: $longitude
            codDate: $codDate
            eowDate: $eowDate
            hubHeight: $hubHeight
            capacity: $capacity
            maker: $maker
            statusOnline: $statusOnline
            bladeRootDiameter: $bladeRootDiameter
        }) {
            turbine {
                id
            }
            errors
        }
    }
`

export const DELETE_TURBINES_MUTATION = gql`
    mutation DeleteTurbines($ids: [String!]!) {
        deleteTurbines(input: {
            ids: $ids
        }) {
            results {
                id
                error {
                    type
                    i18nOpts
                }
            }
        }
    }
`

export const REQUEST_INSPECTION_REPORT = gql`
    mutation RequestInspectionAnalysis($inspectionId: String!) {
        requestInspectionAnalysis(input: {
            inspectionId: $inspectionId,
        }) {
            errors
        }
    }
`
