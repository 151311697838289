import { flexRender, type Row } from '@tanstack/react-table'
import * as React from 'react'
import { memo, type MouseEventHandler } from 'react'
import FocusTrap from 'focus-trap-react'
import EditCell from './editCell'
import { type useTranslation } from 'react-i18next'
import { type ColumnMeta, type RecordWithId } from './table'
import TableCell from './tableCell'
import { isEqual } from 'lodash'

interface TableRowProps<RowData extends RecordWithId> {
  row: Row<RowData>
  rowIndex: number
  rowClickHandler?: MouseEventHandler
  hasEditColumn: boolean
  isEditable: boolean
  areRowsDeletable: boolean
  isEditing: boolean
  enableRowSelection: boolean
  isSelected: boolean
  isPersisting: boolean
  isRecentlyChanged: boolean
  t: ReturnType<typeof useTranslation>['t']
  bgOverride?: string
  abortEdit?: (row: RowData) => void
  startEdit?: (row: RowData) => void
  canDelete?: (row: RowData) => boolean
  deleteRow?: (row: RowData) => void
  saveEdit?: (row: RowData) => Promise<void>
}

const TableRowUnmemoized = <RowData extends RecordWithId>({
  row, rowIndex, rowClickHandler = undefined, hasEditColumn, isEditable, areRowsDeletable, isEditing, enableRowSelection,
  isSelected, isPersisting, isRecentlyChanged, t, bgOverride = undefined, abortEdit = undefined, startEdit = undefined,
  canDelete = undefined, deleteRow = undefined, saveEdit = undefined
}: TableRowProps<RowData>): React.JSX.Element => {
  const isNew = row.id === 'new'
  let hasSeenEditableCell = false
  const isRowEven = rowIndex % 2 === 0
  const editCol = hasEditColumn &&
    <td className="sticky left-0 bg-white z-10">
      <EditCell row={row} t={t}
                isEditable={isEditable} isDeletable={areRowsDeletable} isEditing={isEditing}
                abortEdit={abortEdit} startEdit={startEdit} canDelete={canDelete} deleteRow={deleteRow}
                saveEdit={saveEdit} enableRowSelection={enableRowSelection} isSelected={isSelected}
      />
    </td>

  const tr =
        <tr id={row.id}
            onClick={isEditing ? () => {} : rowClickHandler}
            className={`scroll-mt-8 scroll-ml-4 scroll-mb-2 transition-opacity border-b ${isRecentlyChanged
                ? 'flashing-green'
                : isPersisting
                    ? 'flashing-yellow'
                    : isNew
                        ? 'bg-yellow-100'
                        : bgOverride ?? 'bg-white even:bg-gray-100 hover:bg-gray-200'}
                     ${isEditing || rowClickHandler == null
                ? ''
                : 'cursor-pointer'}`}
        >
            {editCol}
            {row.getVisibleCells().map(cell => {
              const colDef = cell.column.columnDef
              const colId = cell.column.id
              const columnMeta = colDef.meta as ColumnMeta<RowData>
              const colSpan = isEditing && columnMeta?.editColSpan !== undefined ? columnMeta.editColSpan : 1
              let autoFocus = false
              if (isEditing) {
                if (isEditing && !hasSeenEditableCell &&
                    colDef.cell === TableCell &&
                    columnMeta?.readonly !== true &&
                    colSpan > 0) {
                  autoFocus = true
                  hasSeenEditableCell = true
                }
              }
              const getRequiredFulfilled = (): boolean => {
                return columnMeta?.validate != null
                  ? columnMeta.validate(row, colId) === true
                  : (cell.getValue() != null && cell.getValue() !== '')
              }
              const req = columnMeta?.required
              const requiredUnfulfilled = req != null &&
                  !getRequiredFulfilled()
                ? (typeof req === 'string' ? req : req(row, colId))
                : null
              const cellBgOverride = requiredUnfulfilled == null
                ? null
                : requiredUnfulfilled === 'required'
                  ? (isRowEven ? 'bg-red-200' : 'bg-red-100')
                  : (isRowEven ? 'bg-amber-100' : 'bg-amber-50')
              const accessorKey: string = (colDef as any).accessorKey
              const title = requiredUnfulfilled == null
                ? undefined
                : columnMeta?.requiredText ?? (requiredUnfulfilled === 'required'
                  ? t('fieldIsRequired', { field: t(accessorKey) })
                  : t('fieldIsRecommended', { field: t(accessorKey) }))
              return colSpan === 0
                ? null
                : (
                    <td key={cell.id} colSpan={colSpan} className={`px-1 ${cellBgOverride}`} title={title}>
                        {flexRender(colDef.cell, { ...cell.getContext(), autoFocus })}
                    </td>
                  )
            })}
        </tr>
  return (isNew || isEditing) ? <FocusTrap focusTrapOptions={{ allowOutsideClick: true, escapeDeactivates: false }}>{tr}</FocusTrap> : tr
}

const TableRow = memo(TableRowUnmemoized, isEqual) as typeof TableRowUnmemoized

export default TableRow
