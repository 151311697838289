import * as React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  type SortingState,
  type ColumnFiltersState,
  getFilteredRowModel,
  type TableOptions
} from '@tanstack/react-table'
import { type Inspection, type Site, type Turbine } from '../../graphql/graphql'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Table, { type ColumnMeta, type Option, type TableMeta } from '../table/table'
import TableCell from '../table/tableCell'
import { useTranslation } from 'react-i18next'
import { byteSizeToHumanReadable, shortId, stopPropagation } from '../../utils/utils'
import {
  DELETE_INSPECTIONS_MUTATION,
  UPSERT_INSPECTION_MUTATION
} from '../../graphql/queriesAndMutations'
import {
  CursorArrowRaysIcon,
  DocumentIcon as DocumentIconOutline,
  FolderArrowDownIcon
} from '@heroicons/react/24/outline'
import { buildReportFilename, downloadSingle } from '../../utils/download'
import { useLocalizedToast } from '../../hooks/useLocalizedToast'
import type { Row } from '@tanstack/table-core/build/lib/types'
import useForceReLogin from '../../hooks/useForceReLogin'
import useEntityMutation from '../../hooks/useEntityMutation'
import { useApolloClient } from '@apollo/client'
import useEntityQuery from '../../hooks/useEntityQuery'
import { getCachedObject } from '../apolloClientManager'
import useDeleteEntitiesMutation from '../../hooks/useDeleteEntitiesMutation'
import { distinctColValues } from '../table/tableUtils'
import { useGlobalState } from '../../hooks/useGlobalState'
import useStateMap from '../../hooks/useStateMap'
import ProgressBars from '../common/progressBars'
import { type ProgressBarProps } from '../common/progressBar'
import useDocumentTitle from '../../hooks/useDocumentTitle'

type InspectionPlus = Inspection & {
  siteId: string
  siteName: string
  turbineName: string
}
const columnHelper = createColumnHelper<InspectionPlus>()

function createNewItem (): InspectionPlus {
  // @ts-expect-error TS2322: We cannot set a non-null turbine here yet, which is required by the model
  return (
    {
      id: 'new',
      inspector: null,
      inspectionFirm: null,
      inspectionMethod: 'autonomous_drone',
      plannedDate: null,
      actualDate: null,
      cameraModel: null,
      updatedAt: '',
      status: 'data_entry'
    })
}

const InspectionTable: React.FC = () => {
  const { t, i18n } = useTranslation('inspectionTable')
  useDocumentTitle(t('inspections'))
  const sites = useEntityQuery<Site>('Site', { suspense: true })
  const turbines = useEntityQuery<Turbine>('Turbine', { suspense: true })
  const inspections = useEntityQuery<Inspection>('Inspection', { suspense: true })

  const location = useLocation()
  const { tenantId, authData, setHasUnsavedChanges } = useGlobalState()
  const forceReLogin = useForceReLogin()

  const client = useApolloClient()

  const getSite = (siteId: string): Site => getCachedObject<Site>(client, 'Site', siteId)
  const getTurbine = (turbineId: string): Turbine => getCachedObject<Turbine>(client, 'Turbine', turbineId)
  const inspectionsPlus = useMemo(() => {
    return inspections?.map(i => {
      const turbine = getTurbine(i.turbineId)
      const turbineName = turbine?.name
      const siteId = turbine?.siteId
      const siteName = getSite(siteId)?.name
      return ({ ...i, siteId, siteName, turbineName } satisfies InspectionPlus)
    })
  }, [sites, turbines, inspections])

  const showToast = useLocalizedToast()

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'siteId', desc: false },
    { id: 'turbineId', desc: false }
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    // {id: "name", value: "h" }
  ])

  const saveEdit = useEntityMutation(UPSERT_INSPECTION_MUTATION, r => r.data.upsertInspection.errors, r => r.data.upsertInspection.inspection)
  const deleteRows = useDeleteEntitiesMutation(DELETE_INSPECTIONS_MUTATION, r => r.data.deleteInspections.results)

  const readonlyUnlessInDataEntryStatus = (row: Row<InspectionPlus>, _: string): boolean => {
    return row.original.status != null && // in the case of new entries, status will be null, meaning 'data_entry'
        row.original.status !== 'data_entry'
  }
  const { map: downloadingFiles, setOrDelete: setProgress } = useStateMap<string, ProgressBarProps>()
  useEffect(() => {
    setHasUnsavedChanges(downloadingFiles.size > 0)
  }, [downloadingFiles])

  const meta: (m: ColumnMeta<InspectionPlus>) => ColumnMeta<InspectionPlus> = m => m
  const autoCompleteMeta = meta({ autoCompleteList: distinctColValues })

  const columns = [
    columnHelper.accessor('id', {
      header: 'id',
      cell: TableCell,
      meta: {
        readonly: true,
        render: (id: string) => <span title={id}>{shortId(id)}</span>
      } satisfies ColumnMeta<InspectionPlus>
    }),
    columnHelper.accessor('reportBlobId', {
      header: () => <FolderArrowDownIcon title={t('downloadReport')} className="h-6"/>,
      enableSorting: false,
      cell: info => {
        const blobId = info.getValue()
        return typeof blobId !== 'string'
          ? <span title={t('reportNotYetAvailable')} onClick={stopPropagation}>
              <DocumentIconOutline className="h-6 opacity-0 cursor-default"/>
            </span>
          : <span title={t('downloadReport')} onClick={e => {
            e.stopPropagation()
            const inspection = info.row.original
            if (authData == null || tenantId == null) {
              forceReLogin()
              return
            }
            const filename = buildReportFilename(inspection.id, String(inspection.updatedAt))
            downloadSingle(blobId, authData, tenantId, { save: true, filename, setProgress })
              .catch((error) => {
                showToast('error', () => t('errorRequestingFile', { filename, msg: JSON.stringify(error) }))
              })
          }}>
              <DocumentIconOutline className="h-6"/>
            </span>
      }
    }),
    columnHelper.accessor('siteId', {
      header: 'siteId',
      cell: TableCell,
      meta: {
        required: 'required',
        readonly: readonlyUnlessInDataEntryStatus,
        filterValues: (siteId: string): string[] => [siteId, getSite(siteId)?.name ?? ''],
        render: (siteId: string) => getSite(siteId)?.name,
        options: sites.map((site) => ({
          value: site.id,
          label: site.name
        }))
      } satisfies ColumnMeta<InspectionPlus>
    }),
    columnHelper.accessor('turbineId', {
      header: 'turbineId',
      cell: TableCell,
      meta: {
        required: 'required',
        readonly: readonlyUnlessInDataEntryStatus,
        filterValues: (turbineId: string): string[] => [turbineId, getTurbine(turbineId)?.name ?? ''],
        render: (turbineId: string) => getTurbine(turbineId)?.name ?? '',
        options: (row: Row<InspectionPlus>, _: string): Option[] => {
          const selectedSite = getSite(row.original.siteId)
          const turbs = selectedSite == null || turbines == null ? null : turbines.filter(t => t.siteId === selectedSite.id)
          if (turbs == null || turbs.length === 0) {
            return []
          } else {
            return turbs.map((turbine) => (
              {
                value: turbine.id,
                label: turbine.name
              })) as Option[]
          }
        }
      } satisfies ColumnMeta<InspectionPlus>
    }),
    columnHelper.accessor('inspectionImageCount', {
      id: 'inspectionDetails',
      header: 'inspectionDetails',
      cell: info => (
        <Link tabIndex={-1} to={`/inspections/${info.row.id}`}>
          <div className="inline-flex items-center">
            <CursorArrowRaysIcon className="h-6 text-blue-700" />
            {(info.getValue() == null || info.getValue() === 0) ? '0' : info.getValue() + ' (' + byteSizeToHumanReadable(info.row.original.inspectionImagesByteSize, i18n.language) + ')'}
          </div>
        </Link>
      )
    }),
    columnHelper.accessor('status', {
      header: 'status',
      cell: TableCell,
      meta: {
        readonly: true,
        translate: true
      } satisfies ColumnMeta<InspectionPlus>
    }),
    columnHelper.accessor('inspectionMethod', {
      header: 'inspectionMethod',
      cell: TableCell,
      meta: {
        translate: true,
        required: 'required',
        readonly: readonlyUnlessInDataEntryStatus,
        options: [
          { value: 'autonomous_drone' },
          { value: 'manual_drone' },
          { value: 'ground_based' }
        ]
      } satisfies ColumnMeta<InspectionPlus>
    }),
    // columnHelper.accessor('plannedDate', {
    //   header: 'plannedDate',
    //   cell: TableCell,
    //   meta: { type: 'date' } satisfies ColumnMeta<InspectionPlus>
    // }),
    columnHelper.accessor('actualDate', {
      header: 'actualDate',
      cell: TableCell,
      meta: { type: 'date' } satisfies ColumnMeta<InspectionPlus>
    }),
    columnHelper.accessor('inspector', { header: 'inspector', cell: TableCell, meta: autoCompleteMeta }),
    columnHelper.accessor('inspectionFirm', { header: 'inspectionFirm', cell: TableCell, meta: autoCompleteMeta }),
    columnHelper.accessor('cameraModel', { header: 'cameraModel', cell: TableCell, meta: autoCompleteMeta }),
    columnHelper.accessor('updatedAt', { header: 'updatedAt', cell: TableCell, meta: { type: 'datetime', readonly: true } })
  ]
  const tableMeta: TableMeta<InspectionPlus> = {
    t,
    i18n,
    canDelete: (inspection: InspectionPlus) => inspection.status !== 'in_progress',
    enableMultiDelete: true,
    deleteRows,
    saveEdit,
    createNewItem
  }
  const tableOptions: TableOptions<InspectionPlus> = {
    data: inspectionsPlus,
    columns,
    getRowId: row => row.id,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter: _ => true,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnFilters
    },
    meta: tableMeta
  }

  return (
    <>
      <ProgressBars itemProgresses={Array.from(downloadingFiles.values())} progressColor="bg-green-500"/>
      <Table
        tableOptions={tableOptions}
        t={t}
        initialFilter={location.state?.filter}
        canCreateNewRow={turbines != null && turbines.length > 0}
        createNewRowTitle={turbines != null && turbines.length > 0 ? undefined : t('createTurbineFirst')}
      />
    </>)
}

export default InspectionTable
