import { useState } from 'react'

export interface StateMap<K, V> {
  map: Map<K, V>
  setOrDelete: (k: K, v: V | null) => void
}

export default function useStateMap<K, V> (): StateMap<K, V> {
  const [map, setMap] = useState<Map<K, V>>(new Map<K, V>())
  const setOrDelete = (k: K, v: V | null): void => {
    setMap(prevState => {
      if (v == null) {
        if (!prevState.has(k)) { return prevState }
        const newMap = new Map([...prevState])
        newMap.delete(k)
        return newMap
      } else {
        if (prevState.get(k) === v) { return prevState }
        const newMap = new Map([...prevState])
        newMap.set(k, v)
        return newMap
      }
    })
  }
  return { map, setOrDelete }
}
