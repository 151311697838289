import { createRoot } from 'react-dom/client'
import App from '../App'
import * as React from 'react'
import i18n from '../i18n/i18n'
import '../styles/application.css'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { PROD } from '../utils/utils'

if (!PROD) {
  loadDevMessages() // messages for errors that are raised only in a development environment.
}
loadErrorMessages() // messages for errors that are raised in all environments

const domNode = (document).getElementById('root')
if (domNode == null) { throw new Error('root not found') }
const root = createRoot(domNode)

void i18n.init().then(() => {
  root.render(<App/>)
})
