import { type ReactNode } from 'react'
import { toast, type Id, Flip, type ToastContent, type ToastOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const defaultOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
  progress: undefined,
  transition: Flip
}

export const defaultOptionsMap: Record<string, ToastOptions> = {
  error: { ...defaultOptions, autoClose: false },
  success: { ...defaultOptions, autoClose: 5000 }
}

const getToasterByCategory = (category: string): (<TData = unknown>(content: ToastContent<TData>, options?: (ToastOptions | undefined)) => Id) | null => {
  switch (category) {
    case 'loading': return toast.loading
    case 'success': return toast.success
    case 'info': return toast.info
    case 'error': return toast.error
    case 'warning': return toast.warning
    case 'warn': return toast.warn
    default: return null
  }
}

export const showToast = (category: string, message: ReactNode, options = {}): Id | null => {
  const toaster = getToasterByCategory(category)
  if (toaster == null) {
    console.error('toaster for category ' + category + ' not found')
    return null
  } else {
    // const xy: (<TData = unknown>(content: ToastContent<TData>, options?: (ToastOptions<{}> | undefined)) => Id) | undefined = toast['error']
    return toaster(message, { ...(defaultOptionsMap[category] ?? defaultOptions), ...options })
  }
}

export const success = (message: ReactNode, options = {}): Id => {
  return toast.success(message, { ...(defaultOptionsMap.success ?? defaultOptions), ...options })
}

export const info = (message: ReactNode, options = {}): Id => {
  return toast.info(message, { ...(defaultOptionsMap.info ?? defaultOptions), ...options })
}

export const warn = (message: ReactNode, options = {}): Id => {
  return toast.warn(message, { ...(defaultOptionsMap.warn ?? defaultOptions), ...options })
}

export const error = (message: ReactNode, options = {}): Id => {
  return toast.error(message, { ...(defaultOptionsMap.error ?? defaultOptions), ...options })
}
