import React, { type KeyboardEvent, useRef, useEffect, type ReactNode } from 'react'

interface CustomSelectProps extends React.HTMLProps<HTMLSelectElement> {
  children: ReactNode
}

/**
 * The sole purpose of this custom select is to stop propagation of the Escape key event up to the table level,
 * where it would cause the editing to be aborted.
 *
 * This has a downside: I actually would like the event to bubble up if the select is closed, but detecting whether it
 * is open or not does not seem possible reliably. So now, you cannot abort editing when on a CustomSelect - open or not.
 */
const CustomSelect: React.FC<CustomSelectProps> = ({ children, ...restProps }) => {
  const selectRef = useRef<HTMLSelectElement | null>(null)

  const handleKeyMotion = (event: KeyboardEvent<HTMLSelectElement>): void => {
    if (event.key === 'Escape') {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    const currentSelect = selectRef.current
    if (currentSelect != null) {
      currentSelect.addEventListener('keyup', handleKeyMotion as unknown as EventListener)
      currentSelect.addEventListener('keydown', handleKeyMotion as unknown as EventListener)
    }
    return () => {
      if (currentSelect != null) {
        currentSelect.removeEventListener('keydown', handleKeyMotion as unknown as EventListener)
        currentSelect.removeEventListener('keyup', handleKeyMotion as unknown as EventListener)
      }
    }
  }, [])

  return (
      <select ref={selectRef} onKeyDown={handleKeyMotion} {...restProps}>
        {children}
      </select>
  )
}

export default CustomSelect
