import * as React from 'react'
import { useCallback } from 'react'
import { type DropzoneOptions, useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getAuthHeaders } from '../../utils/auth'
import { type UploadResponse, type BeforeUploadValidationResult } from './inspectionDetails'
import { upload, getMeta } from '../../utils/utils'
import useForceReLogin from '../../hooks/useForceReLogin'
import { useGlobalState } from '../../hooks/useGlobalState'
import type { ProgressBarProps } from '../common/progressBar'
import { throttle } from 'lodash'

interface InspectionFileUploadFormProps extends React.ComponentProps<any> {
  children?: React.ReactNode
  onUploadResult: (response: UploadResponse | null, error: any) => void
  beforeUploadValidation?: (file: File | null) => BeforeUploadValidationResult | null
  dropZoneOpts?: DropzoneOptions
  setProgress?: (filename: string, v: ProgressBarProps | null) => void
  dragRejectClass?: string
}

const InspectionFileUploadForm: React.FC<InspectionFileUploadFormProps> =
    ({
      children = undefined, onUploadResult, beforeUploadValidation = undefined,
      dropZoneOpts = null, setProgress = undefined, dragRejectClass = 'bg-red-400',
      className,
      ...restProps
    }) => {
      const { t } = useTranslation('inspectionDetails')
      const forceRelogin = useForceReLogin()
      const { id } = useParams()
      const { tenantId, authData } = useGlobalState()
      const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
          if (beforeUploadValidation != null) {
            beforeUploadValidation(null)
          }
          return
        }
        acceptedFiles.forEach(file => {
          uploadFile(file)
        })
      }, [id])

      const uploadFile = (file: File): void => {
        if (authData == null || tenantId == null) {
          forceRelogin()
          return
        }
        const formData = new FormData()
        const {
          response: validationResponse,
          error: validationError,
          additionalFormData
        } = (beforeUploadValidation?.(file)) ?? {}
        if (validationResponse !== undefined || validationError !== undefined) {
          onUploadResult(validationResponse ?? null, validationError)
          return
        } else {
          setProgress?.(file.name, { label: file.name, progress: 0 })
          if (additionalFormData != null) {
            for (const [k, v] of Object.entries(additionalFormData)) {
              formData.append(`inspection[${k}]`, v)
            }
          }
          formData.append('inspection[file]', file)
        }

        const throttledSetProgress = throttle((progress: number | null) => {
          setProgress?.(file.name, progress == null ? null : { label: file.name, progress })
        }, 100)

        const updateFileProgress = (progress: number): void => {
          throttledSetProgress(progress)
          if (progress >= 100) {
            setTimeout(() => {
              throttledSetProgress(null)
            }, 500)
          }
        }

        upload(getMeta('urlBase') + `/inspections/${id}`,
          'PATCH',
          formData,
          getAuthHeaders(authData, { tid: tenantId }),
          progress => { updateFileProgress(progress) }
        )
          .then((data) => {
            onUploadResult(data, null)
          })
          .catch((error) => {
            onUploadResult(null, error)
          })
      }

      const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({ ...dropZoneOpts, onDrop })
      const mergedClassName = `p-1 flex flex-col items-center justify-center ${isDragReject
            ? dragRejectClass
            : isDragAccept
              ? 'bg-green-400'
              : 'bg-amber-400'
        } cursor-pointer rounded ${className}`

      return (
            <button {...getRootProps()} {...restProps} className={mergedClassName}>
                <input {...getInputProps()} />
                <p className="text-center">{t('dragNDrop', { count: dropZoneOpts?.maxFiles === 1 ? 1 : 999 })}</p>
                {children}
            </button>
      )
    }

export default InspectionFileUploadForm
