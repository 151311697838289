import { type Option } from './table'
import { type Row, type Table as TanTable } from '@tanstack/react-table'
import { isEqual } from 'lodash'

export function distinctColValues<RowData> (table: TanTable<RowData>, columnId: string, row: Row<RowData>): Set<string> {
  const distinctValues: string[] = table.getCoreRowModel().rows
    .filter(r => r.id !== row.id) // do not include the row in question itself
    .map(r => r.getValue(columnId))
    .filter(r => r != null)
    .map(String)
    .filter(r => r !== '')
  distinctValues.sort()
  return new Set<string>(distinctValues)
}

function extractUniqueKey<RowData> (table: TanTable<RowData>, columnId: string, row: Row<RowData>, uniqueKeyExtractor: true | ((table: TanTable<RowData>, columnId: string, row: Row<RowData>) => any)): any {
  return uniqueKeyExtractor === true ? row.getValue(columnId) : uniqueKeyExtractor(table, columnId, row)
}

export function valueExists<RowData> (table: TanTable<RowData>, columnId: string, row: Row<RowData>,
  uniqueKeyExtractor: true | ((table: TanTable<RowData>, columnId: string, row: Row<RowData>) => any)): boolean {
  const val = uniqueKeyExtractor === true ? row.getValue(columnId) : uniqueKeyExtractor(table, columnId, row)
  return table.getCoreRowModel().rows
    .filter(r => r.id !== row.id) // do not include the row in question itself
    .some(r => isEqual(val, extractUniqueKey(table, columnId, r, uniqueKeyExtractor)))
}

export const optionsFromValues: (values: any[]) => Option[] = values => values.map(s => ({ value: s }))
