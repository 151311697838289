import React, { type SetStateAction, useCallback, useEffect, useState } from 'react'
import { rnd, getMeta, setMeta } from '../utils/utils'
import { useGlobalState } from '../hooks/useGlobalState'
import { useTranslation } from 'react-i18next'
import { type ConnectionEstablishedEvent, type RefreshEvent } from '../types/cableEvents'
import { useActionCable } from '../hooks/useActionCable'

function handleRefreshAction (reauthRequired: boolean, reloadRequired: boolean, refreshMaxMillis: number, setPendingAction: React.Dispatch<SetStateAction<Action | undefined>>): void {
  const delay = rnd(refreshMaxMillis)
  const action = { reauth: reauthRequired, reload: reloadRequired }
  console.log(`Performing action ${JSON.stringify(action)} in ${delay} ms.`)
  setTimeout(() => { setPendingAction(action) }, delay)
}

function handleConnectionEstablished (msg: ConnectionEstablishedEvent, setPendingAction: React.Dispatch<SetStateAction<Action | undefined>>): void {
  const serverVersion = msg.version
  const ourVersion = getMeta('appVersion')
  const serverAuthVersion = msg.authVersion
  const ourAuthVersion = getMeta('authVersion')
  console.log(`Server id: ${msg.bladeRunnerServerId} / ${getMeta('bladeRunnerServerId')}`)
  console.log(`Server version: ${serverVersion} / ${ourVersion}`)
  console.log(`Server auth version: ${serverAuthVersion} / ${ourAuthVersion}`)
  if (ourVersion !== serverVersion || ourAuthVersion !== serverAuthVersion) {
    setMeta('authVersion', serverAuthVersion)
    handleRefreshAction(ourAuthVersion !== serverAuthVersion, ourVersion !== serverVersion, msg.refreshMaxMillis, setPendingAction)
  }
}

const handleMessage = (data: any, setPendingAction: React.Dispatch<SetStateAction<Action | undefined>>): void => {
  const eventType: string = data?.event as string
  switch (eventType) {
    case 'connectionEstablished': {
      handleConnectionEstablished(data as ConnectionEstablishedEvent, setPendingAction)
      break
    }
    case 'refreshRequired': {
      console.log('Received "refreshRequired" message', data)
      const refreshEvent = data as RefreshEvent
      handleRefreshAction(refreshEvent.reauth, refreshEvent.reload, refreshEvent.refreshMaxMillis, setPendingAction)
      break
    }
  }
}

interface Action {
  reauth: boolean
  reload: boolean
}

const ApplicationCable: React.FC = () => {
  const { t } = useTranslation()
  const { hasUnsavedChanges, onLogout } = useGlobalState()
  const [pendingAction, setPendingAction] = useState<Action>()
  const [willAct, setWillAct] = useState<boolean>(false)

  useEffect(() => {
    if (!hasUnsavedChanges && pendingAction != null) {
      setWillAct(true)
      setTimeout(() => {
        if (pendingAction.reauth) {
          void onLogout()
        }
        if (pendingAction.reload) {
          // due to "Cache-Control: max-age=0, private, must-revalidate", the following will make the browser re-request
          // the page from the server, rather than using the cached version, even without using the 'true' argument,
          // which is not standard (it may work on Firefox only)
          window.location.reload()
        }
      }, 3000)
    }
  }, [hasUnsavedChanges, pendingAction])

  const onReceived = useCallback((_channel: string, data: any): void => {
    handleMessage(data, setPendingAction)
  }, [])
  const { subscribe, unsubscribe } = useActionCable()
  useEffect(() => {
    subscribe('AppChannel', onReceived)
    return () => {
      unsubscribe('AppChannel', onReceived)
    }
  }, [])

  const msgI18nKey = pendingAction?.reauth === true
    ? 'refreshAction_reauth_HTML'
    : pendingAction?.reload === true
      ? 'refreshAction_reload_HTML'
      : ''

  return (
    <>
      {willAct &&
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg z-50 max-w-screen-md text-center">
                <p dangerouslySetInnerHTML={{ __html: t(msgI18nKey) }}></p>
              </div>
            </div>
      }
    </>)
}

export default ApplicationCable
