import type { Row } from '@tanstack/react-table'
import type { ColumnMeta, RecordWithId } from './table'

type SimpleValidator<RowData> = (row: Row<RowData>, colId: string) => boolean | undefined

type ValidateNumberProps<RowData extends RecordWithId> = ColumnMeta<RowData> & {
  integer?: true
}

export function validateLatitude<RowData extends RecordWithId> (props: ColumnMeta<RowData> = {}): ColumnMeta<RowData> {
  const augmentedProps: ColumnMeta<RowData> = { ...props, type: 'latitude', min: -90, max: 90 }
  return { ...augmentedProps, validate: numberValidator(augmentedProps) }
}

export function validateLongitude<RowData extends RecordWithId> (props: ColumnMeta<RowData> = {}): ColumnMeta<RowData> {
  const augmentedProps: ColumnMeta<RowData> = { ...props, type: 'longitude', min: -180, max: 180 }
  return { ...augmentedProps, validate: numberValidator(augmentedProps) }
}

export function validateNumber<RowData extends RecordWithId> (props: ValidateNumberProps<RowData> = {}): ColumnMeta<RowData> {
  const augmentedProps: ColumnMeta<RowData> = { type: 'number', ...props }
  return { ...augmentedProps, validate: numberValidator(augmentedProps) }
}

export function validatePositiveNumber<RowData extends RecordWithId> (props: ValidateNumberProps<RowData> = {}): ColumnMeta<RowData> {
  const augmentedProps: ColumnMeta<RowData> = { min: Number.MIN_VALUE, type: 'number', ...props }
  return { ...augmentedProps, validate: numberValidator(augmentedProps) }
}

function getValue<RowData> (row: Row<RowData>, colId: string): any {
  return row != null && colId != null ? row.getValue(colId) : null
}

export const setToValueOtherThanThis: (otherThanThis: any) => SimpleValidator<any> = otherThanThis => {
  return (row: Row<any>, colId: string): boolean => {
    const v = getValue(row, colId)
    return v != null && v !== otherThanThis
  }
}

const numberValidator: <RowData extends RecordWithId>(props: ValidateNumberProps<RowData>) => SimpleValidator<RowData> = props => {
  return (row, colId) => {
    const v = getValue(row, colId)
    if (v == null || v === '') { return undefined }
    const vNum = Number(v)
    return (props.integer !== true || Number.isInteger(vNum)) &&
      (props.min == null || vNum >= props.min) &&
      (props.max == null || vNum <= props.max)
  }
}
