import * as React from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  type SortingState,
  type ColumnFiltersState,
  getFilteredRowModel, type TableOptions, type Row
} from '@tanstack/react-table'
import { type Inspection, type Site, type Turbine } from '../graphql/graphql'
import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Table, { type ColumnMeta, type TableMeta } from './table/table'
import { useTranslation } from 'react-i18next'
import TableCell from './table/tableCell'
import { addAll, shortId } from '../utils/utils'
import { DELETE_TURBINES_MUTATION, UPSERT_TURBINE_MUTATION } from '../graphql/queriesAndMutations'
import useEntityMutation from '../hooks/useEntityMutation'
import { useApolloClient } from '@apollo/client'
import useEntityQuery from '../hooks/useEntityQuery'
import { getCachedObject } from './apolloClientManager'
import useDeleteEntitiesMutation from '../hooks/useDeleteEntitiesMutation'
import { validateLatitude, validateLongitude, validateNumber } from './table/validations'
import {
  KNOWN_TURBINE_MAKERS,
  MAX_BLADE_LENGTH_M,
  MAX_BLADE_ROOT_DIAMETER_M, MAX_CAPACITY_MW,
  MIN_BLADE_LENGTH_M,
  MIN_BLADE_ROOT_DIAMETER_M, MIN_CAPACITY_MW
} from '../utils/constants'
import { distinctColValues } from './table/tableUtils'
import type { Table as TanTable } from '@tanstack/table-core/build/lib/types'
import useDocumentTitle from '../hooks/useDocumentTitle'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'

function createNewItem (): TurbinePlus {
  // @ts-expect-error TS2322: We cannot set a non-null siteId here yet, which is required by the model
  return ({
    id: 'new',
    name: '',
    serialNo: null,
    model: null,
    bladeLength: null,
    bladeModel: null,
    latitude: null,
    longitude: null,
    codDate: null,
    eowDate: null,
    hubHeight: null,
    capacity: null,
    maker: null,
    statusOnline: true,
    bladeRootDiameter: null,
    updatedAt: ''
  })
}

const columnHelper = createColumnHelper<TurbinePlus>()

type TurbinePlus = Turbine & {
  siteName: string
  inspections?: Inspection[]
}

const TurbineTable: React.FC = () => {
  const { t, i18n } = useTranslation('turbineTable')
  useDocumentTitle(t('turbines'))
  const sites = useEntityQuery<Site>('Site', { suspense: true })
  const turbines = useEntityQuery<Turbine>('Turbine', { suspense: true })
  const inspections = useEntityQuery<Inspection>('Inspection', { suspense: true })

  const location = useLocation()
  const client = useApolloClient()
  const getSite = (siteId: string): Site => getCachedObject<Site>(client, 'Site', siteId)
  const turbinesPlus = useMemo(() => {
    return turbines?.map(t => {
      const siteName = getSite(t.siteId)?.name
      return ({ ...t, siteName, inspections: inspections.filter(i => i.turbineId === t.id) })
    })
  }, [turbines, inspections])
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false }
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    // {id: "name", value: "h" }
  ])

  const saveEdit = useEntityMutation(UPSERT_TURBINE_MUTATION, r => r.data.upsertTurbine.errors, r => r.data.upsertTurbine.turbine)
  const deleteRows = useDeleteEntitiesMutation(DELETE_TURBINES_MUTATION, r => r.data.deleteTurbines.results)

  const meta: (m: ColumnMeta<TurbinePlus>) => ColumnMeta<TurbinePlus> = m => m
  const autoCompleteMeta = meta({ autoCompleteList: distinctColValues })

  const columns = [
    columnHelper.accessor('id', {
      header: 'id',
      cell: TableCell,
      meta: {
        readonly: true,
        filterValues: (id: string) => id,
        render: (id: string) => <span title={id}>{shortId(id)}</span>
      } satisfies ColumnMeta<TurbinePlus>
    }),
    columnHelper.accessor('name', {
      header: 'name',
      cell: TableCell,
      meta: {
        required: 'required',
        uniqueConstraint: (_: TanTable<TurbinePlus>, _2: string, row: Row<TurbinePlus>) => [row.getValue('siteId'), row.getValue('name')]
      } satisfies ColumnMeta<TurbinePlus>
    }),
    columnHelper.accessor('siteId', {
      header: 'siteId',
      cell: TableCell,
      meta: {
        required: 'required',
        filterValues: (siteId: string): string[] => [siteId, getSite(siteId)?.name ?? ''],
        render: (siteId: string) => getSite(siteId)?.name,
        options: sites.map((site) => ({
          value: site.id,
          label: site.name
        }))
      } satisfies ColumnMeta<TurbinePlus>
    }),
    columnHelper.accessor(row => row.inspections?.length, {
      id: 'inspections',
      header: 'inspections',
      cell: info => (
        <Link tabIndex={-1} to="/inspections" state={{ filter: info.row.id }}>
          <div className="inline-flex items-center">
            <CursorArrowRaysIcon className="h-6 text-blue-700"/>
            {info.getValue()}
          </div>
        </Link>
      )
    }),
    columnHelper.accessor('bladeRootDiameter', {
      header: 'bladeRootDiameter',
      cell: TableCell,
      meta: validateNumber<TurbinePlus>({ min: MIN_BLADE_ROOT_DIAMETER_M, max: MAX_BLADE_ROOT_DIAMETER_M, required: 'recommended', autoCompleteList: distinctColValues })
    }),
    columnHelper.accessor('bladeLength', {
      header: 'bladeLength',
      cell: TableCell,
      meta: validateNumber<TurbinePlus>({ min: MIN_BLADE_LENGTH_M, max: MAX_BLADE_LENGTH_M, required: 'required', autoCompleteList: distinctColValues })
    }),
    columnHelper.accessor('capacity', {
      header: 'capacity',
      cell: TableCell,
      meta: validateNumber<TurbinePlus>({ min: MIN_CAPACITY_MW, max: MAX_CAPACITY_MW, autoCompleteList: distinctColValues })
    }),
    // columnHelper.accessor('statusOnline', {
    //   header: 'statusOnline',
    //   cell: TableCell,
    //   meta: {
    //     type: 'boolean',
    //     required: 'required',
    //     render: (online: boolean) => t(online ? 'online' : 'offline'),
    //     options: [
    //       { value: true, label: 'online' },
    //       { value: false, label: 'offline' }
    //     ]
    //   } satisfies ColumnMeta<TurbinePlus>
    // }),
    columnHelper.accessor('maker', {
      header: 'maker',
      cell: TableCell,
      meta: {
        autoCompleteList: (table, columnId, row) => addAll(distinctColValues(table, columnId, row), KNOWN_TURBINE_MAKERS)
      } satisfies ColumnMeta<TurbinePlus>
    }),
    columnHelper.accessor('model', { header: 'model', cell: TableCell, meta: autoCompleteMeta }),
    columnHelper.accessor('serialNo', { header: 'serialNo', cell: TableCell }),
    columnHelper.accessor('bladeModel', { header: 'bladeModel', cell: TableCell, meta: autoCompleteMeta }),
    columnHelper.accessor('hubHeight', {
      header: 'hubHeight',
      cell: TableCell,
      meta: validateNumber<TurbinePlus>({ min: 5, max: 500, autoCompleteList: distinctColValues })
    }),
    columnHelper.accessor('codDate', { header: 'codDate', cell: TableCell, meta: meta({ type: 'date' }) }),
    columnHelper.accessor('eowDate', { header: 'eowDate', cell: TableCell, meta: meta({ type: 'date' }) }),
    columnHelper.accessor('latitude', {
      header: 'latitude',
      cell: TableCell,
      enableGlobalFilter: false,
      meta: validateLatitude<TurbinePlus>()
    }),
    columnHelper.accessor('longitude', {
      header: 'longitude',
      cell: TableCell,
      enableGlobalFilter: false,
      meta: validateLongitude<TurbinePlus>()
    }),
    columnHelper.accessor('updatedAt', { header: 'updatedAt', cell: TableCell, meta: { type: 'datetime', readonly: true } })
  ]

  const tableMeta: TableMeta<TurbinePlus> = {
    t,
    i18n,
    canDelete: (row: TurbinePlus) => row.inspections?.length === 0,
    enableMultiDelete: true,
    deleteRows,
    saveEdit,
    createNewItem
  }
  const tableOptions: TableOptions<TurbinePlus> = {
    data: turbinesPlus,
    columns,
    getRowId: row => row.id,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter: _ => true,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnFilters
    },
    meta: tableMeta
  }

  return <Table
      tableOptions={tableOptions}
      t={t}
      initialFilter={location.state?.filter}
      canCreateNewRow={sites.length > 0}
      createNewRowTitle={sites.length > 0 ? undefined : t('createSiteFirst')}
  />
}

export default TurbineTable
