import { error } from './notifications'
import { type ApolloError, type ServerError, type ServerParseError } from '@apollo/client'
import type { MouseEvent } from 'react'
import { type UploadResponse } from '../components/inspections/inspectionDetails'
import { getTimeZones } from '@vvo/tzdb'

// export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

// const isValidDate = (dateObj) => !Number.isNaN(Date.parse(dateObj));

// export const formatDate = (d: Date) => {
//     const YYYY = d.getFullYear();
//     const MM = `0${d.getMonth() + 1}`.slice(-2);
//     const DD = `0${d.getDate()}`.slice(-2);
//
//     return `${YYYY}-${MM}-${DD}`;
// };

/**
 *     const sampleErr = {
 *         "name": "ApolloError",
 *         "graphQLErrors": [
 *             {
 *                 "message": "User couldn't be registered",
 *                 "locations": [{"line": 2, "column": 3}],
 *                 "path": ["userRegister"],
 *                 "extensions": {
 *                     "code": "USER_ERROR",
 *                     "detailed_errors": [
 *                         "Password is too short (minimum is 6 characters)"]}}],
 *         "protocolErrors": [],
 *         "clientErrors": [],
 *         "networkError": null,
 *         "message": "User couldn't be registered"}
 *
 * @param err
 */
export function handleApolloErrors (err: ApolloError): void {
  console.error('handleApolloErrors', err)
  _formatApolloErrors(err).forEach(error)
}

export function formatApolloErrors (err: ApolloError): string {
  return _formatApolloErrors(err).join('\n')
}

function _formatApolloErrors (err: ApolloError): string[] {
  const graphQLErrors = err.graphQLErrors
  if ((graphQLErrors?.length ?? 0) > 0) {
    return graphQLErrors.map(gqlErr => {
      let errMsg = gqlErr.message
      for (const detailedErr of gqlErr.extensions?.detailed_errors as any[] ?? []) {
        errMsg += '\n' + detailedErr
      }
      return errMsg
    })
  } else if (err.networkError != null) {
    const spe = err.networkError as ServerParseError
    const se = err.networkError as ServerError
    if (spe != null) {
      if (spe.statusCode != null) {
        return ['StatusCode: ' + spe.statusCode + ' ' + spe.name + ' ' + spe.message + '\n' + spe.bodyText]
      } else {
        return [spe.name + ' ' + spe.message]
      }
    } else if (se != null) {
      let errMsg = se.statusCode + ' ' + se.name
      const res = se.result
      if (typeof res === 'string') {
        errMsg += '\n' + res
      } else if (Array.isArray(res.errors)) {
        for (const err of res.errors) {
          errMsg += '\n' + err.message
        }
      }
      return [errMsg]
    } else {
      const e = err.networkError
      return [e.name + ' ' + e.message]
    }
  } else {
    console.error(err)
    return ['Something went wrong (2) ' + JSON.stringify(err)]
  }
}

export function isApolloAuthError (err: ApolloError | null | undefined): boolean {
  const graphQLErrors = err?.graphQLErrors
  for (const gqlE of graphQLErrors ?? []) {
    if (gqlE?.extensions?.code === 'AUTHENTICATION_ERROR') { return true }
  }
  return false
}

export function shortId (id: string | null): string | null {
  return typeof (id) === 'string' ? id.substring(0, 5) : id
}

export function callPeriodically (func: () => boolean, interval: number, maxCalls: number): void {
  let callCount = 0
  const intervalId = setInterval(() => {
    callCount++
    const shouldContinue = func()

    if (!shouldContinue || callCount >= maxCalls) {
      clearInterval(intervalId)
    }
  }, interval)
}

export function snakeToCamelCase (str: string): string {
  return str.replace(/(_\w)/g, function (match) {
    return match[1].toUpperCase()
  }).replace(/^_/, '')
}

export function stopPropagation (e: MouseEvent): void {
  e.stopPropagation()
}

export function getMeta (name: string): string
export function getMeta<T> (name: string, defaultValue: T): T

export function getMeta<T> (name: string, defaultValue: T | '' = ''): T | string {
  const content = document.querySelector(`meta[name="${name}"]`)?.getAttribute('content')
  if (content !== null) {
    return content as any
  }
  return defaultValue as any
}

export function setMeta (name: string, content: string): void {
  return document.querySelector(`meta[name="${name}"]`)?.setAttribute('content', content)
}

export const PROD = getMeta('environment') === 'production'

export function rnd (maxExclusive: number): number {
  return Math.floor(Math.random() * maxExclusive)
}

export function createUrl (path: string): string {
  const protocol = window.location.protocol
  const host = window.location.hostname
  const port = window.location.port

  const isNonDefaultPort = port != null && port !== '' && !(protocol === 'http:' && port === '80') && !(protocol === 'https:' && port === '443')
  return `${protocol}//${host}${isNonDefaultPort ? `:${port}` : ''}${getMeta('urlBase')}${path}`
}

export function stripOffUrlBase (url: string): string {
  const urlBase = getMeta('urlBase')
  return urlBase === '' || !url.startsWith(urlBase) ? url : url.substring(urlBase.length)
}

export function urlIsAtRoot (url: string): boolean {
  const urlBase = getMeta('urlBase')
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1)
  }
  return url === urlBase
}

export async function upload (
  url: string,
  method: string,
  formData: FormData,
  headers?: Record<string, string>,
  onProgress?: (progress: number) => void
): Promise<UploadResponse> {
  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.open(method, url, true)

    const h = headers ?? {}
    Object.keys(h).forEach(key => {
      xhr.setRequestHeader(key, h[key])
    })

    if (onProgress != null) {
      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100
          onProgress(progress)
        }
      })
    }

    xhr.onload = () => {
      try {
        const data = JSON.parse(xhr.responseText) as UploadResponse
        resolve(data)
      } catch (error) {
        console.log(error)
        reject(new Error(`Request failed with status: ${xhr.status}. Error: ${String(error)}`))
      }
    }

    xhr.onerror = () => {
      reject(new Error('Network Error'))
    }

    xhr.send(formData)
  })
}

export function byteSizeToHumanReadable (num: number | undefined, locale: string): string {
  if (num == null || num === 0) {
    return '0'
  }

  const units = locale.startsWith('ja')
    ? ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let unitIndex = 0

  while (num >= 1024 && unitIndex < units.length - 1) {
    num /= 1024
    unitIndex++
  }

  return new Intl.NumberFormat(locale, { maximumSignificantDigits: 3 }).format(num) + ' ' + units[unitIndex]
}

const timeZones = getTimeZones()

/**
 * Arbitrary list of countries with multiple time zones, and for now, mapped to an arbitrary time zone for that country
 */
const defaultTimeZonesByCountry = new Map([
  ['US', 'America/New_York'],
  ['CN', 'Asia/Shanghai'],
  ['IN', 'Asia/Kolkata']
])

export function getTimeZoneForCountry (countryCode: string): string | null {
  const defaultTimeZone = defaultTimeZonesByCountry.get(countryCode)
  if (defaultTimeZone != null) {
    return defaultTimeZone
  }
  const countryTimeZones = timeZones.filter(tz => tz.countryCode === countryCode)
  return countryTimeZones.length > 0 ? countryTimeZones[0].name : null
}

export function isTimeZoneOfCountry (countryCode: string, timeZone: string): boolean {
  const countryTimeZones = timeZones.filter(tz => tz.countryCode === countryCode)
  return countryTimeZones.some(tz => timeZone === tz.name)
}

export function asIterable<T> (obj: any): Iterable<T> | undefined {
  if (obj != null && typeof obj[Symbol.iterator] === 'function') {
    return obj
  } else {
    return undefined
  }
}

export function mapIt<T, U> (iterable: Iterable<T>, mapFn: (item: T) => U): U[] {
  const result: U[] = []
  for (const item of iterable) {
    result.push(mapFn(item))
  }
  return result
}

interface Addable<T> {
  add: (value: T) => void
}

export function addAll<T, A extends Addable<T>> (addable: A, addThese: Iterable<T>): A {
  for (const addThis of addThese) {
    addable.add(addThis)
  }
  return addable
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

export function getFilenameFromContentDisposition (contentDisposition: string): string | null {
  // First, try to find a filename* (RFC 5987 encoding)
  const filenameStarRegex = /filename\*=([^';]+)'(?:[^']*)'([^';]+)/
  const matchesStar = filenameStarRegex.exec(contentDisposition)
  if (matchesStar != null && matchesStar.length === 3) {
    const charset = matchesStar[1]
    const encodedFilename = matchesStar[2]
    if (charset.toLowerCase() === 'utf-8') {
      return decodeURIComponent(encodedFilename)
    }
  }

  // Fallback to filename= if filename*= is not present or not UTF-8 encoded
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches?.[1] != null) {
    // Remove quotes if present
    return matches[1].replace(/['"]/g, '')
  }

  return null
}

export function safeFilename (originalName: string): string {
  // Replace reserved characters with underscore
  // eslint-disable-next-line no-control-regex
  let safeName = originalName.replace(/[<>:"/\\|?*\x00-\x1F]/g, '_')

  // Replace reserved Windows filenames
  const windowsReservedNames = /^(con|prn|aux|nul|com[1-9]|lpt[1-9])$/i
  if (windowsReservedNames.test(safeName.split('.')[0])) {
    safeName = '_' + safeName
  }

  // Truncate to a reasonable length for a filename (255 characters is a common maximum)
  return safeName.substring(0, 255)
}

