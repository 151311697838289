import * as React from 'react'
import SiteTable from './siteTable'
import SiteMapAndTable from './siteMapAndTable'
import useEntityQuery from '../../hooks/useEntityQuery'
import type { TenantProperties } from '../../graphql/graphql'

const SiteView: React.FC = () => {
  const tenantProperties = useEntityQuery<TenantProperties>('TenantProperties')?.[0]
  return tenantProperties?.properties.mapsEnabled === true
    ? <SiteMapAndTable/>
    : <SiteTable/>
}

export default SiteView
