import { type ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast, type Id, type ToastOptions } from 'react-toastify'
import { showToast as showToastFn } from '../utils/notifications'

interface ToastRecord {
  toastId: Id
  getMessage: () => ReactNode
}

export const useLocalizedToast = (): ((
  category: string,
  getMessage: () => ReactNode,
  options?: ToastOptions) => void) => {
  const { i18n } = useTranslation()
  const toastRecords = useRef<ToastRecord[]>([])

  const showToast = (category: string, getMessage: () => ReactNode, options: ToastOptions | undefined = undefined): void => {
    toastRecords.current.push({
      toastId: showToastFn(category, getMessage(), options) ?? '',
      getMessage
    })
  }

  i18n.on('languageChanged', () => {
    for (const rec of toastRecords.current) {
      toast.update(rec.toastId, {
        render: rec.getMessage()
      })
    }
  })

  return showToast
}
