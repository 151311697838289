import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  ChartPieIcon,
  ClipboardDocumentCheckIcon, Cog8ToothIcon,
  MapIcon
} from '@heroicons/react/24/outline'
import '../types/images.d'
import TurbineIcon from '../../assets/images/outline/turbine.svg?react'
import { useTranslation } from 'react-i18next'
import usePermissions from '../hooks/usePermissions'

const navLocations = {
  insights: { icon: ChartPieIcon },
  sites: { icon: MapIcon },
  turbines: { icon: TurbineIcon },
  inspections: { icon: ClipboardDocumentCheckIcon },
  settings: { icon: Cog8ToothIcon }
}

export default function NavigationBar (): React.JSX.Element {
  const { t } = useTranslation('navigationBar')
  const location = useLocation()
  const curNavCategory = location.pathname.split('/')[1] ?? 'insights'
  const permissions = usePermissions()
  const viewPermissionsFilter = ([view]: [string, any]): boolean => permissions == null
    ? false
    : permissions.enabledViews.has(view)
  return (
    <nav className="flex flex-col bg-gray-400 text-white">
      {Object.entries(navLocations)
        .filter(viewPermissionsFilter)
        .map(([name, v]) => {
          const Icon = v.icon
          const bg = name === curNavCategory ? 'bg-gray-600' : ''
          return <Link key={name} to={`/${name}`}
                              className={`flex flex-col items-center w-full p-2 hover:text-yellow-500 ${bg} focus:outline-1`}
              >
                  <Icon className="h-9 w-9"/>
                  {t(name)}
              </Link>
        })}
    </nav>
  )
}
