import React from 'react'

export interface ProgressBarProps extends React.ComponentProps<any> {
  label: string
  progress: number
  nonPercentageProgress?: boolean
  progressColor?: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  label,
  progress,
  nonPercentageProgress = false,
  progressColor = 'bg-blue-500',
  className,
  ...restProps
}) => {
  const normalizedProgress = nonPercentageProgress ? 100 : Math.min(Math.max(progress, 0), 100)

  const progressStyle = nonPercentageProgress ? {} : { width: `${normalizedProgress}%` }

  let bgClass = progressColor
  if (nonPercentageProgress) {
    switch (progressColor) {
      case 'bg-green-500':
        bgClass = 'indeterminate-progress-green-500'
        break
      case 'bg-blue-500':
      default:
        // Making the color of the animation dynamic doesn't seem that straightforward, so let's just default to blue
        // in case we haven't created a style for the requested color in application.css yet
        bgClass = 'indeterminate-progress-blue-500'
        break
    }
  }
  return (
    <div className={`relative inline-block m-1 bg-gray-200 rounded ${className}`} {...restProps}>
      <div style={progressStyle}
        className={`absolute top-0 left-0 h-full rounded transition-all duration-300 ${bgClass}`}>
      </div>
      <span className="relative px-2 block text-center text-black">{label}</span>
    </div>
  )
}

export default ProgressBar
