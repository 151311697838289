import React, { useEffect, useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink, Observable, type Operation, type RequestHandler, split, ApolloProvider
} from '@apollo/client'
import { getMeta, PROD } from '../utils/utils'
import type { DefinitionNode, OperationDefinitionNode } from 'graphql/language'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import useSyncedRef from '../hooks/useSyncedRef'
import { getAuthHeaders } from '../utils/auth'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { useActionCable } from '../hooks/useActionCable'
import { Outlet } from 'react-router-dom'
import { createFragmentRegistry } from '@apollo/client/cache'
import { FRAGMENTS } from '../graphql-user/fragments'
import { useGlobalState } from '../hooks/useGlobalState'

const simulateLatencyMillis = 1000 // in order to test slow / distant connections

const delayLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
    setTimeout(() => {
      const subscription = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer)
      })

      return () => { subscription.unsubscribe() }
    }, simulateLatencyMillis)
  })
})

const httpLink = createHttpLink({
  uri: getMeta('urlBase') + '/graphql'
})

function isOperationDefinitionNode (definition: DefinitionNode): definition is OperationDefinitionNode {
  return definition.kind === 'OperationDefinition'
}

function isSubscriptionOperation ({ query }: Operation): boolean {
  const definition = getMainDefinition(query)
  return (
    isOperationDefinitionNode(definition) && definition.operation === 'subscription'
  )
}

export function getCachedObject<T> (client: ApolloClient<any>, type: string, id: string): T {
  const cache = client.cache.extract() as Record<string, any>
  return cache[`${type}:${id}`] as T
}

const ApolloClientManager: React.FC = (): React.JSX.Element => {
  const { authData, setAuthData, tenantId } = useGlobalState()
  const authDataRef = useSyncedRef(authData)
  const tenantIdRef = useSyncedRef(tenantId)
  useEffect(() => {
    authDataRef.current = authData
  }, [authData])

  const { consumer } = useActionCable()

  const actionCableLink = useMemo(() => {
    return consumer == null ? null : new ActionCableLink({ cable: consumer })
  }, [consumer])
  const actionCableLinkRef = useSyncedRef(actionCableLink)

  const apolloClient = useMemo(() => {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          tid: tenantIdRef.current,
          ...getAuthHeaders(authDataRef.current)
        }
      }
    })

    const authResponseLink = new ApolloLink((operation, forward) => {
      return forward(operation).map(data => {
        const context = operation.getContext()
        const { response: { headers } } = context

        if (headers != null) {
          setAuthData({
            'access-token': headers.get('access-token'),
            client: headers.get('client'),
            uid: headers.get('uid')
          })
        }

        return data
      })
    })

    const authHttpLink = simulateLatencyMillis > 0
      ? authLink.concat(authResponseLink).concat(delayLink).concat(httpLink)
      : authLink.concat(authResponseLink).concat(httpLink)

    const authChangeableActionCableLink: RequestHandler = (operation, forward) => {
      const actionCableLink = actionCableLinkRef.current
      if (actionCableLink == null) {
        throw new Error('Not authenticated. Cannot establish real-time communication channel')
      }
      return actionCableLink.request(operation, forward) as Observable<any>
    }

    const link = split(
      isSubscriptionOperation,
      authChangeableActionCableLink,
      authHttpLink
    )

    return new ApolloClient({
      link,
      connectToDevTools: !PROD,
      cache: new InMemoryCache({
        fragments: createFragmentRegistry(...FRAGMENTS),
        possibleTypes: { // TODO: Generate automatically? https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
          EntityType: ['Site', 'Turbine', 'Inspection', 'InspectionImage']
        },
        typePolicies: {
          Query: {
            fields: {
              entity: {
                // Custom merge function to prevent these warnings:
                //
                // Cache data may be lost when replacing the entity field of a Query object.
                //
                // This could cause additional (usually avoidable) network requests to fetch data that were otherwise cached.
                //
                // To address this problem (which is not a bug in Apollo Client), define a custom merge function for the Query.sites field, so InMemoryCache can safely merge these objects:
                //
                //   existing: [object Object],[object Object],[object Object],[object Object],[object Object]
                //   incoming: [object Object],[object Object],[object Object],[object Object]
                //
                // For more information about these options, please refer to the documentation:
                //
                //   * Ensuring entity objects have IDs: https://go.apollo.dev/c/generating-unique-identifiers
                //   * Defining custom merge functions: https://go.apollo.dev/c/merging-non-normalized-objects
                merge (_ = [], incoming) {
                  return incoming
                }
              }
            }
          }
        }
      })
    })
  }, [])

  return (
      <ApolloProvider client={apolloClient}>
        <Outlet/>
      </ApolloProvider>
  )
}

export default ApolloClientManager
